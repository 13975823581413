<template>
  <TreeInput
    v-if="item.type === 'tree'"
    :item="item"
    :model="model"
    :error-messages="errorMessages"
    :disabled="disabled"
    @input="$emit('input', $event)"
  />
  <div
    v-else-if="item.type === 'autocomplete'"
    class="application-input-wrap mb-24"
  >
    <div class="application-input-title">
      {{ $t(item.name) }}
    </div>
    <AppAutocomplete
      :value="model[item.key]"
      :url="item.url"
      :imitate="true"
      :search-keys="searchKeys"
      :disabled="item.disabled"
      @change="$emit('input', [item.key, $event])"
    />
    <div
      v-if="isError"
      class="application-input-error"
    >
      {{ getTranslationOrKey(errorMessages[item.key][0]) }}
    </div>
  </div>
  <div
    v-else-if="item.type === 'rating'"
    class="application-input-wrap mb-24"
  >
    <div class="application-input-title">
      {{ $t(item.name) }}
    </div>
    <StarsSelect
      :value="model[item.key]"
      @input="$emit('input', [item.key, $event])"
    />
  </div>
  <div
    v-else-if="item.type === 'double-date'"
    class="application-input-wrap"
  >
    <div class="application-input-title">
      {{ $t(item.name) }}
    </div>
    <div class="flex-grow-1 d-flex justify-content-center align-items-center">
      <div class="flex-grow-1">
        <AppDatePicker
          :value="model[item.keys[0]]"
          :error="isError"
          :allow-clear="item.removable"
          ref="input"
          size="large"
          default-value=""
          @input="$emit('input', [item.keys[0], $event])"
        />
      </div>
      <div class="mr-16 ml-16">
        —
      </div>
      <div class="flex-grow-1">
        <AppDatePicker
          :value="model[item.keys[1]]"
          :error="isError"
          :allow-clear="item.removable"
          size="large"
          default-value=""
          @input="$emit('input', [item.keys[1], $event])"
        />
      </div>
    </div>
    <div
      v-if="isError"
      class="application-input-error"
    >
      {{ getTranslationOrKey(errorMessages[item.key][0]) }}
    </div>
  </div>
  <div
    v-else-if="item.type === 'select'"
    class="application-input-wrap"
  >
    <div class="application-input-title">
      {{ $t(item.name) }}
    </div>
    <AppSelect
      ref="input"
      :items="item.items"
      :allow-clear="item.removable"
      :auto-focus="autoFocus"
      item-name="name"
      default-value=""
      item-value="value"
      :value="model[item.key]"
      :error="isError"
      class="application-input"
      size="large"
      @input="$emit('input', [item.key, $event])"
      :disabled="disabled"
    />
    <div
      v-if="isError"
      class="application-input-error"
    >
      {{ getTranslationOrKey(errorMessages[item.key][0]) }}
    </div>
  </div>
  <div
    v-else-if="item.type === 'switch'"
    class="application-input-text-row"
  >
    <div class="application-input-title">
      {{ $t(item.name) }}
    </div>
    <AppSwitch
      ref="input"
      class="ml-auto"
      :auto-focus="autoFocus"
      true-value="components.common.yes"
      false-value="components.common.no"
      :value="model[item.key]"
      @input="$emit('input', [item.key, $event])"
      :disabled="disabled"
    />
  </div>
  <div
    v-else-if="item.type === 'textarea'"
    class="application-input-wrap"
  >
    <div class="application-input-title">
      {{ $t(item.name) }}
    </div>
    <AppTextarea
      ref="input"
      :value="model[item.key]"
      :auto-focus="autoFocus"
      :error="isError"
      class="application-input"
      size="large"
      @input="$emit('input', [item.key, $event])"
      :disabled="disabled"
    />
    <div
      v-if="isError"
      class="application-input-error"
    >
      {{ getTranslationOrKey(errorMessages[item.key][0]) }}
    </div>
  </div>
  <div
    v-else-if="item.type === 'input' || item.type === 'password'"
    class="application-input-wrap"
  >
    <div class="application-input-title">
      {{ $t(item.name) }}
    </div>
    <AppInput
      ref="input"
      :value="model[item.key]"
      :auto-focus="autoFocus"
      :error="isError"
      class="application-input"
      :type="item.type === 'input' ? 'text' : item.type"
      size="large"
      @input="$emit('input', [item.key, $event])"
      :disabled="disabled"
    />
    <div
      v-if="isError"
      class="application-input-error"
    >
      {{ getTranslationOrKey(getTranslationOrKey(errorMessages[item.key][0])) }}
    </div>
  </div>
  <div
    v-else-if="item.type === 'block'"
    class="application-modal-block"
  >
    <div class="application-modal-block__name">
      {{ $t(item.name) }}
    </div>
    <FormInputComponent
      v-for="el in item.items"
      :key="el.key"
      class="mb-24"
      :item="el"
      :error-messages="errorMessages"
      :model="model"
      @input="$emit('input', $event)"
      :disabled="disabled"
    />
  </div>
</template>
<script>
import AppSwitch from "@/components/partials/AppSwitch.vue";
import AppTextarea from "@/components/partials/AppTextarea.vue";
import AppSelect from "@/components/partials/AppSelect.vue";
import AppInput from "@/components/partials/AppInput.vue";
import TreeInput from "@/components/shared/old/tree-input/tree-input.component.vue";
import AppDatePicker from "@/components/partials/AppDatePicker.vue";
import StarsSelect from "@/components/partials/StarsSelect.vue";
import AppAutocomplete from "@/components/partials/AppAutocomplete.vue";
import {translationExists} from "@/mixins/local/translationExists.mixin";

export default {
    name: "FormInputComponent",
    components: {AppSwitch, AppTextarea, AppSelect, AppInput, TreeInput, AppDatePicker, StarsSelect, AppAutocomplete},
    mixins: [translationExists],
    props: {
        item: [Object, Array],
        model: Object,
        errorMessages: Object,
        autoFocus: Boolean,
        disabled: Boolean,
        searchKeys: {
            type: [String, Array],
            default: "name"
        },
        itemValue: {
            type: String,
            default: "id"
        }
    },
    computed: {
        isError() {
            return Boolean(this.errorMessages[this.item.key] && this.errorMessages[this.item.key].length);
        }
    },
    mounted() {
        if (this.autoFocus && this.$refs.input) {
            this.$refs.input.$el.focus();
        }
    }
};
</script>
