class TimerService {
    timerData = {
        rawSeconds: 0,
        seconds: 0,
        minutes: 0,
        timer: null
    }

    defaultData = {
        rawSeconds: 0,
        seconds: 0,
        minutes: 0,
    }

    constructor(seconds = 0) {
        this.defaultData = {
            rawSeconds: seconds,
            seconds: Math.round(seconds % 60),
            minutes: Math.floor(seconds / 60)
        };
        this.timerData = {
            ...this.defaultData,
            timer: null
        }
    }

    getData() {
        return this.timerData;
    }

    startTimer(cb) {
        if(this.timerData.timer) return;

        this.timerData.timer = setInterval(() => {
            const d = this.defaultData.rawSeconds ? -1 : 1;
            const seconds = this.timerData.rawSeconds + d;

            if(seconds <= 0) {
                clearInterval(this.timerData.timer);
                this.timerData.timer = null;
            }

            this.timerData.rawSeconds = seconds;

            this.timerData.seconds = Math.round(seconds % 60);
            this.timerData.minutes = Math.floor(seconds / 60);

            if(cb) cb(this.timerData);
        }, 1000);
    }
    clearTimer() {
        clearInterval(this.timerData.timer);
        this.timerData.rawSeconds = this.defaultData.rawSeconds;
        this.timerData.minutes = this.defaultData.minutes;
        this.timerData.seconds = this.defaultData.seconds;
        this.timerData.timer = null;
    }
    resetTimer() {
        this.clearTimer();
        this.startTimer();
    }

    getMinutesAndFormat() {
        const { minutes } = this.timerData;
        return `${minutes < 10 ? '0' : ''}${minutes}`;
    }
    getSecondsAndFormat() {
        const { seconds } = this.timerData;
        return `${seconds < 10 ? '0' : ''}${seconds}`;
    }
    getMinutesSecondsAndFormat() {
        return `${this.getMinutesAndFormat()}:${this.getSecondsAndFormat()}`;
    }
}

export default TimerService;
