import { getCurrentInstance } from "vue";

export function useNotification(){
    const app = getCurrentInstance()

    const showNotification = (type: 'error' | 'success', text: string) => {
        app?.proxy.$store.commit('notifications/add', {type, message: text})
    }

    return {
        showNotification
    }
}
