<template>
    <div class="header-dialer header-trigger" :class="{'header-trigger--active': visible, 'header-trigger--disabled': disabled}" v-clickaway="onClickaway">
        <div class="header-dialer__trigger header-trigger__button" v-tooltip="$t('layout.header.headerDiler.dialPhoneNumber')" @click="trigger">
            <AppIcon icon="dialer"/>
        </div>
        <transition name="dialer">
            <DialerWindow v-if="visible" class="header-dialer__window header-trigger__window" ref="dialer" @submit="onSubmit"/>
        </transition>
    </div>
</template>
<script>
import AppIcon from "../partials/AppIcon";
import {EventBus} from "../../helpers/eventBus";
import clickaway from "../../directives/clickaway";
import DialerWindow from "../partials/DialerWindow";

export default {
    name: 'HeaderDialer',
    components: {DialerWindow, AppIcon},
    props: {
        disabled: Boolean
    },
    directives: {
        clickaway
    },
    data() {
        return {
            visible: false
        }
    },
    methods: {
        focus() {
            this.$refs.dialer.focus();
        },
        trigger() {
            if(this.disabled) return;

            this.visible = !this.visible
            if(this.visible) {
                this.$nextTick(this.focus);
            }
        },
        close() {
            this.visible = false;
        },
        onClickaway() {
            this.close();
        },
        onSubmit(phone) {
            this.$router.push({ name: "ClientCard" })
            EventBus.$emit('make-call', {phone});
            this.close();
        }
    }
}
</script>
<style lang="scss">
@import '@/assets/scss/variables';
.header-dialer {
    $self: &;
    font-family: $font-secondary;
}
</style>
