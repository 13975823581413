import {getPrefixes, gatherGroupInfo} from "@/data/config/cisco";

// Voice Groups constants
const VoiceGroups = [];
const VoiceGroupsInfo = {};

// Chat Groups constants
const ChatGroups = [];
const ChatGroupsInfo = {};

// Retrieves voice group prefixes and populates the VoiceGroups and VoiceGroupsInfo constants
const voicePrefixes = getPrefixes('VUE_APP_VOICE_GROUPS');
gatherGroupInfo(voicePrefixes, 'VUE_APP_VOICE_GROUPS', VoiceGroups, VoiceGroupsInfo);

// Retrieves chat group prefixes and populates the ChatGroups and ChatGroupsInfo constants
const chatPrefixes = getPrefixes('VUE_APP_CHAT_GROUPS');
gatherGroupInfo(chatPrefixes, 'VUE_APP_CHAT_GROUPS', ChatGroups, ChatGroupsInfo);

// Returned configured object
const oCiscoConfig = {
    CUIC_credential: {
        'base64': process.env.VUE_APP_CUIC_CREDENTIAL_BASE_64,
        'base64_report': process.env.VUE_APP_CUIC_REPORT_CREDENTIAL_BASE_64,
        'call_log_report_id': process.env.VUE_APP_CUIC_CALL_LOGS_REPORT_ID,
        'call_log_field_id': process.env.VUE_APP_CUIC_CALL_LOGS_FIELD_ID,
        'call_log_value_list_id': process.env.VUE_APP_CUIC_CALL_LOGS_VALUESLIST_ID,
        'state_log_report_id': process.env.VUE_APP_CUIC_STATE_LOGS_REPORT_ID,
        'state_log_field_id': process.env.VUE_APP_CUIC_STATE_LOGS_FIELD_ID,
        'state_log_value_list_id': process.env.VUE_APP_CUIC_STATE_LOGS_VALUESLIST_ID
    },
    VoiceGroups,
    VoiceGroupsInfo,
    ChatGroups,
    ChatGroupsInfo
};

export default oCiscoConfig;
