<template>
    <div class="base-tooltip" id="base-tooltip" :class="[{'base-tooltip--hidden': !tooltipVisible, 'base-tooltip--hide-animation': tooltipHideAnimation}, 'base-tooltip--' + tooltipOptions.posHor + '-' + tooltipOptions.posVer]"
         :style="{'--tooltip-left': tooltipOptions.left + 'px', '--tooltip-top': tooltipOptions.top + 'px'}">
        <div class="base-tooltip__container">
            {{ tooltipOptions.content }}
        </div>
    </div>
</template>
<script>
export default {
    name: 'BaseTooltip',
    data() {
        return {
            tooltipVisible: false,
            tooltipHideAnimation: false
        }
    },
    computed: {
        tooltipOptions() {
            return this.$store.state.tooltip;
        },
    },
    watch: {
        '$store.state.tooltip.visible'(value) {
            if(value) {
                this.tooltipVisible = true;
            } else {
                this.tooltipVisible = false;
                this.tooltipHideAnimation = true;
                setTimeout(() => {
                    this.tooltipHideAnimation = false;
                }, 250);
            }
        }
    },
}
</script>
<style lang="scss">
.base-tooltip {
    $self: &;
    z-index: 102;
    position: fixed;
    top: var(--tooltip-top);
    left: var(--tooltip-left);
    animation: tooltipEnterBottom .25s 1 both;
    pointer-events: none;
    &__container {
        position: relative;
        background: var(--color-dark-light);
        border-radius: 9px;
        padding: 7px 20px 9px;
        font-weight: 500;
        font-size: 16px;
        line-height: 19px;
        color: var(--color-white);
        max-width: 240px;
        text-align: center;
        margin-top: 10px;
        &:before {
            content: '';
            background: url('../../assets/img/tooltip-label.svg') center/contain no-repeat;
            position: absolute;
            left: 50%;
            margin-left: -9px;
            bottom: 100%;
            width: 18px;
            height: 5px;
        }
    }
    &--left-top,
    &--right-top,
    &--center-top {
        animation-name: tooltipEnterTop;
        #{$self}__container {
            margin-top: 0;
            margin-bottom: 10px;
            &:before {
                bottom: auto;
                top: 100%;
                transform: rotate(180deg);
            }
        }
    }
    &--left-top,
    &--left-bottom {
        #{$self}__container {
            &:before {
                left: 14px;
            }
        }
    }
    &--right-top,
    &--right-bottom {
        #{$self}__container {
            &:before {
                right: 14px;
                left: auto;
            }
        }
    }
    &--hidden {
        opacity: 0;
        animation: none;
    }
    &--hide-animation {
        animation: tooltipHide .25s 1 both;
    }
}
@keyframes tooltipHide {
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
    }
}
@keyframes tooltipEnterTop {
    from {
        opacity: 0;
        transform: translateY(32px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}
@keyframes tooltipEnterBottom {
    from {
        opacity: 0;
        transform: translateY(-32px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}
</style>
