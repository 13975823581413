import {langsCallVarsDict, DEFAULT_CALLVARS_LANG} from "@/config/langsCallVars";

const callVarsConfig = {
    "lang": ["callVariable2"],
    //'callTo': ['callVariable4', 'callVariable5'],
    "customerType": ["callVariable3"],
    "taskID": ["BAAccountNumber"],
    "customerNumberOnOutbound": ["BACustomerNumber"]
};
export default class CallVarParse {
    #calVars = [];

    #callVarsConfig = [];

    constructor(calVars) {
        this.#calVars = calVars;
        this.#callVarsConfig = callVarsConfig;
    }

    /**
     * Get selected Language
     * @returns {*|string}
     */
    getCallLanguage() {
        const sLang = this.#getVarValue("lang");
        if ("" !== sLang && langsCallVarsDict.hasOwnProperty(sLang)) {
            return langsCallVarsDict[sLang];
        }
        return DEFAULT_CALLVARS_LANG;
    }

    /**
     * Get Support line
     * @returns {*|string|string}
     */
    getCallToPhone() {
        return this.#getVarValue("callTo");
    }

    /**
     * Get Customer type - physical or juridical
     * @returns {*|string|string}
     */
    getCustomerType() {
        return "YUR" === this.#getVarValue("customerType")
            ? "Юр" : "Физ";
    }

    getTaskID() {
        return this.#getVarValue("taskID");
    }

    getCustomerNumberOnOutbound() {
        return this.#getVarValue("customerNumberOnOutbound");
    }

    #getVarValue(varName) {
        if (this.#calVars.length && this.#callVarsConfig.hasOwnProperty(varName)) {
            const requestedCallVar = this.#calVars.filter((item) => {
                return (this.#callVarsConfig[varName].includes(item.name) && item.value !== "");
            });

            return requestedCallVar.length ? requestedCallVar[0].value : "";
        }
        return "";
    }
}
