<template>
  <div
    class="app-timepicker"
    :class="{['app-timepicker--theme-' + theme]: theme, 'app-timepicker--opened': opened}"
    v-clickaway="onCLickaway"
    @keydown="onKeyboard"
  >
    <AppInput
      v-model="model"
      v-mask="'##:##'"
      :allow-clear="allowClear"
      class="app-timepicker__input"
      v-bind="parsedAttrs"
      v-on="parsedListeners"
      :placeholder="placeholder"
      ref="input"
      @input="scrollTimeDelayed"
      @focusin="onOpen"
    />
    <AppIcon
      v-if="!value && allowClear"
      class="app-timepicker__icon"
      :icon="icon"
    />
    <transition name="dialer">
      <div
        v-if="opened"
        class="app-timepicker__menu"
      >
        <div
          class="app-timepicker__col"
          ref="hours"
        >
          <div
            v-for="item in hours"
            class="app-timepicker__item"
            :class="{'app-timepicker__item--selected': +(selectedHours || 0) == +item}"
            @click="setHours(item, true)"
          >
            {{ item }}
          </div>
        </div>
        <div
          class="app-timepicker__col"
          ref="minutes"
        >
          <div
            v-for="item in minutes"
            class="app-timepicker__item"
            :class="{'app-timepicker__item--selected': +(selectedMinutes || 0) == +item}"
            @click="setMinutes(item, true)"
          >
            {{ item }}
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>
<script>
import AppInput from "./AppInput";
import AppIcon from "./AppIcon";
import clickaway from "../../directives/clickaway";

const hours = new Array(24).fill("").map((_, i) => i < 10 ? "0" + i : "" + i);
const minutes = new Array(60).fill("").map((_, i) => i < 10 ? "0" + i : "" + i);

export default {
    name: "AppTimePicker",
    components: {AppIcon, AppInput},
    directives: {
        clickaway
    },
    props: {
        value: {
            type: [String, Number],
            default: () => {
                const date = new Date();
                return date.toLocaleTimeString().slice(0,5);
            }
        },
        theme: String,
        allowClear: Boolean,
        placeholder: {
            type: String,
            default: "Выбрать время"
        },
        icon: {
            type: String,
            default: "time"
        },
    },
    data() {
        return {
            opened: false,
            minutes,
            hours
        };
    },
    computed: {
        parsedAttrs() {
            const attrs = {...this.$attrs};
            delete attrs.value;
            return attrs;
        },
        parsedListeners() {
            const listeners = {...this.$listeners};
            delete listeners.input;
            return listeners;
        },
        model: {
            get() {
                return this.value;
            },
            set(val) {
                let [hours, minutes] = val.split(":");

                if(["3","4","5","6","7","8","9"].includes(hours)) {
                    hours = "0" + hours;
                } else if(hours > "23") {
                    hours = "23";
                }
                if(["6","7","8","9"].includes(minutes)) {
                    minutes = "0" + minutes;
                } else if(minutes > "59") {
                    minutes = "59";
                }

                if(hours && minutes) {
                    val = hours + ":" + minutes;
                } else if(hours.length === 2) {
                    val = hours + ":";
                }
                this.$emit("input", val);
            }
        },
        selectedHours() {
            return this.model.split(":")[0];
        },
        selectedMinutes() {
            return this.model.split(":")[1];
        },
    },
    methods: {
        setHours(hours, scrollTo) {
            if(scrollTo) {
                this.$nextTick(() => {
                    this.$refs.input.focus();
                });
            }
            let minutes = this.selectedMinutes;
            if(!minutes) {
                minutes = "00";
            }
            this.model = hours + ":" + minutes;
        },
        setMinutes(minutes, scrollTo) {
            if(scrollTo) {
                this.$nextTick(() => {
                    this.$refs.input.focus();
                });
            }
            let hours = this.selectedHours;
            if(!hours) {
                hours = new Date().toLocaleTimeString().slice(0,2);
                this.model = hours + ":" + minutes;
                this.scrollTimeDelayed();
            } else {
                this.model = hours + ":" + minutes;
            }
        },
        onCLickaway() {
            this.opened = false;

            if(this.selectedHours && this.selectedHours.length < 2) {
                this.model = "00".slice(0, 2 - this.selectedHours.length) + this.selectedHours + ":" + "00";
            } else if(this.selectedHours && this.selectedMinutes.length < 2) {
                this.setMinutes("00".slice(0, 2 - this.selectedMinutes.length) + this.selectedMinutes);
            }
        },
        onOpen() {
            if(this.opened) {
                return;
            }
            this.opened = true;
            this.scrollTimeDelayed();
        },
        scrollTime() {
            if(this.$refs.hours) {
                this.$refs.hours.scrollTo(0, this.selectedHours * 40);
                this.$refs.minutes.scrollTo(0, this.selectedMinutes * 40);
            }
        },
        scrollTimeDelayed() {
            this.$nextTick(() => {
                this.scrollTime();
            });
        },
        onKeyboard(e) {
            if(e.code === "Tab") {
                this.onCLickaway();
            }
        }
    }
};
</script>
<style lang="scss">
@import '@/assets/scss/variables';
.app-timepicker {
    $self: &;
    position: relative;
    &__input {
        width: 100%;
    }
    &__icon {
        width: 20px;
        height: 20px;
        color: #949494;
        position: absolute;
        right: 14px;
        top: 50%;
        margin-top: -10px;
    }
    &__menu {
        margin-top: -1px;
        display: flex;
        position: absolute;
        top: 100%;
        left: 0;
        width: 100%;
        height: 205px;
        background: #FFFFFF;
        border: 1px solid #E1E1E1;
        box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.13);
        border-radius: 4px;
    }
    &__col {
        width: 50%;
        height: 100%;
        overflow: auto;
        &:first-child {
            border-right: 1px solid #E1E1E1;
        }
        &::-webkit-scrollbar-track {
            background: transparent!important;
            border-radius: 10px;
        }
        &::-webkit-scrollbar {
            width: 3px;
            height: 3px;
            background: transparent!important;
        }
        &::-webkit-scrollbar-thumb {
            background: #2552C6;
            opacity: 0.5;
            border-radius: 50px;
        }
    }
    &__item {
        padding: 10px 15px;
        font-weight: 500;
        font-size: 16px;
        line-height: 20px;
        letter-spacing: 0.005em;
        color: #3C3C3C;
        font-family: $font-secondary;
        &--selected,
        &:hover {
            background-color: #EDF2FF;
        }
    }
    &--opened {
        z-index: 1;
        #{$self}__input input {
            background: #F4F4F4;
        }
    }
}
</style>
