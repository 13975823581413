import {EventBus} from "../../helpers/eventBus";
import ChatController from "../ChatController";
import app from '../../main';
import {AGENT_CHAT_STATE} from "@contact-center/asakabank-cisco-finesse/dist/lib/finesse";
import TimerService from "../TimerService";

const reloadChatTimer = new TimerService();

class ChatReconnectService {
    isReloadChatDisabled() {
        return app.$store.state.chat.operatorState === AGENT_CHAT_STATE.RESERVED || app.$store.state.chat.reloadChatTimer;
    }
    reloadChat() {
        if(this.isReloadChatDisabled()) return;
        reloadChatTimer.startTimer(({rawSeconds}) => {
            if(rawSeconds >= 30) {
                app.$store.commit('chat/setReloadChatTimer', null);
                reloadChatTimer.clearTimer();
            }
        });
        app.$store.commit('chat/setReloadChatTimer', reloadChatTimer.timerData.timer);
        ChatController.reloadChat();
    }
}

export default new ChatReconnectService();
