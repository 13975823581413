import LiveStatistics from "@contact-center/asaka-live-statistics";
import oCiscoConfig from "../config/cisco";

const options = {
    frontAddress: window.location.origin,
    groups: [
        {
            source: 'ChatQueueStatistics',
            groups: oCiscoConfig.ChatGroups
        },
        {
            source: 'VoiceIAQStats',
            groups: oCiscoConfig.VoiceGroups
        }
    ],
    authToken: 'Basic ' + oCiscoConfig.CUIC_credential.base64,
    onMessage(message) {
        socket.listeners.forEach(listener => {
            listener(message);
        });
        if(message.VoiceIAQStats) {
            socket.lastCallMessage = message;
        } else if(message.ChatQueueStatistics) {
            socket.lastChatMessage = message;
        }
    },
    onConnectError(error) {
        if (error.type == "UnauthorizedError" || error.type == "TransportError") {
            socket.recreate();
        }
    },
};

const socket = {
    listeners: [],
    liveStatistics: new LiveStatistics(options),
    lastChatMessage: null,
    lastCallMessage: null,
    recreate() {
        console.log('recreated'); // Пока оставить
        const old = this.liveStatistics;
        this.liveStatistics = new LiveStatistics(options);
        old.destroy();
    },
    subscribe(cb) {
        this.listeners.push(cb);
    },
    unsubscribe(cb) {
        const idx = this.listeners.indexOf(cb);
        this.listeners.splice(idx, 1);
    },
    addGroupAndSubscribe(group, cb) {
        try {
            if (options.groups.some(el => el.source === group.source)) {
                return
            }
            this.liveStatistics.subscribeGroups([group]);
            this.subscribe(cb);
        } catch (e) {}
    },
    removeGroupAndUnsubscribe(group, cb) {
        try {
            this.liveStatistics.unsubscribeSource([group]);
            this.unsubscribe(cb);
        } catch (e) {}
    },
};

export default socket;
