<template>
    <ModalWrapper transparent #default="{close}" ref="modal">
        <div class="filials-list-modal">
            <div class="filials-list-modal__close" @click="close">
                <AppIcon icon="close"/>
            </div>
            <div class="filials-list-modal__sidebar filials-sidebar">
                <transition name="opacity">
                    <div v-if="sidebarTopHidden" class="filials-sidebar__top-overlay filial-overlay"></div>
                </transition>
                <transition name="opacity">
                    <div v-if="sidebarBottomHidden" class="filials-sidebar__bottom-overlay filial-overlay"></div>
                </transition>
                <div class="filials-sidebar__name">{{ $t("components.common.listOfDepartments") }}</div>
                <div class="filials-sidebar__body" ref="sidebar" @scroll="onSidebarScroll">
                    <div v-for="(item, idx) in departmentsList" :key="item.code" class="filials-sidebar__item" :class="{'filials-sidebar__item--active': model.dept_code === item.code}" @click="selectDepartment(item.code)">
                        {{ idx + 1 + '. ' + item.name }}
                    </div>
                </div>
            </div>
            <div class="filials-list-modal__body">
                <div class="filials-list-modal__form">
                    <div class="filials-list-modal__field filials-field filials-field--type-search">
                        <AppIcon class="filials-field__icon" icon="search"/>
                        <AppInput :value="model.search" class="filials-field__input" :disabled="filialsLoading" :placeholder="$t('components.common.searchWithPoints')" @input="searchFilial" autoFocus ref="search-input"/>
                    </div>
                    <div class="filials-list-modal__field filials-field">
                        <div class="filials-field__legend">{{ $t("components.common.searchByBranches") }}</div>
                        <AppSelect :value="model.filial" class="filials-field__input" :disabled="filialsLoading" item-name="name" item-value="mfo" :items="filialsList" :placeholder="$t('components.common.branch')" @input="selectFilial"/>
                    </div>
                </div>

                <transition name="opacity">
                    <div v-if="bodyTopHidden" class="filials-list-modal__top-overlay filial-overlay"></div>
                </transition>
                <transition name="opacity">
                    <div v-if="bodyBottomHidden" class="filials-list-modal__bottom-overlay filial-overlay"></div>
                </transition>

                <PageLoader v-if="filialsLoading"/>
                <div v-else-if="filialsData.length" class="filials-list-modal__table-wrap" ref="body" @scroll="onBodyScroll">
                    <div v-for="(block, key) in filialsInfo" :key="key" class="filials-list-modal__table-item">
                        <div class="filials-list-modal__heading">{{ key }}</div>
                        <AppTable class="filials-list-modal__table" theme="filial" :head="filialsHead" :data="block" no-scroll>
                            <template #cell.cisco="{item}">
                                {{ getCiscoNumber(item) || '-' }}
                            </template>
                            <template #cell.actions="{item}">
                                <AppButton v-if="getCiscoNumber(item)" class="filials-list-modal__action-button"
                                           size="light" theme="primary-outline" @click="callUser(item.normalized_cisco)">{{ $t("components.common.call") }}</AppButton>
                            </template>
                        </AppTable>
                    </div>
                </div>
                <div v-else class="empty-title">{{ $t("components.common.nothingFound") }}</div>
            </div>
        </div>
    </ModalWrapper>
</template>
<script>
import ModalWrapper from "../partials/ModalWrapper";
import AppInput from "../partials/AppInput";
import AppIcon from "../partials/AppIcon";
import AppSelect from "../partials/AppSelect";
import AppTable from "../partials/AppTable";
import AppButton from "../partials/AppButton";
import {intranetClient} from "../../helpers/api";
import ListUtils from "../../mixins/ListUtils";
import PageLoader from "../partials/PageLoader";
import {EventBus} from "../../helpers/eventBus";

export default {
    name: 'FilialsListModal',
    components: {PageLoader, AppButton, AppTable, AppSelect, AppIcon, AppInput, ModalWrapper},
    mixins: [ListUtils],
    data() {
        return {
            departmentsList: [],
            filialsList: [],
            filialsHead: [[
                {
                    name: 'common.fio',
                    key: 'full_name'
                },
                {
                    name: 'common.position',
                    key: 'position',
                },
                {
                    name: 'common.status',
                    key: 'condition_name',
                    width: '20%',
                },
                {
                    name: 'common.extensionNumber',
                    key: 'cisco',
                    width: '15%',
                },
                {
                    name: 'common.actions',
                    key: 'actions',
                    width: '174px'
                },
            ]],
            filialsData: [],
            sidebarTopHidden: false,
            sidebarBottomHidden: false,
            bodyTopHidden: false,
            bodyBottomHidden: false,
            scrollTimeout: false,
            filialsError: false,
            filialsLoading: false,
            model: {
                filial: null,
                dept_code: null,
                search: ''
            },
        }
    },
    computed: {
        filialsInfo() {
            return this.filialsData.reduce((acc, filial, idx) => {
                const depName = filial.dep_name || 'Другое';

                if(depName in acc) {
                    const data = acc[depName];
                    data.push(filial);
                } else {
                    acc[depName] = [filial];
                }
                return acc;
            }, {});
        },
        departmentName() {
            if(this.model.dept_code) {
                const department = this.departmentsList.find(department => department.code === this.model.dept_code)
                if(department) {
                    return department.name;
                }
            }
            return '';
        },
    },
    mounted() {
        this.getFilialsList();
    },
    methods: {
        getCiscoNumber(item) {
            return item.cisco && item.normalized_cisco !== '0000' ? item.normalized_cisco : null;
        },
        getFilialsList() {
            this.filialsList = this.$store.state.filialsList;
            let mfo = '09013';
            const mainMFO = this.filialsList.find(filial => {
                return filial.mfo == mfo
            });
            if(!mainMFO) {
                mfo = this.filialsList[0].mfo
            }
            this.selectFilial(mfo);
        },
        getDepartmentsList(model) {
            this.filialsLoading = true;
            intranetClient(`/departments/?filial=${model.filial}&page_size=50000`)
                .then(res => {
                    this.departmentsList = res.data.results.sort((a, b) => a.dep_id - b.dep_id);
                    this.filialsLoading = false;
                    this.selectDepartment(this.departmentsList[0].code);
                    this.$nextTick(() => {
                        this.scrollLogic('sidebar');
                    })
                })
                .catch(e => {
                    this.filialsError = true;
                })
                .finally(() => {
                    this.filialsLoading = false;
                })
        },
        getUsersList(model) {
            this.filialsLoading = true;
            const filter = Object.keys(model).reduce((acc, curr) => {
                let value = model[curr];
                if(value) {
                    if(curr === 'search') {
                        value = encodeURIComponent(value);
                    }
                    return `${acc}&${curr}=${value}`;
                }
                return acc;
            }, '')
            this.filialsData = [];
            intranetClient(`/phonebook/?ordering=-order_by&page_size=50000${filter}`)
                .then(res => {
                    this.filialsData = res.data.results;
                    this.$nextTick(() => {
                        this.scrollLogic('body');
                    })
                    this.getSubdepartmentsUsersList();
                })
                .catch(e => {
                    this.filialsError = true;
                    this.filialsLoading = false;
                })
                .finally(() => {
                    this.$refs['search-input'].focus();
                })
        },
        flattenDepartment(department) {
            return department.reduce((acc, curr) => {
                return acc.concat(this.flattenDepartment(curr.sub_departments))
            }, department);
        },
        getSubdepartmentsUsersList() {
            const selectedDepartment = this.departmentsList.find(department => department.code === this.model.dept_code);
            if(selectedDepartment) {
                const flatten = this.flattenDepartment(selectedDepartment.sub_departments);
                const loadedFilials = [];
                let dataResults = [...this.filialsData];

                if(!flatten.length) {
                    this.filialsLoading = false;
                    this.$refs['search-input'].focus();
                    return;
                }

                flatten.forEach(sub_dep => {
                    intranetClient(`/phonebook/?ordering=-order_by&page_size=50000&filial=${this.model.filial}&dept_code=${sub_dep.code}`)
                        .then(res => {
                            dataResults = dataResults.concat(res.data.results);
                            loadedFilials.push(sub_dep.code);

                            if(loadedFilials.length === flatten.length) {
                                this.filialsLoading = false;
                                this.filialsData = dataResults;
                                this.$nextTick(() => {
                                    this.scrollLogic('body');
                                    this.$refs['search-input'].focus();
                                })
                            }
                        })
                        .catch(e => {
                            this.filialsError = true;
                            this.filialsLoading = false;
                        })
                })
            }
        },
        selectFilial(filial) {
            if(this.filialsLoading) return;

            this.model = {
                filial,
                dept_code: null,
                search: ''
            }
            this.getDepartmentsList(this.model);
        },
        selectDepartment(dept_code) {
            if(this.filialsLoading) return;

            this.model = {
                ...this.model,
                dept_code,
                search: ''
            }
            this.getUsersList(this.model);
        },
        searchFilial(search) {
            if(this.filialsLoading) return;

            this.model = {
                ...this.model,
                search
            }
            this.delay(() => {
                const model = {
                    ...this.model,
                }

                if(this.model.search) {
                    delete model.dept_code;
                    delete model.filial;
                }


                this.getUsersList(model);
            })
        },
        scrollLogic(name) {
            const block = this.$refs[name];
            if(!block) return;
            this[name + 'TopHidden'] = block.scrollTop >= 3;
            this[name + 'BottomHidden'] = block.scrollTop + block.offsetHeight <= block.scrollHeight - 3;
        },
        onScroll(name) {
            if(this.scrollTimeout) return;

            this.scrollTimeout = true;
            setTimeout(() => {
                this.scrollTimeout = false;
                this.scrollLogic(name);
            }, 10);
        },
        onBodyScroll() {
            this.onScroll('body')
        },
        onSidebarScroll() {
            this.onScroll('sidebar')
        },
        callUser(phone) {
            EventBus.$emit('make-call', {phone});
            this.$refs.modal.close();
        },
    }
}
</script>
<style lang="scss">
@import '../../assets/scss/variables';
.filial-overlay {
    pointer-events: none;
    position: absolute;
    background: linear-gradient(to top, var(--overlay-color) 13.48%, transparent 100%);
    width: 100%;
    height: 130px;
    border-radius: inherit;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    z-index: 1;
}
.filials-sidebar {
    --overlay-color: var(--color-primary-dark);
    background: var(--color-primary-dark);
    padding: 32px 0 0 42px;
    color: var(--color-white);
    position: relative;
    border-radius: 12px 0 0 12px;
    &__top-overlay,
    &__bottom-overlay {
        bottom: 0;
        left: 0;
    }
    &__top-overlay {
        transform: rotate(180deg);
        bottom: auto;
        top: 92px;
    }
    &__body {
        height: calc(100% - 60px);
        padding-bottom: 32px;
        overflow-y: scroll;
        &::-webkit-scrollbar-thumb {
            background-color: var(--scroll-primary);
        }
    }
    &__name {
        margin-bottom: 26px;
        font-weight: 500;
        font-size: 28px;
        line-height: 34px;
    }
    &__item {
        cursor: pointer;
        user-select: none;
        padding: 19px 0 18px 24px;
        position: relative;
        z-index: 0;
        &:before {
            transition: transform .25s;
            content: '';
            background: var(--color-primary);
            z-index: -1;
            position: absolute;
            right: 0;
            top: 0;
            width: 100%;
            height: 100%;
            transform-origin: 100% 0;
            transform: scaleX(0);
            border-radius: 12px 0 0 12px;
        }
        &--active {
            &:before {
                transform: none;
            }
        }
    }
}
.filials-field {
    $self: &;
    position: relative;
    border: 1px solid var(--color-border);
    border-radius: 4px;
    z-index: 0;
    &__input {
        width: 100%;
        height: 46px;
        font-weight: 500;
        font-size: 16px;
        line-height: 20px;
        letter-spacing: 0.005em;
        font-family: $font-secondary;
        padding-left: 17px;
        background-color: transparent;
        border: 0;
        margin-left: 0;
        .ant-select-selection {
            background-color: transparent;
            border: 0;
            height: 46px;
            margin-left: -17px;
        }
        .ant-select-selection__rendered {
            width: 100%;
            height: 100%;
            margin: 0;
            padding-left: 17px;
            display: flex;
            align-items: center;
        }
    }
    &__legend {
        z-index: 1;
        position: absolute;
        top: -16px;
        left: 7px;
        padding: 10px 10px 5px;
        background-color: var(--color-white);
        font-weight: 500;
        font-size: 10px;
        line-height: 12px;
        color: var(--color-gray-lighten);
        font-family: $font-secondary;
    }
    &--type-search {
        #{$self}__icon {
            z-index: 1;
            position: absolute;
            top: 50%;
            margin-top: -9px;
            left: 14px;
            width: 18px;
            height: 18px;
            color: var(--color-white-darken);
        }
        #{$self}__input {
            padding-left: 47px;
        }
    }
}
.filials-list-modal {
    --overlay-color: var(--color-white);
    width: 1584px;
    background-color: var(--color-white);
    border-radius: 12px;
    padding-left: 458px;
    position: relative;
    overflow: hidden;
    &__sidebar {
        width: 458px;
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
    }
    &__body {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        padding: 67px 40px 0 42px;
        height: calc(100vh - 228px);
        min-height: 520px;
    }
    &__form {
        margin-bottom: 42px;
        display: flex;
        justify-content: space-between;
    }
    &__field {
        width: calc(50% - 10px);
    }
    &__heading {
        margin-bottom: 32px;
        font-weight: 500;
        font-size: 28px;
        line-height: 34px;
    }
    &__table-wrap {
        position: relative;
        padding: 0 40px 0 42px;
        margin-left: -42px;
        margin-right: -40px;
        flex-grow: 1;
        height: 100px;
        overflow-y: auto;
    }
    &__table {
        margin-left: -42px;
        margin-right: -40px;
        margin-bottom: 22px;
    }
    &__footer {
        padding-right: 20px;
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
    }
    &__action-button {
        width: 134px;
        svg {
            width: 16px;
            height: 16px;
            margin-left: 4px;
        }
    }
    &__close {
        cursor: pointer;
        user-select: none;
        position: absolute;
        top: 0;
        right: 0;
        padding: 16px;
        svg {
            width: 24px;
            height: 24px;
            display: block;
        }
    }
    &__top-overlay,
    &__bottom-overlay {
        bottom: 0;
        left: 458px;
        width: calc(100% - 458px - 6px);
    }
    &__top-overlay {
        bottom: auto;
        top: 157px;
        transform: rotate(180deg);
    }
}
</style>
