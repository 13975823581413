<template>
    <div class="notifications-layout">
        <transition-group name="notifications-list">
            <div v-for="item in list" :key="item.id" class="notifications-layout__item" :class="item.type">
                <div class="notifications-layout__icon" :class="item.type">
                    <AppIcon :icon="getIconByNotificationType(item.type)" />
                </div>
                <div>
                    <div class="notifications-layout__title" v-html="$t(item.title)"></div>
                    <div class="notifications-layout__text" v-html="$t(item.message)"></div>
                </div>
                <button class="notifications-layout__close" @click="removeItem(item.id)">
                    <AppIcon icon="close-small"/>
                </button>
            </div>
        </transition-group>
    </div>
</template>
<script>
import AppIcon from "../partials/AppIcon";
import {mapState} from "vuex";
import app from "@/main";

export default {
    name: 'NotificationsView',
    components: {AppIcon},
    data() {
        return {
            removeTimer: null
        }
    },
    computed: {
        ...mapState('notifications', ['list'])
    },
    watch: {
        '$store.state.notifications.list': 'startRemoveTimer'
    },
    beforeDestroy() {
        if(this.removeTimer) {
            clearTimeout(this.removeTimer);
        }
    },
    methods: {
        getIconByNotificationType(type) {
            switch (type) {
                case "error":
                    return "alert";
                case "success":
                    return "circle-check-success"
                default:
                    return "alert";
            }
        },
        startRemoveTimer() {
            if(this.removeTimer || !this.list.length) return;
            this.removeTimer = setTimeout(() => {
                this.$store.commit('notifications/removeLast');
                this.removeTimer = null;
                if(this.list.length) {
                    this.startRemoveTimer();
                }
            }, 5000);
        },
        removeItem(id) {
            if(this.removeTimer) {
                clearTimeout(this.removeTimer);
                this.removeTimer = null;
            }
            this.$store.commit('notifications/remove', id);
        }
    }
}
</script>
<style lang="scss">
@import '../../assets/scss/variables';
.notifications-layout {
    position: fixed;
    top: 0;
    right: 0;
    padding-right: 64px;
    padding-top: 20px;
    width: 716px;
    max-width: 100%;
    z-index: 9999;
    &__item {
        width: 100%;
        display: flex;
        align-items: center;
        margin-bottom: 16px;
        background: var(--color-white);
        box-shadow: 0px 2px 30px var(--shadow-avatar-02);
        border-radius: 12px;
        padding: 30px 33px 34px 45px;
        position: relative;
        &:before {
            content: '';
            position: absolute;
            top: 12px;
            left: 17px;
            bottom: 12px;
            width: 6px;
        }
        &.success {
            &:before {
                background-color: var(--color-success);
            }
        }
        &.error {
            &:before {
            background-color: var(--color-danger-dark);
            }
        }

        }
    &__title {
        margin-bottom: 11px;
        font-weight: 500;
        font-size: 24px;
        line-height: 29px;
        color: var(--color-dark);
        font-family: $font-secondary;
    }
    &__text {
        font-weight: 400;
        font-size: 18px;
        line-height: 20px;
        letter-spacing: 0.005em;
        color: var(--color-gray-lighten);
    }
    &__icon {
        flex-shrink: 0;
        padding: 6px;
        border-radius: 100%;
        margin-right: 27px;

        svg {
            width: 100%;
            height: 100%;
            display: block;
        }

        &.error {
            width: 44px;
            height: 44px;
            color: var(--color-white);
            margin-right: 27px;
            background-color: var(--color-danger-dark);
        }

        &.success {
            width: 54px;
            height: 54px;
            svg {
                &:not([fill=none]) {
                    fill: #00CC56;
                }
                [stroke] {
                    stroke: #FFFFFF;
                }
                [fill]:not([fill=none]) {
                    fill: #00CC56;
                }
            }
        }
    }
    &__close {
        flex-shrink: 0;
        padding: 4px;
        width: 32px;
        height: 32px;
        background-color: transparent;
        margin-left: auto;
        svg {
            width: 100%;
            height: 100%;
            display: block;
            color: var(--color-gray-lighten);
        }
    }
}
.notifications-list {
    &-enter-active, &-leave-active, &-move {
        transition: transform .25s;
    }
    &-enter,
    &-leave-to {
        transform: translateX(780px);
    }
}
</style>
