import { FinesseAgent } from "@contact-center/asakabank-cisco-finesse";
import app from "../main";
import { oSecureLS, sUserKey } from "../helpers/api";

const { password } = oSecureLS.get(sUserKey);

const finasseAgent = FinesseAgent.getInstance({
    username: app.$store.state.auth.username,
    password,
    extension: app.$store.state.auth.phone,
    domain: process.env.VUE_APP_FINESSE_AGENT_DOMAIN,
});
export default finasseAgent;
