<template>
  <div class="group-message header-trigger" :class="{'header-trigger--active': visible}">
    <div v-if="visible" class="group-message__overlay" @click="close"></div>
    <div class="group-message__trigger header-trigger__button" v-tooltip="$t('layout.header.groupMessage.title')" @click="trigger">
      <img src="../../assets/img/group-chat-bubble.svg" alt="">
    </div>
    <transition name="dialer">
      <div v-if="visible" class="group-message__window header-trigger__window">
        <div class="group-message__header">{{ $t("layout.header.groupMessage.title") }}</div>
        <div class="group-message__body">
          <div class="group-message__item">
            <div class="group-message__label">{{ $t("layout.header.groupMessage.create") }}</div>
            <AppTextarea v-model="model.message"
                         class="group-message__input group-message__input--textarea application-input"
                         :error="modelError.message" :max-length="255" @input="modelError.message = false"/>
            <div class="group-message__additional">{{
                model.message.length
              }}/{{ $t("layout.header.groupMessage.maxLetter") }}
            </div>
          </div>
          <div class="group-message__item">
            <div class="group-message__label">{{ $t("layout.header.groupMessage.selectGroup") }}</div>
            <AppSelect v-model="model.team" class="group-message__input application-input" mode="multiple"
                       :error="modelError.team" :items="userTeams" item-name="name"
                       :placeholder="$t('layout.header.groupMessage.selectGroup')" @change="modelError.team = false"/>
          </div>
          <div class="group-message__item">
            <div class="group-message__label">{{ $t("layout.header.groupMessage.selectDelay") }}</div>
            <AppTimePicker v-model="model.time" class="group-message__input application-input" :error="modelError.time"
                           :disabled-minutes="disabledMinutes" @change="modelError.time = false"/>
          </div>
          <div class="group-message__footer">
            <div class="block-wrap">
              <AppButton class="group-message__button" theme="gray" @click="reset">{{ $t("common.cancel") }}</AppButton>
              <AppButton class="group-message__button" @click="submit">
                <AppIcon icon="send-message"/>
                {{ $t("common.send") }}
              </AppButton>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>
<script>
import AppTextarea from "../partials/AppTextarea";
import AppButton from "../partials/AppButton";
import AppIcon from "../partials/AppIcon";
import AppSelect from "../partials/AppSelect";
import clickaway from "../../directives/clickaway";
import {EventBus} from "../../helpers/eventBus";
import {mapState} from "vuex";
import AppTimePicker from "../partials/AppTimePicker";

export default {
  name: 'GroupMessage',
  components: {AppTimePicker, AppSelect, AppIcon, AppButton, AppTextarea},
  directives: {
    clickaway
  },
  data() {
    return {
      visible: false,
      model: {
        team: [],
        message: '',
        time: '00:05'
      },
      modelError: {
        team: false,
        message: false,
        time: false
      }
    }
  },
  computed: {
    ...mapState(['userTeams'])
  },
  methods: {
    disabledMinutes(selectedHours) {
      if (selectedHours === 0) {
        return [0]
      }
      return []
    },
    trigger() {
      if (this.disabled) return;

      this.visible = !this.visible
    },
    close() {
      this.visible = false;
    },
    onClickaway() {
      this.close();
    },
    submit() {
      if (!this.model.team.length) {
        this.$set(this.modelError, 'team', true);
      } else {
        this.$set(this.modelError, 'team', false);
      }
      if (!this.model.message) {
        this.$set(this.modelError, 'message', true);
      } else {
        this.$set(this.modelError, 'message', false);
      }
      if (!this.model.time) {
        this.$set(this.modelError, 'time', true);
      } else {
        this.$set(this.modelError, 'time', false);
      }
      if (this.modelError.team || this.modelError.message || this.modelError.time) return;

      EventBus.$emit('send-team-message', this.model);
      this.close();
    },
    reset() {
      this.model = {
        team: [],
        message: '',
        time: '23:59'
      };
      this.close()
    }
  }
}
</script>
<style lang="scss">
@import '../../assets/scss/variables';

.group-message {
  &__overlay {
    cursor: pointer;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 1;
  }

  &__window {
    z-index: 2;
    right: -68px;
    width: 384px;
    background: var(--color-white);
    box-shadow: var(--shadow-button);
    border-radius: 12px;
  }

  &__header {
    margin-bottom: 20px;
    border-radius: 12px 12px 0 0;
    padding: 24px 20px 20px;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: var(--color-primary);
    background: var(--color-white-bluesh);
  }

  &__body {
    padding: 0 20px 24px;
  }

  &__label {
    margin: 0 0 8px;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
  }

  &__item {
    margin: 0 0 24px;
  }

  &__additional {
    margin: 6px 0 0;
    text-align: right;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    color: var(--color-gray-lighten);
  }

  &__input--textarea {
    min-height: 120px !important;
  }

  &__button {
    flex-grow: 1;

    svg {
      width: 24px;
      height: 24px;
      margin-right: 16px;
    }
  }

  .ant-time-picker-input,
  .ant-select-selection,
  .ant-input {
    font-family: $font-secondary;
    height: 36px;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
  }

  .ant-select-selection__rendered {
    line-height: 34px;
  }
}
</style>
