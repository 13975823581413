<template>
    <ModalWrapper class="subscribe-modal" @close-modal="$emit('close-modal')">
        <h3 class="subscribe-modal__header">{{ $t("components.common.notifications") }}</h3>
        <AppTable class="subscribe-modal__table" :head="messagesHead" :data="messages" theme="admin-page">
            <template #cell.number="{rowIndex}">{{ rowIndex + 1 }}</template>
            <template #cell.createdAt="{item}">{{ formatTimeStamp(item.createdAt) }}</template>
        </AppTable>
    </ModalWrapper>
</template>

<script>
import {directive as onClickAway} from "vue-clickaway";
import AppTable from "./partials/AppTable";
import ModalWrapper from "./partials/ModalWrapper";
import FilterDate from "../mixins/FilterDate";

export default {
    components: { ModalWrapper, AppTable},
    directives: {
        onClickAway: onClickAway
    },
    mixins: [FilterDate],
    props: {
        messages: Array
    },
    data() {
        return {
            messagesHead: [[
                {
                    name: '№',
                    key: 'number',
                    width: '136px'
                },
                {
                    name: 'Текст',
                    key: 'message',
                    size: '100%',
                },
                {
                    name: 'Автор',
                    key: 'createdBy',
                    width: '224px',
                },
                {
                    name: 'Дата и время',
                    key: 'createdAt',
                    width: '186px'
                },
            ]]
        }
    },
}
</script>
<style lang="scss">
@import '@/assets/scss/variables';
.subscribe-modal {
    font-family: $font-primary;
    &__header {
        margin: 0 0 29px;
        font-weight: 500;
        font-size: 32px;
        line-height: 38px;
    }
    &__table {
        margin-left: -42px;
        margin-right: -42px;
    }
}
</style>
