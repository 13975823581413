<template>
    <div
        class="app-a page-preload"
        :class="{
            'app-a--admin': isSidebarVisible,
            'page-preload--loading': !criticalLoaded,
            'app-a--sidebar-opened': sidebarOpened && isSidebarVisible,
        }"
    >
        <transition name="page-preload">
            <div v-if="!criticalLoaded" class="page-preload__loader">
                <div>
                    <AppIcon
                        class="page-preload__icon"
                        icon="critical-loader"
                    />
                    <div class="page-preload__name">
                        {{ $t("errors.waitingForYourFavoriteWork") }}
                    </div>
                </div>
            </div>
        </transition>
        <div class="app-a__wrapper page-preload__content">
            <TheSidebar v-if="isSidebarVisible" />
            <NotificationsView />
            <div class="app-a__content">
                <TheHeaderNew
                    ref="header"
                    @open-calls="switchOperatorMode(false)"
                    @open-chats="switchOperatorMode(true)"
                />
                <Subscribe />
                <div class="app-a__page">
                    <HomePage
                        :class="{
                            'home-page-hidden': !isHomePage,
                            'home-page-show-alarm': (newCall || incomingChat) && !isHomePage,
                        }"
                    />
                    <router-view />
                </div>
            </div>
        </div>
        <BaseTooltip />
        <FilialsListModal
            v-if="modalsStore.filialsList && isCSINavItemDisplayed"
            @close-modal="closeModal('filialsList')"
        />
        <ImageGallery />
        <ModalsController />
    </div>
</template>
<script>
import Subscribe from "../components/Subscribe.vue";
import { get, logout, authCheck, intranetClient } from "../helpers/api";
import { mapActions, mapState } from "vuex";
import TheHeaderNew from "./TheHeaderNew";
import NotificationsView from "../components/layout/NotificationsView";
import FilialsListModal from "../components/modals/FilialsListModal";
import TheSidebar from "./TheSidebar";
import BaseTooltip from "../components/layout/BaseTooltip";
import "../assets/scss/app-admin-page.scss";
import ImageGallery from "../components/partials/ImageGalley";
import { isSidebarVisible } from "../config/navigation";
import LeaveModal from "../components/LeaveModal";
import ModalsController from "../components/modals/ModalsController";
import LangController from "../services/LangController";
import AppIcon from "../components/partials/AppIcon";
import HomePage from "../components/pages/HomePage.vue";

export default {
    name: "TheContainer",
    components: {
        AppIcon,
        ModalsController,
        LeaveModal,
        ImageGallery,
        BaseTooltip,
        TheSidebar,
        FilialsListModal,
        NotificationsView,
        TheHeaderNew,
        Subscribe,
        HomePage,
    },
    data() {
        return {
            modals: {
                dontHold: false,
            },
        };
    },
    computed: {
        ...mapState([
            "status",
            "call",
            "callingNumber",
            "whichNumber",
            "isLoading",
            "isCalling",
            "card_stat",
            "isIncomingCall",
            "bank_client_id",
            "connectionError",
            "criticalLoaded",
        ]),
        ...mapState('chat', ['incomingChat']),
        ...mapState({
            modalsStore: (state) => state.modals.modals || {},
            newCall: (state) => state.call,
        }),
        ...mapState("modals", {
            modalsStore: (state) => state.modals || {},
        }),
        ...mapState("system", ["sidebarOpened"]),
        isSidebarVisible,
        isCSINavItemDisplayed() {
            return process.env.VUE_APP_HIDE_CSI_SETTING !== "true";
        },
        isHomePage() {
            return this.$route.name === "ClientCard";
        },
    },
    created() {
        this.logoutIfNeedOrFetchData();
    },
    methods: {
        ...mapActions({
            getApplicationStatuses: "getApplicationStatuses",
        }),
        logoutIfNeedOrFetchData() {
            if (!authCheck()) {
                logout();
            } else {
                const criticalData = [
                    this.fetchAppTypesAndSaveToStore(),
                    this.fetchScriptTextsAndSetApplicationInfo(),
                    this.fetchMarketingSourcesAndSaveToStore(),
                    this.getApplicationStatuses(),
                    this.fetchFilialsListAndSaveToStore(),
                    this.fetchIncomingClientSources(),
                    this.fetchLangsListAndSetGlobally(),
                ];
                Promise.all(criticalData)
                    .catch(() => {
                        this.$store.commit("emitConnectionError");
                    })
                    .finally(() => {
                        this.$store.commit("setCriticalDataLoaded");
                    });
                this.fetchCardActions();
                this.fetchFilialsListFromIntranetAndSaveToStore();
            }
        },
        fetchLangsListAndSetGlobally() {
            return get("languages").then((res) => {
                LangController.setLangsList(res.data);
            });
        },
        fetchIncomingClientSources() {
            return get("sources").then((res) => {
                if (res.status == 200) {
                    this.$store.commit(
                        "setIncomingClientSources",
                        res.data.data
                    );
                }
            });
        },
        fetchAppTypesAndSaveToStore() {
            return get("app-types").then((res) => {
                if (res.status == 200) {
                    this.$store.commit("getdictionary", res.data.data);
                }
            });
        },
        fetchScriptTextsAndSetApplicationInfo() {
            return get("scripts/voice").then((res) => {
                res.data.data = res.data.data.map((item) => ({
                    ...item,
                    text_qq: item["text_" + LangController.defaultLang],
                }));
                this.$store.commit(
                    "getcreateApp",
                    res.data.data.find((item) => item.slug == "create-app")
                );
                this.$store.commit(
                    "getknown",
                    res.data.data.find((item) => item.slug == "known")
                );
                this.$store.commit(
                    "getunknown",
                    res.data.data.find((item) => item.slug == "unknown")
                );
                this.$store.commit(
                    "getbye_script",
                    res.data.data.find((item) => item.slug == "bye")
                );
                this.$store.commit(
                    "getSelectClientScript",
                    res.data.data.find((item) => item.slug == "select-one")
                );
            });
        },
        fetchMarketingSourcesAndSaveToStore() {
            return get("marketing-sources").then((res) => {
                this.$store.commit("getmarketing_sources", res.data.data);
            });
        },
        fetchFilialsListAndSaveToStore() {
            return get("/branches?limit=100").then((res) => {
                if (res.data.success) {
                    this.$store.commit("updateBankBranches", res.data.data);
                }
            });
        },
        fetchFilialsListFromIntranetAndSaveToStore() {
            return intranetClient("/filials/?page_size=1000").then((res) => {
                const filialsMFO = [];
                const filialsList = res.data.results.filter((filial) => {
                    const notIncludes = !filialsMFO.includes(filial.mfo);
                    if (notIncludes) {
                        filialsMFO.push(filial.mfo);
                    }
                    return notIncludes;
                });
                this.$store.commit("setFilialsList", filialsList);
            });
        },
        fetchCardActions() {
            return ["mastercard", "humo", "uzcard"].map((key) => {
                return get("/card-action/actions-dictionary/" + key).then(
                    ({ data }) => {
                        this.$store.commit("activeOperations/setProcessing", {
                            key,
                            value: data.data,
                        });
                    }
                );
            });
        },
        closeModal(modalName) {
            this.$store.commit("modals/closeModal", modalName);
        },
    },
};
</script>

<style lang="scss">
.page-preload {
    position: relative;

    &__loader {
        z-index: 100;
        position: fixed;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        display: flex;
        align-items: center;
        justify-content: center;
        background: rgba(237, 242, 255, 0.88);
    }

    &__icon {
        display: block;
        width: 240px;
        height: 120px;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 16px;
    }

    &__name {
        font-weight: 500;
        font-size: 18px;
        line-height: 21px;
        color: #454142;
    }

    &__content {
        transition: filter 0.3s;
    }

    &--loading &__content {
        filter: blur(10px);
    }

    &-leave-active {
        transition: opacity 0.3s, transform 0.5s;
        transition-delay: 0.2s, 0s;
    }

    &-leave {
        opacity: 1;
    }

    &-leave-to {
        opacity: 0;
        transform: scale(1.25);
    }
}

.app-a {
    background: var(--color-white-bg);

    &__wrapper {
        transition: padding-left 0.25s;
        padding-left: var(--sidebar-width);
        position: relative;
        padding-top: var(--header-height);
    }

    &__page {
        display: flex;
        flex-grow: 1;

        > * {
            width: 100%;
        }
    }

    &__content {
        display: flex;
        flex-direction: column;
        min-height: calc(100vh - var(--header-height));
    }

    &--admin {
        --sidebar-width: 48px;
    }

    &--sidebar-opened {
        --sidebar-width: 182px;
        --sidebar-toggle-bg: var(--color-primary);
        --sidebar-toggle-color: #fff;
    }
}

body .ant-input {
    z-index: initial !important;
}

body .my-container {
    width: 100% !important;
}

.app-a__page {
    flex-direction: column;
}

.home-page.home-page-hidden {
    position: fixed;
    z-index: -1;

    &.home-page-show-alarm {
        position: static;
        z-index: 10;

        height: 60px;

        .calls-view {
            display: none;
        }
    }
}
</style>
