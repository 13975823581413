<template>
    <div class="header-trigger" :class="{'header-trigger--active': active}">
        <div class="header-trigger__button" v-tooltip="$t('layout.header.branchList.list')" @click="trigger">
            <AppIcon icon="book-bookmark"/>
        </div>
    </div>
</template>
<script>
import AppIcon from "../partials/AppIcon";
export default {
    name: 'FilialsList',
    components: {AppIcon},
    computed: {
        active() {
            return this.$store.state.modals.modals.filialsList
        }
    },
    methods: {
        trigger() {
            this.$store.commit('modals/openModal', 'filialsList')
        }
    }
}
</script>
