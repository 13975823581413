<template>
    <ModalWrapper v-slot="{close}" ref="modal">
        <div :class="$style['stickers-modal']">
            <div :class="$style['stickers-modal__header']">
                <img :class="$style['stickers-modal__icon']" src="../../assets/img/bx_wink-smile.svg" alt="">
                <div>
                    <h2 :class="[$style['stickers-modal__name'], 'mb-16']">{{ $t("components.common.stickerPacks") }}</h2>
                    <p :class="$style['stickers-modal__subname']">{{ $t("components.common.selectTheStickerPackThatSuitsYouAndClickSave") }} </p>
                </div>
            </div>
            <div :class="[$style['stickers-modal__body'], $style['stickers-modal__wrap']]">
                <label v-for="item in stickersList" :key="item.id" :class="[$style['stickers-modal__item'], $style['sticker-item']]">
                    <div :class="$style['sticker-item__poster-wrap']">
                        <img :src="item.images[0]" alt="" :class="$style['sticker-item__poster']">
                    </div>
                    <div :class="$style['sticker-item__footer']">
                        <div>
                            <p :class="$style['sticker-item__name']">{{ item.name }}</p>
                            <p :class="$style['sticker-item__subname']">{{ item.description }}</p>
                        </div>
                        <a-radio :checked="sticker === item.id" @change="sticker = item.id"/>
                    </div>
                </label>
            </div>
            <div :class="[$style['stickers-modal__footer'], $style['stickers-modal__wrap']]">
                <AppButton :class="[$style['stickers-modal__item'], $style['stickers-modal__item--button-cancel']]" theme="gray" @click="close">{{ $t("components.common.сancel") }}</AppButton>
                <AppButton :class="[$style['stickers-modal__item'], $style['stickers-modal__item--button-submit']]" @click="submit">
                    <AppIcon class="flex-shrink-0 mr-16" size="24" icon="checked"/>
                    {{ $t("components.common.save") }}
                </AppButton>
            </div>
        </div>
    </ModalWrapper>
</template>
<script>
import ModalWrapper from "../partials/ModalWrapper";
import AppButton from "../partials/AppButton";
import AppIcon from "../partials/AppIcon";
export default {
    name: 'WaitingImageModal',
    components: {AppIcon, AppButton, ModalWrapper},
    props: {
        value: Number,
        stickersList: Array
    },
    data() {
        return {
            sticker: this.value,
        }
    },
    methods: {
        submit() {
            this.$emit('submit', this.sticker)
            this.$refs.modal.close();
        }
    }
}
</script>
<style lang="scss" module>
@import '../../assets/scss/variables';
.sticker-item {
    cursor: pointer;
    transition: border .25s;
    background: var(--color-white);
    border: 1px solid var(--color-border);
    border-radius: 8px;
    padding: 31px;
    &__poster-wrap {
        margin-bottom: 18px;
        width: 100%;
        position: relative;
        padding-top: 100%;
    }
    &__poster {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: block;
    }
    &__footer {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-top: 23px;
        border-top: 1px solid var(--color-border);
    }
    &__name {
        margin: 0 0 4px;
        font-weight: 500;
        font-size: 16px;
        line-height: 19px;
    }
    &__subname {
        margin: 0;
        font-weight: normal;
        font-size: 12px;
        line-height: 15px;
        font-family: $font-secondary;
        color: var(--color-border-lighten);
    }
    &:hover {
        border: 1px solid var(--color-primary-light);
    }
}
.stickers-modal {
    font-family: $font-primary;
    &__header {
        display: flex;
        margin-bottom: 30px;
    }
    &__icon {
        width: 32px;
        height: 32px;
        opacity: 0.32;
        margin-right: 22px;
    }
    &__name {
        margin-top: 0;
        font-weight: 500;
        font-size: 32px;
        line-height: 38px;
    }
    &__subname {
        margin: 0;
        font-weight: 500;
        font-size: 16px;
        line-height: 19px;
        color: var(--color-gray-light);
    }
    &__wrap {
        display: flex;
        flex-wrap: wrap;
        margin-left: -32px;
        margin-top: -32px;
    }
    &__body {
        margin-bottom: 32px;
    }
    &__item {
        width: calc(100% / 5 - 32px);
        margin-left: 32px;
        margin-top: 32px;
        &--button-cancel {
            width: 118px;
        }
        &--button-submit {
            width: 200px;
        }
    }
    &__footer {
        justify-content: flex-end;
    }
}
</style>
