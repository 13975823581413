import TimerService from "../services/TimerService";

const disableChangeStatusTimer = new TimerService();
const agentChatStatusTimer = new TimerService();
const agentCallStatusTimer = new TimerService();

export {
    disableChangeStatusTimer,
    agentChatStatusTimer,
    agentCallStatusTimer
}
