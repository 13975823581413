<template>
  <div
    class="avatar-frame-a"
    :class="[{
      'avatar-frame-a--animating': isAnimating,
      'avatar-frame-a--transition': transition,
      'avatar-frame-a--disabled': disabled,
      'avatar-frame-a--is-enter': isEnter,
      ['avatar-frame-a--size-' + size]: size,
    }, 'avatar-frame-a--state-' + state]"
    @click="$emit('click')"
  >
    <slot name="avatar">
      <img
        class="avatar-frame-a__image"
        :src="data.avatar"
        alt="img"
      >
    </slot>
    <svg
      class="avatar-frame-a__icon"
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        class="avatar-frame-a__circle"
        cx="24"
        cy="24"
        r="22.6"
        stroke="currentColor"
        stroke-width="2.8"
      />
    </svg>
  </div>
</template>
<script>
export default  {
    name: "AvatarFrame",
    props: {
        state: String,
        data: Object,
        isEnter: Boolean,
        size: String,
        disabled: Boolean,
    },
    data() {
        return {
            animationTimer: null,
            isAnimating: false,
            transition: false
        };
    },
    beforeDestroy() {
        this.clearAnimation();
    },
    methods: {
        startAnimation() {
            if(this.animationTimer) {
                this.clearAnimation();
            }
            this.isAnimating = false;
            this.transition = true;
            this.$nextTick(() => {
                this.isAnimating = true;
                this.$emit("animation-start");
                this.animationTimer = setTimeout(() => {
                    this.clearAnimation();
                    this.$emit("animation-finish");
                }, 1000);
            });
        },
        clearAnimation() {
            this.transition = false;
            if(!this.isEnter) {
                this.isAnimating = false;
            }
            if(this.animationTimer) {
                clearTimeout(this.animationTimer);
                this.animationTimer = null;
            }
        },
    }
};
</script>
<style lang="scss">
.avatar-frame-a {
    $self: &;
    border-radius: 100%;
    padding: 6px;
    position: relative;
    color: var(--color-success-dark);
    &__icon {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
    &__circle {
        transform: rotateZ(-90deg);
        transform-origin: 50% 50%;
        stroke-dasharray: 142;
        stroke-dashoffset: 0;
    }
    &__image {
        border-radius: 100%;
        width: 100%;
        height: 100%;
        object-fit: cover;
        display: block;
    }
    &--transition {
        #{$self}__icon {
            transition: color 0s calc(var(--animation-duration, 1s) / 2);
        }
    }
    &--animating {
        #{$self}__circle {
            transition: stroke-dashoffset linear;
            transition-duration: var(--animation-duration, 1s);
            stroke-dashoffset: 284;
        }
    }
    &--is-enter {
        #{$self}__circle {
            stroke-dashoffset: 142;
        }
    }
    &--is-enter#{$self}--animating {
        #{$self}__circle {
            stroke-dashoffset: 0;
        }
    }
    &--state-pause {
        color: #397AF5;
    }
    &--state-not-ready,
    &--state-post-processing {
        color: var(--color-danger);
    }
    &--state-reserved,
    &--state-training,
    &--state-lunch {
        color: var(--color-warning-light);
    }
    &--state-answered {
        color: var(--color-training);
    }
    &--state-inactive {
        color: var(--color-gray-lighten-03);
    }
    &--state-overtime {
        color: var(--color-overtime);
    }
    &--state-disabled {
        color: transparent
    }
    &--size-small {
        padding: 4px;
        #{$self}__circle {
            stroke-width: 2px;
        }
    }
    &--disabled {
        color: transparent;
    }
}
</style>
