import {CustomerData} from "../../customer/CustomerModel";
import {
    clearSearchMessage,
    selectCustomer,
    BaseChatCommand,
    useUpdateChat,
} from "../../../helpers/customerHelpers";

export class StartCreateCustomerCommand extends BaseChatCommand {
    execute() {
        const [dataToUpdate, update] = useUpdateChat(this.chatData);
        if(this.chatData.selectedCustomer) {
            dataToUpdate.savedUser = this.chatData.selectedCustomer;
        }

        dataToUpdate.searchFailed = false;
        dataToUpdate.isNewClient = true;
        dataToUpdate.selectedPhone = '';
        dataToUpdate.selectedCustomer = null;
        dataToUpdate.customerForm = new CustomerData(null, this.chatData.data.phone);
        update();
    }
}
export class BackCustomersCommand extends BaseChatCommand {
    execute() {
        const [dataToUpdate, update] = useUpdateChat(this.chatData);
        dataToUpdate.selectedCustomer = null;
        dataToUpdate.isNewClient = false;
        dataToUpdate.searchFailed = false;
        update();
        clearSearchMessage(this.chatData);
    }
}
export class CancelCreateCustomerCommand extends BaseChatCommand {
    execute() {
        const [dataToUpdate, update] = useUpdateChat(this.chatData);
        if(this.chatData.savedUser) {
            selectCustomer(this.chatData.savedUser, this.chatData);
            dataToUpdate.savedUser = null;
            update();
        } else if(this.chatData.selectedCustomer) {
            selectCustomer(this.chatData.selectedCustomer, this.chatData);
        } else {
            dataToUpdate.searchFailed = false;
            dataToUpdate.isNewClient = false;
            dataToUpdate.customerForm = new CustomerData(null, this.chatData.data.phone);
            update();
        }
    }
}
export class SelectCustomerCommand extends BaseChatCommand {
    execute(id) {
        selectCustomer(id, this.chatData);
    }
}
