<template>
    <label class="app-checkbox-input container">
        <input type="checkbox" :value="value" :checked="value" :disabled="disabled">
        <span class="app-checkbox-input__checkmark"></span>
        <span class="app-checkbox-input__text">{{ $t(text) }}</span>
    </label>
</template>

<script>
export default {
    props: {
        value: {
            type: Boolean,
            required: true,
            default: false
        },
        disabled: {
            type: Boolean,
            required: false,
            default: false
        },
        text: {
            type: [String, Number, undefined, null],
            required: false,
            default: ""
        }
    }
}
</script>

<style src="@/assets/scss/modules/components/common/AppCheckboxInput.scss" lang="scss" scoped></style>
