<template>
    <section v-if="statisticsSource.length" class="waiting-statistics" :class="{ 'fixed-height': statisticsSource.length > 4 }">
        <AppTable :head="tableHead" :data="parsedStatistics" theme="statistics">
            <template #cell.channel="{item, rowIndex}">
                <div class="d-flex align-items-center">
                    <img
                        class="mr-14"
                        :src="item.waiting > 5 ? item.icon.replace('.svg', '-danger.svg') : item.waiting === 0 ? item.icon : item.icon.replace('.svg', '-success.svg')"
                        alt=""
                    />
                    <span :class="item.waiting > 5 ? 'color-danger' : item.waiting === 0 ? '' : 'color-success'">{{item.channel}}</span>
                </div>
            </template>
            <template #cell.waiting="{item}">
                <span :class="item.waiting > 5 ? 'color-danger' : item.waiting === 0 ? 'color-gray' : 'color-success'">{{item.waiting}}</span>
            </template>
            <template #cell.longest="{item}">
                <span :class="item.longest <= 0 ? 'color-gray' : ''">
                    {{item.longest < 0 ? 0 : item.longest | timeFromMS}}
                </span>
            </template>
        </AppTable>
    </section>
</template>
<script>
import AppTable from "./AppTable";
import {StatisticsProxyClass} from "../../helpers/StatisticsOperator";
import filterDate from "../../mixins/FilterDate";
import oCiscoConfig from "../../config/cisco";
import socket from '../../services/StatistiscsSocket';

export default {
    name: 'WaitingStatistics',
    components: {AppTable},
    mixins: [filterDate],
    props: {
        isChat: Boolean
    },
    data() {
        return {
            voiceStatistics: [],
            chatStatistics: [],
            currentTime: Date.now(),
            dateTimer: null
        }
    },
    computed: {
        tableHead() {
            if(this.isChat) {
                return [[
                    {
                        name: 'Канал обращения',
                        key: 'channel',
                    },
                    {
                        name: 'В очереди',
                        key: 'waiting',
                        width: '25%'
                    },
                ]];
            }
            return [[
                {
                    name: 'Канал обращения',
                    key: 'channel',
                    width: '50%'
                },
                {
                    name: 'В очереди',
                    key: 'waiting',
                    width: '25%'
                },
                {
                    name: 'Самое долгое ожидание',
                    key: 'longest',
                    width: '25%'
                },
            ]]
        },
        statisticsSource: {
            set(value) {
                if(this.isChat) {
                    this.chatStatistics = value
                } else {
                    this.voiceStatistics = value
                }
            },
            get() {
                return this.isChat ? this.chatStatistics : this.voiceStatistics;
            }
        },
        channelSource() {
            return this.isChat ? 'ChatQueueStatistics' : 'VoiceIAQStats'
        },
        channelsGroups() {
            if(this.isChat) {
                return oCiscoConfig.ChatGroups;
            }
            return oCiscoConfig.VoiceGroups;
        },
        parsedStatistics() {
            if(this.isChat) {
                return this.chatStatistics.map((message) => ({
                    channel: oCiscoConfig.ChatGroupsInfo[message.id].name,
                    icon: oCiscoConfig.ChatGroupsInfo[message.id].icon,
                    waiting: message.ChatQueueStatistics.contactsQueued,
                }));
            }
            return this.voiceStatistics.map(message => ({
                channel: oCiscoConfig.VoiceGroupsInfo[message.id].name,
                icon: oCiscoConfig.VoiceGroupsInfo[message.id].icon,
                waiting: message.VoiceIAQStats.nWaitingContacts,
                longest: message.VoiceIAQStats.longestCurrentlyWaitingCallStartTime ? this.currentTime - message.VoiceIAQStats.longestCurrentlyWaitingCallStartTime : 0
            }));
        },
        statisticsDriverType() {
            return this.isChat ? 6 : 1;
        }
    },
    watch: {
        '$store.state.serverTime'(value) {
            this.currentTime = value;
        }
    },
    mounted() {
        this.loadStatistic();
        this.dateTimer = setInterval(() => {
            this.currentTime = this.currentTime + 1000;
        }, 1000);
        socket.subscribe(this.onMessage);
    },
    beforeDestroy() {
        if(this.dateTimer) {
            clearInterval(this.dateTimer);
        }
        socket.unsubscribe(this.onMessage);
    },
    methods: {
        onMessage(message) {
            if(this.isChat && message.ChatQueueStatistics || !this.isChat && message.VoiceIAQStats) {
                const oldMessageIdx = this.statisticsSource.findIndex(data => data.id === message.id);

                if(oldMessageIdx > -1) {
                    this.statisticsSource.splice(oldMessageIdx, 1, message);
                } else {
                    this.statisticsSource.push(message);
                }
            }
        },
        loadStatistic() {
            const username = this.$store.state.auth.username;
            const oDriver = new StatisticsProxyClass(this.statisticsDriverType, username).getDrivers();
            if (null !== oDriver) {
                oDriver.getCallback().apply(this);
            }
        }
    }
}
</script>
<style lang="scss">
.waiting-statistics {
    position: relative;
    z-index: 1;
    background: var(--color-white);
    border-radius: 12px;
    overflow: hidden;
    width: 1188px;
    max-width: calc(100% - 40px);
    margin: 0 auto 32px;

    &.fixed-height > div {
        height: 290px;
    }
}
</style>
