<template>
    <div>
        <LogoutModal v-if="modals.leave" @close-modal="closeModal('leave')" />
        <OtchetModal v-if="modals.report" @close-modal="closeModal('report')" />
        <ABSCustomerModal v-if="modals.absCustomer" @close-modal="closeModal('absCustomer')"/>
    </div>
</template>
<script>
const ABSCustomerModal = () => import("./ABSCustomerModal");
const OtchetModal = () => import("../otchetModal");
const LogoutModal = () => import("@/components/modals/LogoutModal.vue");

export default {
    name: 'ModalsController',
    components: {
        OtchetModal, 
        ABSCustomerModal,
        LogoutModal
    },
    computed: {
        modals() {
            return this.$store.state.modals.modals;
        }
    },
    methods: {
        closeModal(name) {
            this.$store.commit('modals/closeModal', name);
        },
    }
}
</script>