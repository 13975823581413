var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"app-a page-preload",class:{
        'app-a--admin': _vm.isSidebarVisible,
        'page-preload--loading': !_vm.criticalLoaded,
        'app-a--sidebar-opened': _vm.sidebarOpened && _vm.isSidebarVisible,
    }},[_c('transition',{attrs:{"name":"page-preload"}},[(!_vm.criticalLoaded)?_c('div',{staticClass:"page-preload__loader"},[_c('div',[_c('AppIcon',{staticClass:"page-preload__icon",attrs:{"icon":"critical-loader"}}),_c('div',{staticClass:"page-preload__name"},[_vm._v(" "+_vm._s(_vm.$t("errors.waitingForYourFavoriteWork"))+" ")])],1)]):_vm._e()]),_c('div',{staticClass:"app-a__wrapper page-preload__content"},[(_vm.isSidebarVisible)?_c('TheSidebar'):_vm._e(),_c('NotificationsView'),_c('div',{staticClass:"app-a__content"},[_c('TheHeaderNew',{ref:"header",on:{"open-calls":function($event){return _vm.switchOperatorMode(false)},"open-chats":function($event){return _vm.switchOperatorMode(true)}}}),_c('Subscribe'),_c('div',{staticClass:"app-a__page"},[_c('HomePage',{class:{
                        'home-page-hidden': !_vm.isHomePage,
                        'home-page-show-alarm': (_vm.newCall || _vm.incomingChat) && !_vm.isHomePage,
                    }}),_c('router-view')],1)],1)],1),_c('BaseTooltip'),(_vm.modalsStore.filialsList && _vm.isCSINavItemDisplayed)?_c('FilialsListModal',{on:{"close-modal":function($event){return _vm.closeModal('filialsList')}}}):_vm._e(),_c('ImageGallery'),_c('ModalsController')],1)
}
var staticRenderFns = []

export { render, staticRenderFns }