<template>
    <ModalWrapper class="leave-modal" ref="modal" #default="{close}" transparent>
        <div class="leave-modal__body">
            <p class="leave-modal__text">{{ $t("components.common.areYouSureYouWantToLeaveNow") }}</p>
            <div class="block-wrap justify-content-end">
                <AppButton class="leave-modal__cancel-button" theme="gray" @click="close">{{ $t("common.cancel") }}</AppButton>
                <AppButton class="leave-modal__submit-button" theme="danger" @click="submit">{{ $t("components.common.yes") }}</AppButton>
            </div>
        </div>
    </ModalWrapper>
</template>

<script>
import ModalWrapper from "./partials/ModalWrapper";
import AppButton from "./partials/AppButton";
import {logout} from "../helpers/api";
export default {
    components: {AppButton, ModalWrapper},
    methods: {
        submit() {
            this.$refs.modal.close();
            this.$emit('submit');

            logout();
            this.$store.commit("stopInterval");
            this.$store.commit("stopInterval2");
            this.$store.commit("stopInterval3");
            this.$store.commit('clearState');
        }
    },
};
</script>
<style lang="scss">
.leave-modal {
    &__body {
        width: 624px;
        max-width: 100%;
        background: var(--color-white);
        box-shadow: inset -4px 0px 0px var(--color-danger-dark), inset 4px 0px 0px var(--color-danger-dark);
        border-radius: 12px;
        padding: 25px 32px 32px;
        color: var(--color-black);
    }
    &__text {
        margin-bottom: 58px;
        font-weight: 500;
        font-size: 24px;
        line-height: 29px;
    }
    &__submit-button {
        width: 74px;
    }
    &__cancel-button {
        width: 118px;
    }
}
</style>
