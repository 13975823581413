<template>
    <div class="application-input-wrap">
        <div class="application-input-title">{{ $t(item.name) }}</div>
        <AppTreeSelect :value="model[item.key]" class="w-100 mb-2-a"
                       hide-item-action
                       :loading="item.loading"
                       :options="item.items"
                       :flatten-items="item.flattenItems" @input="$emit('input', [item.key, $event ])"/>
        <div v-if="filterItems.length" class="block-wrap block-wrap--offset-small">
            <div v-for="item in filterItems" :key="item.id"
                 class="filter-pill"
                 @click="onInput(item.id)">
                {{ item.name }}
                <AppIcon class="filter-pill__close" icon="close"/>
            </div>
        </div>
        <div v-if="isError" class="application-input-error">{{ getTranslationOrKey(errorMessages[item.key][0]) }}</div>
    </div>
</template>

<script >

import AppTreeSelect from "@/components/partials/AppTreeSelect.vue";
import AppIcon from "@/components/partials/AppIcon.vue";
import {translationExists} from "@/mixins/local/translationExists.mixin";

export default {
    name: 'TreeInputComponent',
    components: {
        AppTreeSelect, AppIcon
    },
    mixins: [ translationExists ],
    props: {
        item: [Object, Array],
        model: Object,
        errorMessages: Object,
        autoFocus: Boolean,
        disabled: Boolean
    },
    computed: {
        isError() {
            return Boolean(this.errorMessages[this.item.key] && this.errorMessages[this.item.key].length)
        },
        filterItems() {
            return this.item.flattenItems.filter((item) => this.model[this.item.key].includes(item.id))
                .map(option => ({
                    id: option.id,
                    name: option.name || option.title,
                    key: this.item.key
                }))
        }
    },
    methods: {
        onInput(optionId) {
            this.$emit('input', [this.item.key, this.model[this.item.key].filter(id => id !== optionId) ])
        }
    }
}
</script>
