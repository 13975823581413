var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"avatar-frame-a",class:[{
    'avatar-frame-a--animating': _vm.isAnimating,
    'avatar-frame-a--transition': _vm.transition,
    'avatar-frame-a--disabled': _vm.disabled,
    'avatar-frame-a--is-enter': _vm.isEnter,
    ['avatar-frame-a--size-' + _vm.size]: _vm.size,
  }, 'avatar-frame-a--state-' + _vm.state],on:{"click":function($event){return _vm.$emit('click')}}},[_vm._t("avatar",function(){return [_c('img',{staticClass:"avatar-frame-a__image",attrs:{"src":_vm.data.avatar,"alt":"img"}})]}),_c('svg',{staticClass:"avatar-frame-a__icon",attrs:{"width":"48","height":"48","viewBox":"0 0 48 48","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[_c('circle',{staticClass:"avatar-frame-a__circle",attrs:{"cx":"24","cy":"24","r":"22.6","stroke":"currentColor","stroke-width":"2.8"}})])],2)
}
var staticRenderFns = []

export { render, staticRenderFns }