<template>
    <div class="stars-rating" :class="'stars-rating--theme-' + theme">
        <div v-for="star in max" :key="star" class="stars-rating__item">
            <AppIcon :icon="getIcon(star)"/>
        </div>
    </div>
</template>
<script>
import AppIcon from "./AppIcon";

export const getRatingTheme = (value) => {
    if(value < 2) return 'danger';
    else if(value < 3.5) return 'warning';
    else return 'success';
}

export default {
    name: 'StarsRating',
    components: {AppIcon},
    props: {
        value: {
            type: [String, Number],
            default: 0
        },
        max: {
            type: [String, Number],
            default: 5
        }
    },
    computed: {
        floored() {
            return Math.floor(this.value);
        },
        ceiled() {
            return Math.ceil(this.value);
        },
        theme() {
            return getRatingTheme(this.value);
        }
    },
    methods: {
        getIcon(rating) {
            if(rating > this.floored && rating === this.ceiled) return 'star-half';
            else if(rating > this.ceiled) return 'star-empty';
            else return 'star-full';
        }
    }
}
</script>
<style lang="scss">
.stars-rating {
    display: flex;
    align-items: center;
    color: #00CC56;
    &__item {
        margin-right: 2px;
        svg {
            width: 14px;
            height: 14px;
            display: block;
        }
        &:last-child {
            margin-right: 0;
        }
    }
    &--theme-warning {
        color: #F2B600;
    }
    &--theme-danger {
        color: #FF4921;
    }
}
</style>
