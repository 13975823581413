<template>
    <div v-if="selectedData" class="price-course" v-clickaway="close">
        <transition name="opacity" mode="out-in">
            <div :key="key" class="price-course__wrap" @click="visible = !visible" @mouseenter="clearPriceTimeout" @mouseleave="startPriceTimeout">
                <div class="price-course__name">{{ codesName[selectedCurrency] }}</div>
                <div class="price-course__item price-course__item--up">
                    <div class="price-course__title">{{ $t("components.common.purchase") }}</div>
                    <div class="price-course__value">{{ buyPrice }}</div>
                </div>
                <div class="price-course__item price-course__item--down">
                    <div class="price-course__title">{{ $t("components.common.sale") }}</div>
                    <div class="price-course__value">{{ sellPrice }}</div>
                </div>
                <div class="price-course__button" @click.stop="loadData">
                    <AppIcon icon="update-price"/>
                </div>
            </div>
        </transition>
        <transition name="dialer">
            <div v-if="visible" class="price-course__window">
                <div class="price-course__arrow" @click="close"></div>
                <table>
                    <thead>
                    <tr>
                        <th>{{ $t("components.common.nameOfCurrency") }}</th>
                        <th>{{ $t("components.common.purchaseInCapitalLetters") }}</th>
                        <th>{{ $t("components.common.saleInCapitalLetters") }}</th>
                        <th>{{ $t("components.common.centralBank") }}</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="item in priceData" :key="item.currencyCode">
                        <td>1 {{ item.currencyName }}</td>
                        <td>{{ parseNumber(item.rateParch) }}</td>
                        <td>{{ parseNumber(item.rateSal) }}</td>
                        <td>{{ parseNumber(item.rateCB) }}</td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </transition>
    </div>
</template>
<script>
import {get} from "../../helpers/api";
import ListUtils from "../../mixins/ListUtils";
import clickaway from "../../directives/clickaway";
import AppIcon from "../partials/AppIcon";

export default {
    name: 'PriceCourse',
    components: {AppIcon},
    mixins: [ListUtils],
    directives: {
        clickaway
    },
    data() {
        return {
            priceData: [],
            visible: false,
            mainPrices: ['840', '978', '643'],
            codesName: {
                '840': 'USD',
                '978': 'EUR',
                '643': 'RUB'
            },
            key: 0,
            priceTimeout: null
        }
    },
    computed: {
        selectedData() {
            return this.priceData.find(({currencyCode}) => currencyCode === this.selectedCurrency)
        },
        sellPrice() {
            return this.parseNumber(this.selectedData.rateSal)
        },
        buyPrice() {
            return this.parseNumber(this.selectedData.rateParch)
        },
        pricesList() {
            return this.priceData.filter(({currencyCode}) => this.mainPrices.includes(currencyCode))
        },
        selectedCurrency() {
            return this.mainPrices[this.key];
        },
    },
    mounted() {
        this.loadData();
    },
    beforeDestroy() {
        this.clearPriceTimeout();
    },
    methods: {
        clearPriceTimeout() {
            clearInterval(this.priceTimeout);
            this.priceTimeout = null;
        },
        startPriceTimeout() {
            this.priceTimeout = setInterval(() => {
                this.key++;
                if(!this.mainPrices[this.key]) {
                    this.key = 0;
                }
            }, 10000);
        },
        close() {
            this.visible = false
        },
        parseNumber(price) {
            return this.formatNumber(price / 100, true)
        },
        loadData() {
            this.priceData = [];
            get(`/aibs/exchange-list`)
                .then(res => {
                    if(res.data.success) {
                        this.priceData = res.data.data;
                        this.startPriceTimeout();
                    }
                })
        },
    }
}
</script>
<style lang="scss">
.price-course {
    position: relative;
    &__wrap {
        cursor: pointer;
        user-select: none;
        display: flex;
        align-items: center;
        font-weight: 500;
        font-size: 16px;
        line-height: 19px;
    }
    &__name {
        margin-right: 14px;
    }
    &__title {
        margin: 0 0 2px;
        font-weight: 500;
        font-size: 12px;
        line-height: 14px;
        color: var(--color-gray-lighten);
    }
    &__item {
        --color: var(--color-success-dark);
        margin-right: 6px;
        padding-right: 14px;
        position: relative;
        &:before {
            content: '';
            position: absolute;
            right: 0;
            bottom: 10px;
            border-bottom: 5px solid var(--color);
            border-left: 4px solid transparent;
            border-right: 4px solid transparent;
        }
        &--down {
            --color: var(--color-danger);
            &:before {
                transform: matrix(1, 0, 0, -1, 0, 0);
            }
        }
    }
    &__button {
        margin-left: 4px;
        padding: 4px;
        color: var(--color-border-dark);
        svg {
            display: block;
            width: 24px;
            height: 24px;
        }
        &:hover {
            color: var(--color-black);
        }
    }
    &__window {
        position: absolute;
        top: calc(100% + 18px);
        left: 34px;
        background: var(--color-white);
        box-shadow: var(--shadow-button);
        border-radius: 12px;
        overflow: hidden;
        table {
            width: 100%;
            border-spacing: 0;
            border-collapse: collapse;
        }
        th {
            padding: 24px 48px 17px 0;
            font-weight: 500;
            font-size: 12px;
            line-height: 14px;
            color: var(--color-primary-light);
            border-bottom: 1px solid var(--color-primary-light);
            white-space: nowrap;
        }
        td {
            padding: 18px 0;
            font-weight: 500;
            font-size: 14px;
            line-height: 17px;
            color: var(--color-dark);
            &:first-child {
                font-weight: 400;
            }
        }
        td, th {
            &:first-child {
                padding-left: 34px;
            }
            &:last-child {
                padding-right: 34px;
            }
        }
        tr:hover td {
            background: var(--color-white-bluesh);
        }
    }
    &__arrow {
        cursor: pointer;
        user-select: none;
        width: 30px;
        height: 30px;
        position: absolute;
        right: 13px;
        top: 15px;
        background: url('../../assets/svg/chevron-up.svg') center/20px no-repeat;
    }
}
</style>
