var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{ref:"wrap",staticClass:"agent-phone-control-wrap",class:{
        'agent-phone-control-wrap--fullscreen': _vm.modalWindowFullscreen,
    }},[_c('ShiftLayoutAppearAnimation',{attrs:{"visible":_vm.newCall && !_vm.conferenceCall && !_vm.isCalling && !_vm.isViewClientPage,"height":"88"}},[_c('div',[_c('div',{staticClass:"agent-phone-control"},[_c('div',{staticClass:"d-flex align-items-center"},[_c('div',{staticClass:"agent-phone-control__item agent-phone-control__item--large"},[_c('ProblemDescriptionItem',{attrs:{"image":"call/call-hello-001.svg","name":'components.common.talkToMe',"value":_vm.call.phone ||
                                _vm.$t('components.common.unknownNumber'),"type":"phone","theme":"main"}})],1),_c('div',{staticClass:"agent-phone-control__item"},[_c('ProblemDescriptionItem',{attrs:{"icon":"globe","name":"components.common.serviceLanguage","value":_vm.call.language
                                    ? _vm.$t(
                                          'common.languages.' +
                                              _vm.call.language
                                      )
                                    : '-'}})],1),_c('div',{staticClass:"agent-phone-control__item"},[_c('ProblemDescriptionItem',{attrs:{"icon":"control-phone","name":"components.common.calTo","value":_vm.phoneInfo}})],1)]),_c('div',{staticClass:"agent-phone-control__actions"},[_c('button',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(_vm.$t('components.common.answerCall')),expression:"$t('components.common.answerCall')"}],staticClass:"call-button-a",on:{"click":_vm.answerCall}},[_c('AppIcon',{attrs:{"icon":"phone"}}),_vm._v(" "+_vm._s(_vm.formattedAnswerCallTime)+" ")],1)])])])]),_c('ShiftLayoutAppearAnimation',{attrs:{"visible":_vm.newCall && !_vm.conferenceCall && _vm.isCalling && !_vm.isViewClientPage,"height":"88"}},[_c('div',[_c('div',{staticClass:"agent-phone-control",class:{
                    ['agent-phone-control--theme-' + _vm.callTheme]: _vm.callTheme,
                }},[_c('transition',{attrs:{"name":"agent-indicator","mode":"out-in"}},[_c('div',{key:_vm.callTheme,staticClass:"agent-phone-control__indicator"})]),_c('transition',{attrs:{"name":"agent-info","mode":"out-in"}},[_c('div',{key:_vm.clientInfoKey,staticClass:"d-flex align-items-center"},[_c('div',{staticClass:"agent-phone-control__item agent-phone-control__item--large"},[_c('ProblemDescriptionItem',{class:{
                                    'user-tags-shaking': _vm.usernameChanged,
                                },attrs:{"icon":"user","name":"","value":_vm.call.phone ||
                                    _vm.$t('components.common.unknownNumber'),"type":"user-phone"},scopedSlots:_vm._u([{key:"name",fn:function(){return [_c('BankUserTags',{attrs:{"value":_vm.clientName,"user-data":_vm.userData}})]},proxy:true},{key:"icon-additional",fn:function(){return [_c('div',{staticClass:"lang-icon clickable-item",on:{"click":_vm.changeLanguage}},[_c('img',{attrs:{"src":_vm.iconLang,"alt":"flag"}})])]},proxy:true}])})],1),_c('div',{staticClass:"agent-phone-control__item"},[_c('ProblemDescriptionItem',{attrs:{"icon":"folder-open","name":"components.common.clientCategory","theme":_vm.fieldChanged('client_type')
                                        ? 'success'
                                        : '',"value":_vm.getClientCategory(_vm.userData.client_type)}})],1),_c('div',{staticClass:"agent-phone-control__item"},[_c('ProblemDescriptionItem',{class:{
                                    'user-tags-shaking':
                                        _vm.fieldChanged('code'),
                                },attrs:{"icon":"code","name":"components.common.clientCode","theme":_vm.fieldChanged('code')
                                        ? 'warning-icon'
                                        : '',"value":_vm.userData.code || '-'}})],1),_c('div',{staticClass:"agent-phone-control__item"},[_c('ProblemDescriptionItem',{attrs:{"icon":"control-phone","name":"components.common.calTo","value":_vm.phoneInfo}})],1)])]),_c('transition',{attrs:{"name":"agent-buttons","mode":"out-in"}},[_c('div',{key:_vm.clientButtonsKey,staticClass:"agent-phone-control__actions"},[_c('div',{staticClass:"block-wrap"},[(_vm.clientCallFinished)?[(_vm.isClientDropped)?_c('div',[_c('ProblemDescriptionItem',{attrs:{"icon":"user-cancel","name":"components.common.theClientHungUp","value":_vm.consultFinishTimerText,"theme":"full-danger"}})],1):(_vm.isOvertime)?_c('div',{key:"over-1",staticClass:"d-flex align-items-center"},[_c('ProblemDescriptionItem',{staticClass:"text-right mr-18",attrs:{"name":"components.common.downtime","value":_vm.overtimeTimerText,"theme":"overtime"}}),_c('button',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(
                                            _vm.$t(
                                                'components.common.closePanel'
                                            )
                                        ),expression:"\n                                            $t(\n                                                'components.common.closePanel'\n                                            )\n                                        "}],staticClass:"close-btn close-btn--theme-overtime",on:{"click":_vm.finishCall}},[_c('AppIcon',{attrs:{"icon":"timer"}})],1)],1):_c('div',{key:"finish-1",staticClass:"d-flex align-items-center"},[_c('ProblemDescriptionItem',{staticClass:"mr-42",attrs:{"icon":"timer","name":"components.common.beforeClosing","value":_vm.finishTimerText,"theme":"full-danger"}}),_c('button',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(
                                            _vm.$t(
                                                'components.common.closePanel'
                                            )
                                        ),expression:"\n                                            $t(\n                                                'components.common.closePanel'\n                                            )\n                                        "}],staticClass:"close-btn",on:{"click":_vm.finishCall}},[_c('AppIcon',{attrs:{"icon":"phone-hangup"}})],1)],1)]:[_c('button',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(
                                        () =>
                                            _vm.$t(
                                                'components.common.resetCall'
                                            )
                                    ),expression:"\n                                        () =>\n                                            $t(\n                                                'components.common.resetCall'\n                                            )\n                                    "}],staticClass:"call-button-a call-button-a--theme-red",on:{"click":_vm.dropCall}},[_c('AppIcon',{attrs:{"icon":"hang"}}),_vm._v(" "+_vm._s(_vm.formattedDropCallTime)+" ")],1),_c('div',[_c('div',{staticClass:"button-double",class:{
                                            'button-double--active':
                                                _vm.ispause,
                                        },on:{"click":_vm.onCallPause}},[_c('div',{staticClass:"button-double__front"},[_c('button',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(
                                                    _vm.$t(
                                                        'components.common.continueCall'
                                                    )
                                                ),expression:"\n                                                    $t(\n                                                        'components.common.continueCall'\n                                                    )\n                                                "}],staticClass:"call-button-a call-button-a--theme-yellow",attrs:{"tabindex":_vm.ispause ? null : '-1',"disabled":!_vm.ispause}},[_c('AppIcon',{attrs:{"icon":"stop"}}),_vm._v(" "+_vm._s(_vm.formattedPauseCallTime)+" ")],1)]),_c('div',{staticClass:"button-double__back"},[_c('AppButton',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(
                                                    _vm.$t(
                                                        'components.common.holdCall'
                                                    )
                                                ),expression:"\n                                                    $t(\n                                                        'components.common.holdCall'\n                                                    )\n                                                "}],attrs:{"disabled":_vm.ispause,"theme":"white-outline","round":""}},[_c('AppIcon',{attrs:{"icon":"stop"}})],1)],1)])]),(!_vm.isIncomingCall)?_c('div',[_c('AppButton',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(
                                            _vm.$t('components.common.callBack')
                                        ),expression:"\n                                            $t('components.common.callBack')\n                                        "}],attrs:{"disabled":_vm.ispause,"theme":"primary-outline","round":""},on:{"click":_vm.toggleRecallsModal}},[_c('AppIcon',{attrs:{"icon":"calendar-event-2"}})],1)],1):_vm._e(),(!_vm.isConsultCall)?_c('div',{directives:[{name:"clickaway",rawName:"v-clickaway",value:(_vm.closeDropdown),expression:"closeDropdown"}],staticClass:"agent-phone-control__dialer-button"},[_c('AppButton',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(
                                            _vm.$t(
                                                'components.common.consultationCall'
                                            )
                                        ),expression:"\n                                            $t(\n                                                'components.common.consultationCall'\n                                            )\n                                        "}],attrs:{"theme":"white-outline","round":""},on:{"click":_vm.openDropdown}},[_c('AppIcon',{attrs:{"icon":"user-plus"}})],1),_c('transition',{attrs:{"name":"dialer"}},[(_vm.dropdown)?_c('DialerWindow',{staticClass:"agent-phone-control__dialer-window",attrs:{"body":"","button-text":_vm.share == 2
                                                    ? 'components.common.callTransfer'
                                                    : 'components.common.call',"phone-book":_vm.conferenceDialerPhoneBook},on:{"submit":_vm.submitDialer,"phoneBookClose":_vm.togglePhoneBook}},[_c('div',{staticClass:"block-wrap justify-content-between align-items-center",staticStyle:{"min-height":"64px"}},[_c('AvatarSwitcher',{attrs:{"value":_vm.share,"items":[
                                                        {
                                                            key: 1,
                                                            icon: 'account-plus',
                                                        },
                                                        {
                                                            key: 2,
                                                            icon: 'share-call',
                                                        },
                                                    ]},on:{"input":_vm.dialerTypeSwitcher}}),(_vm.share === 2 && _vm.isPhoneBook)?_c('AppButton',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(
                                                        _vm.$t(
                                                            'components.common.phoneBook'
                                                        )
                                                    ),expression:"\n                                                        $t(\n                                                            'components.common.phoneBook'\n                                                        )\n                                                    "}],attrs:{"theme":"white-outline","round":""},on:{"click":_vm.togglePhoneBook}},[_c('AppIcon',{attrs:{"icon":"phone-book"}})],1):_vm._e(),_c('transition',{attrs:{"name":"opacity"}},[(
                                                            _vm.share == 1 &&
                                                            _vm.filialsList.length
                                                        )?_c('div',[_c('AppButton',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(
                                                                _vm.$t(
                                                                    'components.common.directoryOfBranches'
                                                                )
                                                            ),expression:"\n                                                                $t(\n                                                                    'components.common.directoryOfBranches'\n                                                                )\n                                                            "}],attrs:{"round":"","size":"medium","theme":"gray","type":"button"},on:{"click":_vm.openFilialsList}},[_c('AppIcon',{attrs:{"icon":"book-bookmark"}})],1)],1):_vm._e()])],1)]):_vm._e()],1)],1):_vm._e()]],2)])])],1)])]),_c('ShiftLayoutAppearAnimation',{attrs:{"visible":_vm.show_connect_call && !_vm.conferenceCall,"height":"88"}},[_c('div',[_c('div',{staticClass:"agent-phone-control",class:{
                    ['agent-phone-control--theme-' + _vm.isPausedConsult
                        ? 'warning'
                        : null]: _vm.isPausedConsult ? 'warning' : null,
                }},[_c('transition',{attrs:{"name":"agent-indicator","mode":"out-in"}},[_c('div',{key:_vm.isPausedConsult ? 'warning' : null,staticClass:"agent-phone-control__indicator"})]),_c('div',{staticClass:"agent-phone-control__item agent-phone-control__item--large"},[_c('ProblemDescriptionItem',{attrs:{"icon":"user-voice","name":_vm.consultCallName ||
                            'components.common.ourImportantSpecialist',"value":_vm.consultCallNumber,"theme":"danger","type":"user-phone"},scopedSlots:_vm._u([{key:"icon-additional",fn:function(){return [_c('div',{staticClass:"lang-icon"},[_c('img',{attrs:{"src":require("../assets/img/alo-short-logo.svg"),"alt":"flag"}})])]},proxy:true}])})],1),_c('div',{staticClass:"agent-phone-control__actions"},[_c('transition',{attrs:{"name":"agent-buttons","tag":"div","mode":"out-in"}},[_c('div',{key:_vm.isClientDropped,staticClass:"block-wrap"},[_c('button',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(
                                    _vm.$t('components.common.resetCall')
                                ),expression:"\n                                    $t('components.common.resetCall')\n                                "}],staticClass:"call-button-a call-button-a--theme-red",on:{"click":function($event){return _vm.dropShareCall(false)}}},[_c('AppIcon',{attrs:{"icon":"hang"}}),_vm._v(" "+_vm._s(_vm.formattedConsultCallTime)+" ")],1),(!_vm.isClientDropped)?_c('AppButton',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(
                                    _vm.$t('components.common.startAConference')
                                ),expression:"\n                                    $t('components.common.startAConference')\n                                "}],attrs:{"theme":"primary","disabled":_vm.isPausedConsult,"round":""},on:{"click":_vm.initConferenceCall}},[_c('AppIcon',{attrs:{"icon":"user-group"}})],1):_vm._e(),_c('div',[_c('div',{staticClass:"button-double",class:{
                                        'button-double--active':
                                            _vm.isPausedConsult,
                                    },on:{"click":_vm.onConsultCallPause}},[_c('div',{staticClass:"button-double__front"},[_c('button',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(
                                                _vm.$t(
                                                    'components.common.continueCall'
                                                )
                                            ),expression:"\n                                                $t(\n                                                    'components.common.continueCall'\n                                                )\n                                            "}],staticClass:"call-button-a call-button-a--theme-yellow",attrs:{"tabindex":_vm.isPausedConsult
                                                    ? null
                                                    : '-1',"disabled":!_vm.isPausedConsult}},[_c('AppIcon',{attrs:{"icon":"stop"}}),_vm._v(" "+_vm._s(_vm.formattedPausedConsultCallTime)+" ")],1)]),_c('div',{staticClass:"button-double__back"},[_c('AppButton',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(
                                                _vm.$t(
                                                    'components.common.holdCall'
                                                )
                                            ),expression:"\n                                                $t(\n                                                    'components.common.holdCall'\n                                                )\n                                            "}],attrs:{"disabled":_vm.isPausedConsult,"theme":"white-outline","round":""}},[_c('AppIcon',{attrs:{"icon":"stop"}})],1)],1)])])],1)])],1)],1)])]),_c('ShiftLayoutAppearAnimation',{attrs:{"visible":_vm.show_connect_call && _vm.conferenceCall,"height":"88"}},[_c('div',[_c('div',{staticClass:"agent-phone-control"},[_c('transition',{attrs:{"name":"agent-info","mode":"out-in"}},[_c('div',{key:_vm.clientConferenceDropped,staticClass:"d-flex align-items-center"},[(!_vm.clientConferenceDropped)?_c('div',{staticClass:"agent-phone-control__user agent-phone-control__item agent-phone-control__item--large"},[_c('ProblemDescriptionItem',{class:{
                                    'user-tags-shaking':
                                        _vm.fieldChanged('username'),
                                },attrs:{"icon":"user","name":"","theme":_vm.fieldChanged('username')
                                        ? 'success'
                                        : '',"value":_vm.call.phone ||
                                    _vm.$t('components.common.unknownNumber'),"type":"user-phone"},scopedSlots:_vm._u([{key:"name",fn:function(){return [_c('BankUserTags',{attrs:{"value":_vm.clientName,"user-data":_vm.userData}})]},proxy:true},{key:"icon-additional",fn:function(){return [_c('div',{staticClass:"lang-icon clickable-item",on:{"click":_vm.changeLanguage}},[_c('img',{attrs:{"src":_vm.iconLang,"alt":"flag"}})])]},proxy:true}],null,false,3075575905)})],1):_vm._e(),_c('div',{staticClass:"agent-phone-control__user agent-phone-control__item agent-phone-control__item--large"},[_c('ProblemDescriptionItem',{attrs:{"icon":"user-voice","name":_vm.consultCallName ||
                                    'components.common.ourImportantSpecialist',"value":_vm.consultCallNumber,"theme":"danger","type":"user-phone"},scopedSlots:_vm._u([{key:"icon-additional",fn:function(){return [_c('div',{staticClass:"lang-icon"},[_c('img',{attrs:{"src":require("../assets/img/alo-short-logo.svg"),"alt":"flag"}})])]},proxy:true}])})],1)])]),_c('div',{staticClass:"agent-phone-control__actions"},[_c('button',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(_vm.$t('components.common.resetCall')),expression:"$t('components.common.resetCall')"}],staticClass:"call-button-a call-button-a--theme-red",on:{"click":_vm.dropCall}},[_c('AppIcon',{attrs:{"icon":"hang"}}),_vm._v(" "+_vm._s(_vm.formattedDropCallTime)+" ")],1)])],1)])]),(_vm.modalRecallsData.visible)?_c('ApplicationModal',_vm._g(_vm._b({scopedSlots:_vm._u([{key:"beginningOfForm",fn:function(){return [_c('div',{staticClass:"w-100 mb-4"},[_c('div',{staticClass:"group-message__label"},[_vm._v(" "+_vm._s(_vm.$t("common.selectDate"))+" ")]),_c('AppDatePicker',{ref:"input",staticClass:"w-100",attrs:{"allow-clear":true,"size":"large","default-value":"","placeholder":"common.choose"},model:{value:(_vm.modalRecallsData.model.date),callback:function ($$v) {_vm.$set(_vm.modalRecallsData.model, "date", $$v)},expression:"modalRecallsData.model.date"}})],1),_c('div',{staticClass:"w-100 mb-4"},[_c('div',{staticClass:"group-message__item"},[_c('div',{staticClass:"group-message__label"},[_vm._v(" "+_vm._s(_vm.$t("common.time"))+" ")]),_c('AppTimePicker',{staticClass:"otchet-time__field",attrs:{"allow-clear":"","placeholder":_vm.$t('components.common.timeWith'),"size":"large"},model:{value:(_vm.modalRecallsData.model.time),callback:function ($$v) {_vm.$set(_vm.modalRecallsData.model, "time", $$v)},expression:"modalRecallsData.model.time"}})],1)])]},proxy:true}],null,false,170542629)},'ApplicationModal',_vm.modalRecallsData,false),_vm.modalRecallsListeners)):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }