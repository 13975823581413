<template>
    <div class="avatar-switcher" :class="['avatar-switcher--state-' + value, {'avatar-switcher--disabled': disabled}]" @click="onClick">
        <div v-for="item in items" :key="item.key" class="avatar-switcher__item">
            <AppIcon :icon="item.icon"/>
        </div>
        <div class="avatar-switcher__back"></div>
    </div>
</template>
<script>
import AppIcon from "../partials/AppIcon";
export default {
    name: 'AvatarSwitcher',
    components: {AppIcon},
    props: {
        items: Array,
        value: {
            type: [String, Number],
            default: 1
        },
        disabled: Boolean
    },
    methods: {
        onClick() {
            if(this.disabled) return;
            const state = this.value == 1 ? 2 : 1;
            this.$emit('input', this.items[state - 1].key);
        }
    }
}
</script>
<style lang="scss">
.avatar-switcher {
    $self: &;
    width: fit-content;
    cursor: pointer;
    user-select: none;
    display: flex;
    align-items: center;
    position: relative;
    z-index: 0;
    padding-left: 8px;
    padding-right: 8px;
    &__item {
        transition: color .25s;
        padding: 8px 16px;
        color: var(--color-border);
        svg {
            width: 24px;
            height: 24px;
            display: block;
        }
        &:nth-child(2) {
            color: var(--color-white);
        }
    }
    &__back {
        z-index: -1;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        &:before,
        &:after {
            transition: transform .25s;
            content: '';
            position: absolute;
            width: 72px;
            border-radius: 27px;
        }
        &:before {
            left: 8px;
            top: 4px;
            height: 32px;
            background: var(--color-border-lighten);
        }
        &:after {
            right: 0;
            top: 0;
            height: 40px;
            background: var(--color-primary-dark);
        }
    }
    &--state-1 {
        &:nth-child(2) {
            color: var(--color-border);
        }
        #{$self}__item:nth-child(1) {
            color: var(--color-white);
        }
        #{$self}__back {
            &:before {
                transform: translateX(40px);
            }
            &:after {
                transform: translateX(-56px);
            }
        }
    }
    &--disabled {
        cursor: default;
        #{$self}__back:after {
            background-color: var(--color-gray-lighten);
            opacity: 0.6;
        }
    }
}
</style>
