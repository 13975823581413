import {ModalFilterDataType} from "@/types/components/AgentAndPhoneController.type";

export const MODAL_RECALLS_DATA = (): ModalFilterDataType => ({
    renderInputs: [],
    model: {
        date: "",
        time: ""
    },
    errorMessages: {},
    icon: "date-picker",
    name: "components.common.callBack",
    clearable: true,
    visible: false,
});
