import {BaseChatCommand, useUpdateChat} from "../../../helpers/customerHelpers";

export class SelectProblemCommand extends BaseChatCommand {
    execute(id) {
        const [dataToUpdate, update] = useUpdateChat(this.chatData);
        dataToUpdate.selectedProblem = id;
        update();
    }
}
export class ProblemActionCommand extends BaseChatCommand {
    execute(id) {
        const [dataToUpdate, update] = useUpdateChat(this.chatData);
        if(this.chatData.problemsActions[this.chatData.selectedCustomer].includes(id)) return;
        dataToUpdate.problemsActions = {
            ...this.chatData.problemsActions,
            [this.chatData.selectedCustomer]: [...this.chatData.problemsActions[this.chatData.selectedCustomer], id]
        };
        update();
    }
}
export class StartCreateProblemCommand extends BaseChatCommand {
    execute() {
        const [dataToUpdate, update] = useUpdateChat(this.chatData);
        dataToUpdate.selectedProblem = null;
        dataToUpdate.isNewProblem = true;
        update();
    }
}
export class UpdateProblemCommand extends BaseChatCommand {
    execute(data) {
        const [dataToUpdate, update] = useUpdateChat(this.chatData);
        const customersProblems = this.chatData.customersProblems[this.chatData.selectedCustomer];
        let idx = customersProblems.findIndex(problem => problem.id === data.id);

        if(idx > -1) {
            const list = [...customersProblems];
            list[idx] = data;
            dataToUpdate.customersProblems = {
                ...this.chatData.customersProblems,
                [this.chatData.selectedCustomer]: list
            };
        } else {
            dataToUpdate.customersProblems = {
                ...this.chatData.customersProblems,
                [this.chatData.selectedCustomer]: [data, ...customersProblems]
            };
        }
        dataToUpdate.isNewProblem = false;
        dataToUpdate.selectedProblem = null;
        update();
    }
}
export class ReturnProblemsCommand extends BaseChatCommand {
    execute() {
        const [dataToUpdate, update] = useUpdateChat(this.chatData);
        const customersProblems = this.chatData.customersProblems[this.chatData.selectedCustomer];
        if(!customersProblems || !customersProblems.length) return;
        dataToUpdate.selectedProblem = null;
        dataToUpdate.isNewProblem = false;
        update();
    }
}
