<template>
    <transition name="gallery">
        <div v-if="visible" class="image-gallery" :class="{'image-gallery--zoom-in': isZooming, 'image-gallery--zoom-out': !isZooming, 'image-gallery--grab': drag}" @wheel.prevent>
            <div class="image-gallery__overlay" @click="close"></div>
            <div class="image-gallery__wrap" ref="wrap" :style="{'--z': zoom, '--ty': transformTop / zoom + 'px', '--tx': transformLeft / zoom + 'px', '--r': rotate + 'deg'}">
                <div class="image-gallery__window" ref="window">
                    <img :src="data[0]" alt="" class="image-gallery__image" draggable="false"
                         ref="image" @drag.prevent @dragstart.prevent @mousedown.prevent="onDragStart">
                </div>
            </div>
            <div class="image-gallery__buttons">
                <button v-for="action in actions" :key="action.icon" class="image-gallery__button" type="button" @click="action.cb">
                    <AppIcon :icon="action.icon" size="20"/>
                </button>
            </div>
        </div>
    </transition>
</template>
<script>
import AppIcon from "./AppIcon";
import {galleryController} from "../../store/modules/gallery";
export default {
    name: 'ImageGallery',
    components: {AppIcon},
    data() {
        return {
            zoom: 1,
            zoomDelta: 0.1,
            transformLeft: 0,
            transformTop: 0,
            rotate: 0,
            drag: false,
            actions: [
                {
                    icon: 'plus-zoom',
                    cb: () => this.zoomButton(1)
                },
                {
                    icon: 'minus-zoom',
                    cb: () => this.zoomButton(-1)
                },
                {
                    icon: 'rotate-left',
                    cb: this.rotateMinus
                },
                {
                    icon: 'rotate-right',
                    cb: this.rotatePlus
                },
                {
                    icon: 'picture-default',
                    cb: this.resetZoom
                },
                {
                    icon: 'external-link',
                    cb: this.openImage
                },
            ]
        }
    },
    computed: {
        visible() {
            return this.$store.state.gallery.visible;
        },
        data() {
            return this.$store.state.gallery.data;
        },
        isZooming() {
            return this.zoom <= 1.3 && this.zoom >= 0.1;
        }
    },
    beforeDestroy() {
        window.removeEventListener('keydown', this.closeOnKeyboard);
        if(this.$refs.window) {
            this.$refs.window.removeEventListener('wheel', this.onWheel);
        }
    },
    watch: {
        visible(value) {
            this.$nextTick(() => {
                if (value) {
                    window.addEventListener('keydown', this.closeOnKeyboard);
                } else {
                    window.removeEventListener('keydown', this.closeOnKeyboard);
                }
                if(this.$refs.window) {
                    if (value) {
                        this.$refs.window.addEventListener('wheel', this.onWheel, {passive: true});
                    } else {
                        this.$refs.window.removeEventListener('wheel', this.onWheel);
                    }
                }
                this.resetZoom();
            })
        }
    },
    methods: {
        openImage() {
            const a = document.createElement('a');
            a.href = this.data[0];
            a.target = '_blank';
            a.click();
        },
        closeOnKeyboard(e) {
            if(e.code === 'Escape') {
                this.close();
            }
        },
        close() {
            galleryController.close();
        },
        rotatePlus() {
            this.rotate += 90;
        },
        rotateMinus() {
            this.rotate -= 90;
        },
        resetZoom() {
            this.zoom = 1;
            this.transformLeft = 0;
            this.transformTop =  0;
            this.rotate = 0;
            this.drag = false;
        },
        onClick(e) {
            if(this.isZooming) {
                this.zoom = 3;
                this.onWheel(e);
            } else {
                this.transformLeft = 0;
                this.transformTop = 0;
                this.zoom = 1;
            }
        },
        zoomButton(outIn) {
            const ib = this.$refs.image.getBoundingClientRect();
            this.zoomDelta = 0.5;
            this.onWheel({
                deltaY: outIn,
                clientX: ib.left + ib.width / 2,
                clientY: ib.top + ib.height / 2,
            });
            this.zoomDelta = 0.1;
        },
        zoomIn() {
            if(this.zoom >= 10) return;
            this.zoom += this.zoomDelta;
        },
        zoomOut() {
            if(this.zoom <= 0.2) return;
            this.zoom -= this.zoomDelta;
        },
        onDragStart(e) {
            let x = e.clientX;
            let y = e.clientY;

            this.drag = false;

            let t = setTimeout(() => {
                this.drag = true;
            }, 300);

            const mousemove = (em) => {
                if(Math.abs(em.clientX - e.clientX) > 5 || Math.abs(em.clientY - e.clientY) > 5) {
                    this.drag = true;
                }
                this.transformLeft = this.transformLeft + (em.clientX - x);
                this.transformTop = this.transformTop + (em.clientY - y);
                x = em.clientX;
                y = em.clientY;
            };
            const mouseup = (e) => {
                window.removeEventListener('mousemove', mousemove);
                window.removeEventListener('mouseup', mouseup);
                if(!this.drag) {
                    this.$nextTick(() => {
                        this.onClick(e);
                    })
                }
                this.drag = false;
                clearTimeout(t);
            };
            window.addEventListener('mousemove', mousemove);
            window.addEventListener('mouseup', mouseup);
        },
        onWheel(e) {
            const w = this.$refs.window;
            w.removeEventListener('wheel', this.onWheel);

            const image = this.$refs.image;
            const wrb = this.$refs.wrap.getBoundingClientRect();
            let ib = image.getBoundingClientRect();

            e.deltaY < 0 ? this.zoomOut() : this.zoomIn();

            const xo = e.clientX - wrb.left - this.transformLeft;
            const yo = e.clientY - wrb.top - this.transformTop;
            const pxo = xo / ib.width;
            const pyo = yo / ib.height;

            this.$nextTick(() => {
                ib = image.getBoundingClientRect();
                const xn = ib.width * pxo;
                const yn = ib.height * pyo;
                this.transformLeft = (this.transformLeft + (xo - xn));
                this.transformTop = (this.transformTop + (yo - yn));
                w.addEventListener('wheel', this.onWheel, {passive: true});
            })
        },
    }
}
</script>
<style lang="scss">
.gallery {
    &-enter-active, &-leave-active {
        transition: opacity .3s;
        .image-gallery {
            &__buttons,
            &__overlay {
                transition: opacity .3s;
            }
            &__window {
                transition: opacity .3s, transform .3s;
            }
        }
    }
    &-enter, &-leave-to {
        opacity: 1;
        .image-gallery {
            &__buttons,
            &__overlay {
                opacity: 0;
            }
            &__window {
                transform: translateY(-128px);
                opacity: 0;
            }
        }
    }
}
.image-gallery {
    $self: &;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1000;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    &__wrap {
        margin: auto;
        z-index: 1;
        position: relative;
        pointer-events: none;
    }
    &__window {
        pointer-events: all;
        transform: scale(var(--z)) translate(var(--tx), var(--ty));
        transform-origin: 0 0;
        margin: auto;
        position: relative;
    }
    &__image {
        transition: transform .25s;
        transform: rotate(var(--r));
        display: block;
        max-width: 900px;
        max-height: 90vh;
        object-fit: contain;
        background: rgba(0, 0, 0, 0.5);
    }
    &__overlay {
        background: rgba(0, 0, 0, 0.3);
        cursor: pointer;
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
    &__buttons {
        pointer-events: none;
        display: flex;
        z-index: 3;
        position: absolute;
        left: 50%;
        bottom: 16px;
        transform: translateX(-50%);
    }
    &__button {
        pointer-events: all;
        cursor: pointer;
        user-select: none;
        transition: background .25s;
        width: 48px;
        height: 48px;
        border-radius: 100%;
        background: rgba(0, 0, 0, 0.5);
        padding: 14px;
        color: #fff;
        margin-left: 20px;
        svg {
            display: block;
            width: 20px;
        }
        &:hover {
            background: rgba(#000, 0.8);
        }
    }
    &--zoom-in {
        #{$self}__window {
            cursor: zoom-in;
        }
    }
    &--zoom-out {
        #{$self}__window {
            cursor: zoom-out;
        }
    }
    &--grab {
        #{$self}__window {
            cursor: grabbing;
        }
    }
}
</style>
