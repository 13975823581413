 <template>
     <div class="stars-select" :class="'stars-select--theme-' + theme">
         <div class="stars-select__items" @mouseover="selectable = true" @mouseout="selectable = false">
             <span v-for="rating in 5" class="stars-select__item" @mouseover="preValue = rating" @click="onInput(rating)">
                 <AppIcon :icon="getIcon(rating)"/>
             </span>
         </div>
         <div class="stars-select__name">{{ selectedValue ? selectedValue + $t('components.common.from') + ' 5' : $t('components.common.notChosen') }}</div>
         <div class="stars-select__clear" @click="onInput(null)">{{ $t("components.common.reset") }}</div>
     </div>
 </template>
 <script>
 import AppIcon from "./AppIcon";
 import {getRatingTheme} from "./StarsRating";
 export default {
     name: 'StarsSelect',
     components: {AppIcon},
     props: {
         value: {
             type: Number,
             default: 0
         }
     },
     data() {
         return {
             preValue: 0,
             selectable: false
         }
     },
     computed: {
         selectedValue() {
             return this.selectable ? this.preValue : this.value;
         },
         theme() {
             if(!this.selectedValue) return 'success';
             else return getRatingTheme(this.selectedValue)
         }
     },
     methods: {
         getIcon(rating) {
             return rating <= this.selectedValue ? 'medium-star-full' : 'medium-star-empty';
         },
         onInput(rating) {
             this.$emit('input', rating);
         }
     }
 }
 </script>
 <style lang="scss">
 @import '@/assets/scss/variables';
 .stars-select {
     display: flex;
     align-items: center;
     width: 100%;
     &__items {
         display: flex;
         align-items: center;
         color: #00CC56;
         margin: -6px;
     }
     &__item {
         padding: 6px;
         cursor: pointer;
         user-select: none;
         svg {
             width: 24px;
             height: 24px;
         }
     }
     &__name {
         font-family: $font-secondary;
         font-weight: 500;
         font-size: 16px;
         line-height: 20px;
         letter-spacing: 0.005em;
         color: #949494;
         &:before {
             display: inline-block;
             content: '';
             vertical-align: middle;
             width: 3.5px;
             height: 3.5px;
             border-radius: 100%;
             margin-left: 6px;
             margin-right: 10px;
             background-color: currentColor;
             margin-top: -3px;
         }
     }
     &__clear {
         flex-shrink: 0;
         margin-left: auto;
         cursor: pointer;
         user-select: none;
         padding: 8px 0;
         font-weight: 500;
         font-size: 16px;
         line-height: 20px;
         letter-spacing: 0.005em;
         text-decoration: underline;
         color: var(--color-primary);
     }
     &--theme-warning &__items {
         color: #F2B600;
     }
     &--theme-danger &__items {
         color: #FF4921;
     }
 }
 </style>
