<template>
    <div class="app-sidebar" :class="{ 'app-sidebar--opened': opened }">
        <transition
            @enter="enter"
            @leave="leave"
            @after-enter="enterCancelled"
            @after-leave="enterCancelled"
            @enter-cancelled="enterCancelled"
            @leave-cancelled="enterCancelled"
            :css="false"
        >
            <div :key="key">
                <template v-for="(item, idx) in renderArrays">
                    <router-link
                        :key="item.name"
                        :to="item.to"
                        :disabled="IS_OPERATOR_PICKER_UP_PHONE"
                        class="app-sidebar__item"
                        :class="{
                            'app-sidebar__item--active':
                                selectedIndex == prevPagesLength + idx,
                        }"
                    >
                        <div class="app-sidebar__icon-wrap">
                            <AppIcon
                                class="app-sidebar__icon"
                                :icon="'sidebar/' + item.icon"
                            />
                        </div>
                        <div v-show="opened" class="app-sidebar__name">
                            {{ $t(item.name) }}
                        </div>
                        <div v-if="!opened" class="app-sidebar__tooltip">
                            {{ $t(item.name) }}
                        </div>
                    </router-link>
                    <hr
                        v-if="item.sep"
                        class="app-sidebar__separator"
                        :key="item.name + 'a'"
                    />
                </template>
            </div>
        </transition>

        <div class="app-sidebar__buttons">
            <div
                v-if="page > 1"
                class="app-sidebar__button app-sidebar__button--prev"
                @click="prevPage"
            >
                <AppIcon icon="chevron-down" />
            </div>
            <div
                v-if="page < totalPages"
                class="app-sidebar__button app-sidebar__button--next"
                @click="nextPage"
            >
                <AppIcon icon="chevron-down" />
            </div>
        </div>
        <div
            class="app-sidebar__toggle spacer-button spacer-button--small"
            :key="opened"
            v-tooltip="$t(tooltipMessage)"
            @click="toggle"
        >
            <AppIcon icon="arrow-left-long" />
        </div>
        <div class="app-sidebar__right-border"></div>
    </div>
</template>

<script>
import AppIcon from "../components/partials/AppIcon";
import { roles } from "../config/navigation";
import { mapGetters } from "vuex";

class NavItem {
    constructor(name, to, icon, role = []) {
        this.name = name;
        this.to = to;
        this.icon = icon;
        this.roles = [roles.admin, ...role];
    }
}

class NavItemSep extends NavItem {
    constructor(...args) {
        super(...args);
        this.sep = true;
    }
}

export default {
    name: "TheSidebar",
    components: { AppIcon },
    data() {
        return {
            hideCSINavItem: false,
            nav: [
                new NavItem("layout.sidebar.home", "/", "home", [
                    roles.operator,
                    roles.manager,
                ]),
                new NavItem(
                    "layout.sidebar.controlGroup",
                    "/admin-home",
                    "control-group",
                    [roles.operator, roles.manager]
                ),
                new NavItem(
                    "layout.sidebar.typesOfTreatment",
                    "/app-types",
                    "applications-type"
                ),
                new NavItem(
                    "layout.sidebar.marketingSources",
                    "/marketing-source",
                    "marketing"
                ),
                new NavItem(
                    "layout.sidebar.topicsAndProducts",
                    "/products",
                    "applications-category",
                    [roles.manager]
                ),
                new NavItem(
                    "layout.sidebar.productOptions",
                    "/options",
                    "options"
                ),
                ...(this.hideCSINavItem
                    ? []
                    : [
                          new NavItemSep(
                              "layout.sidebar.settingUpCSIService",
                              "/csi",
                              "sms-notification"
                          ),
                      ]),
                new NavItem("layout.sidebar.branches", "/branches", "filials"),
                new NavItem(
                    "layout.sidebar.clientBase",
                    "/customers",
                    "clients"
                ),
                new NavItemSep(
                    "layout.sidebar.customerInquiries",
                    "/open-apps",
                    "applications",
                    [roles.manager]
                ),
                new NavItem(
                    "layout.sidebar.scriptCategories",
                    "/script-category",
                    "scripts-category"
                ),
                new NavItemSep(
                    "layout.sidebar.scripts",
                    "/script-text",
                    "scripts"
                ),
                new NavItemSep("layout.sidebar.roles", "/roles", "roles"),
                new NavItemSep("layout.sidebar.users", "/users", "users"),
                // new pages
                new NavItem(
                    "layout.sidebar.bankProducts",
                    "/bank-products",
                    "bank-products",
                    [roles.marketing]
                ),
                new NavItem(
                    "layout.sidebar.advertisingChannels",
                    "/promo-channels",
                    "promo-channels",
                    [roles.marketing]
                ),
                new NavItem("layout.sidebar.leads", "/lead-list", "lead-list", [
                    roles.marketing,
                ]),
                new NavItem(
                    "layout.sidebar.advertisingCampaigns",
                    "/promo-companies",
                    "promo-companies",
                    [roles.marketing]
                ),
            ],
            page: 1,
            perPage: 0,
            key: 0,
            leaveHeight: 0,
            enterHeight: 0,
            isTransition: false,
            isEntering: false,
            isPrevEntering: false,
        };
    },
    computed: {
        ...mapGetters(["IS_OPERATOR_PICKER_UP_PHONE"]),
        filteredNav() {
            return this.nav.filter((nav) =>
                nav.roles.includes(this.$store.state.auth.role)
            );
        },
        selectedIndex() {
            return this.filteredNav.findIndex((nav) => {
                if (nav.to === "/") return nav.to === this.$route.path;
                return this.$route.path.includes(nav.to);
            });
        },
        prevPagesLength() {
            return (this.page - 1) * this.perPage;
        },
        renderArrays() {
            return this.isTransition
                ? this.isPrevEntering
                    ? this.prevArray
                    : this.nextArray
                : this.currentArray;
        },
        prevArray() {
            return this.getArray(-1);
        },
        currentArray() {
            return this.getArray(0);
        },
        nextArray() {
            return this.getArray(1);
        },
        totalPages() {
            return Math.ceil(this.filteredNav.length / this.perPage);
        },
        opened() {
            return this.$store.state.system.sidebarOpened;
        },
        tooltipMessage() {
            return this.opened ? "common.collapse" : "common.expand";
        },
    },
    mounted() {
        this.calculate();
        window.addEventListener("resize", this.calculate);
    },
    beforeCreate() {
        this.hideCSINavItem = process.env.VUE_APP_HIDE_CSI_SETTING === "true";
    },
    beforeDestroy() {
        window.removeEventListener("resize", this.calculate);
    },
    methods: {
        getArray(i = 0) {
            const start = (this.page - 1 + i) * this.perPage;
            const stop = Math.min(
                this.filteredNav.length,
                (this.page + i) * this.perPage
            );
            return this.filteredNav.slice(start, stop);
        },
        calculateFirstPage() {
            const selectedNav = this.selectedIndex;
            if (selectedNav > -1) {
                this.page = Math.ceil((selectedNav + 1) / this.perPage);
            }
        },
        toggle() {
            this.$store.commit("system/toggleSidebar");
        },
        calculate() {
            const headerHeight = parseInt(
                getComputedStyle(document.documentElement).getPropertyValue(
                    "--header-height"
                )
            );
            const pageButtons = 64;
            const itemHeight = 48;
            const sidebarHeight =
                document.documentElement.offsetHeight - headerHeight;
            this.perPage = Math.floor(
                (sidebarHeight - pageButtons) / itemHeight
            );
            this.calculateFirstPage();
        },
        animate(cb) {
            if (this.isEntering) return;
            this.isTransition = true;
            this.key++;
            setTimeout(() => {
                this.isTransition = false;
                cb();
            }, 500);
        },
        prevPage() {
            this.isPrevEntering = true;
            this.animate(() => {
                this.isPrevEntering = false;
                this.page--;
            });
        },
        nextPage() {
            this.animate(() => {
                this.page++;
            });
        },
        leave(el, done) {
            this.isEntering = true;
            this.leaveHeight = el.offsetHeight;
            if (this.isPrevEntering) {
                el.style.transition = "transform .5s, opacity .45s";
                requestAnimationFrame(() => {
                    el.style.transform = `translateY(${this.leaveHeight}px)`;
                    el.style.opacity = "0";
                    setTimeout(() => {
                        done();
                    }, 500);
                });
            } else {
                el.style.transition = "transform .5s";
                requestAnimationFrame(() => {
                    el.style.transform = `translateY(${-this.leaveHeight}px)`;
                    setTimeout(() => {
                        done();
                    }, 500);
                });
            }
        },
        enter(el, done) {
            if (this.isPrevEntering) {
                el.style.transform = `translateY(${-(
                    this.leaveHeight + el.offsetHeight
                )}px)`;
                let leave = this.leaveHeight;
                this.leaveHeight = el.offsetHeight;
                requestAnimationFrame(() => {
                    el.style.transition = "transform .5s";
                    el.style.transform = `translateY(${-leave}px)`;
                    setTimeout(() => {
                        done();
                    }, 500);
                });
            } else {
                el.style.transition = "transform .5s";
                requestAnimationFrame(() => {
                    el.style.transform = `translateY(${-this.leaveHeight}px)`;
                    setTimeout(() => {
                        done();
                    }, 500);
                });
            }
        },
        enterCancelled(el) {
            el.style.transition = "";
            el.style.transform = "";
            el.style.opacity = "";
            this.isPrevEntering = false;
            this.isEntering = false;
        },
    },
};
</script>
<style lang="scss">
@import "../assets/scss/variables";
.app-sidebar {
    $self: &;
    position: fixed;
    top: var(--header-height);
    left: 0;
    height: calc(100vh - var(--header-height));
    z-index: 6;
    background: var(--color-sidebar, #293747);
    width: var(--sidebar-width);
    transition: width 0.25s;
    display: flex;
    flex-direction: column;
    &:before {
        content: "";
        position: absolute;
        right: -10px;
        top: 0;
        bottom: 0;
        left: 0;
        z-index: -1;
    }
    &__right-border {
        opacity: 0;
        transition: opacity 0.25s;
        z-index: -1;
        position: absolute;
        width: 1px;
        height: 100vh;
        right: 0px;
        top: 0;
        bottom: 0;
        background-color: var(--color-primary);
    }
    &__item {
        transition: background-color 0.25s, color 0.25s;
        height: 48px;
        display: flex;
        align-items: center;
        position: relative;
        color: var(--color-sidebar-text, #959ba4);
        &:hover {
            background-color: var(--color-sidebar-item, #212837);
            color: var(--color-white);
            #{$self}__tooltip {
                transition-delay: 1s, 1s;
                opacity: 1;
                transform: translateY(-50%);
            }
        }
        &:active,
        &--active {
            background-color: var(--color-primary) !important;
            color: var(--color-white) !important;
        }
    }
    &__tooltip {
        transition: transform 0.25s var(--animation-elastic-1), opacity 0.25s;
        pointer-events: none;
        width: max-content;
        max-width: 240px;
        padding: 7px 12px 9px;
        border-radius: 12px;
        font-weight: 500;
        position: absolute;
        left: 100%;
        top: 50%;
        transform: translateY(-50%) translateX(-48px);
        background-color: #fff;
        opacity: 0;
        text-align: center;
        @apply tw-text-secondary-100;
        &:before {
            content: url('data:image/svg+xml,<svg width="5" height="32" viewBox="0 0 5 32" fill="none" xmlns="http://www.w3.org/2000/svg"><g clip-path="url(%23clip0_9208_64017)"><path fill-rule="evenodd" clip-rule="evenodd" d="M100 0.000188604C104.971 0.000188604 109 4.02963 109 9.00019V23.0002C109 27.9708 104.971 32.0002 100 32.0002H14C9.73657 32.0002 6.16553 29.0357 5.23576 25.0556C5.05069 24.2633 4.75765 23.4882 4.23607 22.8638L1.14195 19.1596C-0.0981625 17.675 -0.0981618 15.5156 1.14195 14.031L4.73586 9.72845C4.90651 9.52414 5 9.26639 5 9.00019V9.00019C5 4.02963 9.02943 0.000188604 14 0.000188604H100Z" fill="white"/></g><defs><clipPath id="clip0_9208_64017"><rect width="5" height="32" fill="white" transform="matrix(-1 0 0 1 5 0)"/></clipPath></defs></svg>');
            position: absolute;
            left: -5px;
            top: 50%;
            margin-top: -16px;
            width: 5px;
            height: 32px;
        }
    }
    &__separator {
        border: 0;
        border-top: 1px solid
            var(--sidebar-separator-bg, rgba(255, 255, 255, 0.1));
        width: 100%;
        display: block;
        margin: -1px 0 0;
    }
    &__icon-wrap {
        padding: 12px;
        width: 48px;
    }
    &__icon {
        display: block;
        width: 24px;
        height: 24px;
    }
    &__name {
        font-family: $font-secondary;
        font-weight: 600;
        font-size: 12px;
        line-height: 15px;
        width: 134px;
        padding-right: 12px;
        flex-shrink: 0;
        max-height: 30px;
        overflow: hidden;
        text-overflow: ellipsis;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        display: -webkit-box;
    }
    &__toggle {
        transition: transform 0.25s, opacity 0.25s;
        opacity: 0;
        position: absolute;
        top: 50%;
        right: -15px;
        margin-top: -16px;
        width: 32px;
        height: 32px;
        padding: 6px;
        border-radius: 100%;
        background-color: var(--sidebar-toggle-bg, #fff);
        color: var(--sidebar-toggle-color, var(--color-primary));
        svg {
            transform: rotate(180deg);
            width: 20px;
            height: 20px;
            display: block;
        }
        &:hover {
            transform: scale(1.15);
        }
    }
    &__toggle:hover ~ &__right-border {
        opacity: 1;
    }
    &__button {
        transition: color 0.25s;
        padding: 8px;
        color: rgba(#fff, 0.3);
        display: flex;
        cursor: pointer;
        user-select: none;
        svg {
            display: block;
            margin: 0 auto;
            width: 16px;
            height: 16px;
        }
        &--prev {
            transform: rotate(180deg);
        }
        &:hover {
            color: var(--color-white);
        }
    }
    &__buttons {
        z-index: 1;
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
    }
    &:hover {
        #{$self}__toggle {
            opacity: 1;
        }
    }
    &--opened {
        #{$self}__item {
            overflow: hidden;
        }
        svg {
            transform: rotate(0deg);
        }
    }
}
</style>
