import Push from "push.js";
export default class PushNotificationsService {
    constructor(title, description, onClickCallback, timeout = 5000) {
        this.title = title;
        this.description = description;
        this.onClickCallback = onClickCallback;
        this.timeout = timeout;
        PushNotificationsService.checkPermissions();
    }
    static checkPermissions() {
        if(!Push.Permission.has()) {
            Push.Permission.request();
        }
    }
    createNotification() {
        if(!document.hasFocus()) {
            const that = this;
            Push.create(this.title, {
                timeout: this.timeout,
                icon: '/img/favicon/favicon-32x32.png',
                body: this.description,
                onClick: function () {
                    window.focus();
                    this.close();
                    if(that.onClickCallback) that.onClickCallback();
                }
            });
        }
    }
}
