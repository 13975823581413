<template>
  <ModalWrapper
    ref="modal"
    custom-close
    transparent
    size="lg"
    @overlay-click="close"
    @close-modal="close"
  >
    <div
      class="modal-window"
      :class="modalWindowClass ? `modal-window--${modalWindowClass}` : ''"
    >
      <div
        class="modal-window__close"
        @click="close"
      >
        <AppIcon icon="close" />
      </div>
      <div class="modal-window__header">
        <AppIcon
          v-if="icon"
          :icon="icon"
        />
        {{ $t(name) }}
      </div>
      <div class="modal-window__body application-input-theme-login">
        <slot name="beginningOfForm" />
        <FormInputComponent
          :class="'field-type-'+item.type"
          v-for="(item, idx) in renderInputs"
          :key="item.key"
          :disabled="item.disabled"
          :auto-focus="idx === 0"
          class="mb-24"
          :item="item"
          :error-messages="errorMessages"
          :model="model"
          :search-keys="item.searchKeys"
          :item-value="item.itemValue"
          @input="onInput($event[0], $event[1])"
        />
        <!-- <hr class="separator mb-52"> -->
        <div class="modal-window__spacing-separator" />
        <slot name="beforeActions" />
        <div class="modal-window__actions">
          <AppButton
            v-if="clearable"
            class="mr-auto"
            type="button"
            square
            theme="white-outline"
            @click="reset"
            tabindex="-1"
          >
            <AppIcon icon="delete" />
          </AppButton>
          <AppButton
            class="mr-16"
            type="button"
            theme="gray"
            @click="close"
            tabindex="-1"
          >
            {{ $t('common.cancel') }}
          </AppButton>
          <AppButton
            @click="submit"
            type="button"
          >
            {{ loading ? $t('common.awaiting') : $t('common.apply') }}
          </AppButton>
        </div>
      </div>
    </div>
  </ModalWrapper>
</template>
<script>
import AppIcon from "../partials/AppIcon";
import AppButton from "../partials/AppButton";
import ModalWrapper from "../partials/ModalWrapper";
import FormInputComponent from "@/components/shared/old/form-input/form-input.component.vue";

export default {
    name: "ApplicationModal",
    components: {
        ModalWrapper,
        AppButton,
        AppIcon,
        FormInputComponent
    },
    props: {
        modalWindowClass: String,
        name: String,
        icon: String,
        clearable: {
            type: Boolean,
            default: true
        },
        loading: Boolean,
        errorMessages: Object,
        renderInputs: Array,
        model: Object
    },
    methods: {
        onInput(key, value) {
            this.$emit("input", key, value);
        },
        close() {
            this.$emit("close");
        },
        submit() {
            this.$emit("submit");
        },
        reset() {
            this.$emit("reset");
        },
        closeModal() {
            this.$refs.modal.close();
        }
    }
};
</script>
<style lang="scss">
@import '../../assets/scss/components/filter-pill';
</style>
