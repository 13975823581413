<template>
    <div v-if="messagesCounts">
        <div class="subscribe" :class="{'subscribe--new-message': newMessage}">
            <div v-if="messagesCounts > 1" class="subscribe__pagination message-pagination">
                <div class="message-pagination__button" @click="indexDown"><AppIcon icon="chevron-left"/></div>
                <div class="message-pagination__button" @click="indexUp"><AppIcon icon="chevron-right"/></div>
            </div>
            <div class="subscribe__button" @click="modals.subscribe = true">
                <AppIcon icon="bell"/>
            </div>
            <p class="subscribe__text">{{ firstMessage.message }}</p>
            <p class="subscribe__info">
                {{ firstMessage.createdBy }} | {{ formatTimeStamp(firstMessage.createdAt) }}
            </p>
        </div>
        <SubsCribeModal v-if="modals.subscribe" :messages="teamMessages" @close-modal="modals.subscribe = false"/>
    </div>
</template>

<script>
import {mapState} from 'vuex';
import SubsCribeModal from "./SubsCribeModal.vue";
import AppIcon from "./partials/AppIcon";
import FilterDate from "../mixins/FilterDate";

export default {
    components: {AppIcon, SubsCribeModal},
    mixins: [FilterDate],
    data() {
        return {
            messageIndex: 0,
            modals: {
                subscribe: false
            },
            timers: {},
            newMessage: false,
            newMessageTimer: null
        }
    },
    computed: {
        ...mapState({
            user: state => state.operator.operator,
        }),
        teamMessages() {
            return this.$store.state.teamMessages.messages.sort((a, b) => new Date(+b.createdAt).valueOf() - new Date(+a.createdAt).valueOf());
        },
        messagesCounts() {
            return this.teamMessages.length;
        },
        firstMessage() {
            const firstMessage = this.teamMessages[this.messageIndex];
            return firstMessage;
        }
    },
    watch: {
        'teamMessages': 'updateMessagesIndicator'
    },
    beforeDestroy() {
        if(this.newMessageTimer) clearTimeout(this.newMessageTimer);
    },
    methods: {
        updateMessagesIndicator() {
            this.newMessage = true
            this.newMessageTimer = setTimeout(() => {
                this.newMessage = false;
                this.newMessageTimer = null;
            }, 10000);
        },
        indexDown() {
            this.changeIndex(false)
        },
        indexUp() {
            this.changeIndex(true)
        },
        changeIndex(isUp) {
            if(this.messagesCounts <= 0) return;

            if(isUp) {
                if(this.messageIndex >= this.messagesCounts - 1) {
                    this.messageIndex = 0;
                } else {
                    this.messageIndex++;
                }
            } else {
                if(this.messageIndex <= 0) {
                    this.messageIndex = this.messagesCounts - 1;
                } else {
                    this.messageIndex--;
                }
            }
        }
    }
}
</script>

<style lang="scss">
@import '@/assets/scss/variables';
.message-pagination {
    display: flex;
    align-items: center;
    &__button {
        user-select: none;
        cursor: pointer;
        width: 32px;
        height: 32px;
        margin-right: 8px;
        padding: 4px;
        border: 0;
        border-radius: 4px;
        svg {
            width: 24px;
            height: 24px;
            display: block;
        }
        &:last-child {
            margin-right: 0;
        }
        &--active {
            background: var(--color-primary-light);
            color: var(--color-white);
            font-weight: 500;
            font-size: 16px;
            line-height: 24px;
            text-align: center;
        }
    }
}
.subscribe {
    $self: &;
    width: 100%;
    padding: 14px 64px 15px;
    box-shadow: var(--shadow-panel);
    border-radius: 0px 0px 12px 12px;
    border-top: 1px solid var(--color-border);
    display: flex;
    align-items: center;
    font-family: $font-primary;
    background: var(--color-white);
    position: relative;
    z-index: 5;
    &__button {
        flex-shrink: 0;
        cursor: pointer;
        user-select: none;
        margin-right: 24px;
        width: 24px;
        height: 24px;
        background-color: transparent;
        border: 0;
        position: relative;
        color: var(--color-white-darken);
        &:before {
            content: '';
            position: absolute;
            top: -8px;
            left: -8px;
            right: -8px;
            bottom: -8px;
        }
        svg {
            width: 100%;
            height: 100%;
            display: block;
        }
        &:hover {
            color: var(--color-primary);
        }
    }
    &__text {
        margin: 0 24px 0 0;
        font-size: 18px;
        line-height: 21px;
        font-family: $font-secondary;
        color: var(--color-dark);
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    &__info {
        white-space: nowrap;
        margin: 0 0 0 auto;
        font-weight: 500;
        font-size: 16px;
        line-height: 19px;
        color: var(--color-primary-light);
    }
    &__pagination {
        margin-right: 32px;
    }
    &--new-message {
        #{$self}__button {
            transform-origin: 50% 0;
            color: var(--color-danger);
            animation: bell 1s infinite;
        }
    }
}
</style>
