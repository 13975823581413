<template>
    <div>
        <header class="header-a">
            <div class="header-a__wavebar"/>
            <div class="header-a__wavebar"/>

            <div
                class="header-a__logo"
                @click="$router.push('/')"
            >
                <img
                    src="@/assets/img/alo-full-logo.svg"
                    alt="img"
                    width="192"
                    height="48"
                >
            </div>
            <template v-if="isDebug">
                <button
                    class="btn-call-debug btn-call-debug--incoming"
                    @click="emitIncomingCall"
                    v-tooltip="$t('layout.header.debug.incoming')"
                >
                    <AppIcon
                        icon="phone"
                        size="18"
                    />
                </button>
                <button
                    class="btn-call-debug btn-call-debug--outgoing"
                    @click="emitOutgoingCall"
                    v-tooltip="$t('layout.header.debug.outgoing')"
                >
                    <AppIcon
                        icon="phone"
                        size="18"
                    />
                </button>
            </template>
            <div class="d-flex align-items-center">
                <NavTabs
                    v-if="canChat && isHomePage"
                    ref="chat-or-call-switcher"
                    class="mr-24"
                    :value="workType"
                    theme="layout"
                    :items="workTypesList"
                    @input="onTypeSwitch"
                >
                    <template #item.call>
                        <div class="header-state">
                            <img
                                :src="require(`../assets/img/state-${agentCallStatusInfo.state}.svg`)"
                                alt=""
                                class="header-state__icon"
                            >
                            <p class="header-state__name">
                                {{ $t("layout.header.workingOnCalls") }}
                            </p>
                            <div
                                class="header-state__add record-circle"
                                :class="{'record-circle--disabled': !isCalling}"
                            />
                        </div>
                    </template>
                    <template #item.chat>
                        <div class="header-state">
                            <img
                                :src="require(`../assets/img/state-${agentChatStatusInfo.state}.svg`)"
                                alt=""
                                class="header-state__icon"
                            >
                            <p class="header-state__name">
                                {{ $t("layout.header.workingOnChats") }}
                            </p>
                            <transition
                                name="message-counter"
                                mode="out-in"
                            >
                                <div
                                    class="header-state__add message-counter message-counter--size-small"
                                    :key="unreadChatTotal"
                                    :class="{'message-counter--disabled': !unreadChatTotal}"
                                >
                                    {{ unreadChatTotal }}
                                </div>
                            </transition>
                        </div>
                    </template>
                </NavTabs>
                <ShiftLayoutAppearAnimation
                    horizontal
                    :visible="isChat"
                    width="80"
                    height="48"
                >
                    <div
                        class="reload-chat mr-32"
                        v-tooltip="$t('layout.header.reloadChat')"
                        :disabled="chatReloadDisabled"
                        @click="reloadChat"
                    />
                </ShiftLayoutAppearAnimation>
                <PriceCourse/>
            </div>

            <div class="header-a__actions">
                <div
                    class="header-trigger"
                    v-on-clickaway="away"
                >
                    <div
                        class="header-agent header-agent--selectable"
                        v-tooltip="$t('layout.header.openMenu')"
                        @click="showDrop"
                    >
                        <div class="header-agent__wrap">
                            <div class="header-agent__name">
                                {{ truncatedName }}
                            </div>
                            <div
                                class="header-agent__timer user-timer"
                                :class="['user-timer--' + avatarFrameState, { 'user-timer--animating': isAnimating, }]"
                            >
                                {{ activeTimerValue | timeFromMS(true) }}
                            </div>
                        </div>
                        <AvatarFrame
                            class="header-agent__avatar"
                            ref="avatar-frame-1"
                            :state="avatarFrameState"
                            :data="{avatar: operator_image}"
                            @animation-start="onAnimationStart"
                            @animation-finish="onAnimationFinished"
                        />
                    </div>
                    <transition name="dialer">
                        <section
                            v-if="status == 2"
                            class="header-trigger__window header-window"
                        >
                            <transition name="opacity">
                                <div
                                    v-show="menuOverlayOpened"
                                    class="header-window__overlay"
                                    @click="onMenuOverlayClick"
                                />
                            </transition>
                            <transition name="dialer">
                                <div
                                    v-show="agentCallStatusMenuOpened"
                                    class="header-window__window header-window"
                                >
                                    <template v-for="status in agentCallStatusesList">
                                        <div
                                            v-if="status.visible"
                                            :key="status.key"
                                        >
                                            <div
                                                class="avatar-link avatar-link--icon-small"
                                                :class="{'avatar-link--active': status === agentCallStatusInfo}"
                                                @click="switchCallOperatorStatus(status.key)"
                                            >
                                                <img
                                                    :src="require(`../assets/img/state-${ status.state }.svg`)"
                                                    alt=""
                                                    class="avatar-link__icon"
                                                >
                                                <div>
                                                    <p class="avatar-link__text">
                                                        {{ $t(status.name) }}
                                                    </p>
                                                    <p
                                                        v-if="status === agentCallStatusInfo"
                                                        class="avatar-link__subtext"
                                                    >
                                                        {{ $t(status.description) }}
                                                    </p>
                                                </div>
                                            </div>
                                            <hr
                                                v-if="status.state === 'ready'"
                                                class="header-window__separator"
                                            >
                                        </div>
                                    </template>
                                </div>
                            </transition>
                            <transition name="dialer">
                                <div
                                    v-show="agentChatStatusMenuOpened"
                                    class="header-window__window header-window"
                                >
                                    <template v-for="status in agentChatStatusesList">
                                        <div
                                            v-if="status.visible"
                                            :key="status.key"
                                            class="avatar-link avatar-link--icon-small"
                                            :class="{'avatar-link--active': status === agentChatStatusInfo}"
                                            @click="switchChatOperatorStatus(status.key)"
                                        >
                                            <img
                                                :src="require(`../assets/img/state-${ status.state }.svg`)"
                                                alt=""
                                                class="avatar-link__icon"
                                            >
                                            <div>
                                                <p class="avatar-link__text">
                                                    {{ $t(status.name) }}
                                                </p>
                                                <p
                                                    v-if="status === agentChatStatusInfo"
                                                    class="avatar-link__subtext"
                                                >
                                                    {{ $t(status.description) }}
                                                </p>
                                            </div>
                                        </div>
                                    </template>
                                </div>
                            </transition>
                            <transition name="dialer">
                                <div
                                    v-show="settingsOpened"
                                    class="header-window__window header-window"
                                >
                                    <div class="settings-header">
                                        <div
                                            class="settings-header__back spacer-button"
                                            @click="settingsOpened = false"
                                        >
                                            <AppIcon
                                                icon="chevron-left-bold"
                                                size="12"
                                            />
                                        </div>
                                        <div class="settings-header__operator header-agent header-agent--menu">
                                            <img
                                                class="header-agent__avatar-image"
                                                :src="operator_image"
                                                alt=""
                                            >
                                            <div class="header-agent__wrap">
                                                <div class="header-agent__name">
                                                    {{ user.name }}
                                                </div>
                                                <div class="header-agent__subname">
                                                    {{ user.phone }}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <hr class="header-window__separator">
                                    <input
                                        class="hidden-input"
                                        type="file"
                                        ref="avatar-input"
                                        accept="image/png,image/jpeg,image/jpg"
                                        @change="onAvatarUpload"
                                    >
                                    <AppButton
                                        class="w-100 mt-18 mb-8"
                                        type="button"
                                        tabindex="-1"
                                        theme="primary-outline"
                                        size="light"
                                        @click="toggleUploadAvatar"
                                    >
                                        {{ $t("layout.header.uploadAvatar") }}
                                    </AppButton>
                                </div>
                            </transition>
                            <transition name="dialer">
                                <div
                                    v-show="modals.languageSwitcher"
                                    class="header-window__window header-window"
                                >
                                    <div class="language-switcher-modal">
                                        <a-menu
                                            ref="app-menu"
                                            class="language-switcher-list"
                                        >
                                            <template v-for="(locale, index) in availableLocales">
                                                <a-menu-item
                                                    :key="locale"
                                                    class="language-switcher-item"
                                                    @click="changeAppLocale(locale)"
                                                >
                                                    <app-checkbox-input
                                                        :value="locale === currentLocale"
                                                        :text="`common.locales.${locale}`"
                                                        disabled
                                                    />
                                                </a-menu-item>

                                                <a-menu-divider
                                                    v-if="index + 1 !== availableLocales.length"
                                                    :key="index"
                                                />
                                            </template>
                                        </a-menu>
                                    </div>
                                </div>
                            </transition>
                            <div class="header-agent header-agent--menu">
                                <div class="header-agent__avatar avatar-confirmed">
                                    <img
                                        class="avatar-confirmed__img"
                                        :src="operator_image"
                                        alt=""
                                    >
                                    <img
                                        :src="require(`../assets/img/state-${ isChat ? agentChatStatusInfo.state : agentCallStatusInfo.state }.svg`)"
                                        alt=""
                                        class="avatar-confirmed__icon"
                                    >
                                </div>
                                <div class="header-agent__wrap">
                                    <div class="header-agent__name">
                                        {{ user.name }}
                                    </div>
                                    <div class="header-agent__subname">
                                        {{ user.phone }}
                                    </div>
                                </div>
                            </div>
                            <hr
                                v-if="visibleForRoles"
                                class="header-window__separator"
                            >
                            <div
                                v-if="visibleForRoles"
                                class="avatar-link"
                                :class="{'avatar-link--disabled': !canChangeagentCallStatus }"
                                @click="openCallMenuStatus"
                            >
                                <AppIcon
                                    class="avatar-link__icon"
                                    icon="phone"
                                />
                                <p class="avatar-link__text">
                                    {{ $t("layout.header.calls") }}
                                </p>
                                <div class="avatar-link__additional header-state header-state--default">
                                    <img
                                        :src="require(`../assets/img/state-${agentCallStatusInfo.state}.svg`)"
                                        alt=""
                                        class="header-state__icon"
                                    >
                                    <p class="header-state__name">
                                        {{ $t(agentCallStatusInfo.name) }}
                                    </p>
                                </div>
                                <AppIcon
                                    class="avatar-link__hover-icon"
                                    icon="chevron-right-bold"
                                    size="12"
                                />
                            </div>
                            <div
                                v-if="canChat && visibleForRoles"
                                class="avatar-link"
                                :class="{'avatar-link--disabled': !canChangeagentChatStatus}"
                                @click="openChatMenuStatus"
                            >
                                <AppIcon
                                    class="avatar-link__icon"
                                    icon="chat-bubble"
                                />
                                <p class="avatar-link__text">
                                    {{ $t("common.chat") }}
                                </p>
                                <div class="avatar-link__additional header-state header-state--default">
                                    <img
                                        :src="require(`../assets/img/state-${agentChatStatusInfo.state}.svg`)"
                                        alt=""
                                        class="header-state__icon"
                                    >
                                    <p class="header-state__name">
                                        {{ $t(agentChatStatusInfo.name) }}
                                    </p>
                                    <AppIcon
                                        class="avatar-link__hover-icon"
                                        icon="chevron-right-bold"
                                        size="12"
                                    />
                                </div>
                            </div>
                            <hr class="header-window__separator">
                            <template v-for="item in headerLinks">
                                <div
                                    class="avatar-link"
                                    v-if="item.visible"
                                    :key="item.text"
                                    @click="item.callback"
                                >
                                    <AppIcon
                                        class="avatar-link__icon"
                                        :icon="item.icon"
                                    />
                                    <p class="avatar-link__text">
                                        {{ $t(item.text) }}
                                    </p>
                                </div>
                            </template>
                            <div
                                class="avatar-link"
                                @click="modals.languageSwitcher = !modals.languageSwitcher"
                            >
                                <AppIcon
                                    class="avatar-link__icon"
                                    icon="active-language"
                                />
                                <p class="avatar-link__text">
                                    {{ $t("common.language") }}
                                </p>
                                <p class="avatar-link__subtext font-sm mt-0 pr-0 ml-auto">
                                    {{ $t("common.locales." + currentLocale) }}
                                </p>
                            </div>
                            <hr class="header-window__separator">
                            <div
                                class="avatar-link avatar-link--theme-danger test"
                                :class="{disabled: this.$store.state.isOperatorPickedUpPhone}"
                                @click="onLogout"
                            >
                                <AppIcon
                                    class="avatar-link__icon"
                                    icon="leave"
                                />
                                <p class="avatar-link__text">
                                    {{ $t("layout.header.logout") }}
                                </p>
                            </div>
                        </section>
                    </transition>
                </div>
                <template v-if="visibleForRoles">
                    <GroupMessage v-if="isAdmin"/>
                    <HeaderDialer :disabled="isCalling || !canChangeagentCallStatus"/>
                    <FilialsList v-if="filialsList.length && isCSINavItemDisplayed"/>
                </template>
            </div>
        </header>
    </div>
</template>

<script>
import {mapState} from "vuex";
import {getUser, post} from "../helpers/api";
import {directive as onClickaway} from "vue-clickaway";
import {EventBus} from "@/helpers/eventBus";
import filterDate from "../mixins/FilterDate";
import ChatController, {
    CHAT_OVERLOADED,
    INCOMING_CHAT,
    STATE_CHANGE,
    SWITCH_ERROR_CHAT
} from "../services/ChatController";
import {roles} from "../config/navigation";
import {AGENT_CHAT_STATE} from "@contact-center/asakabank-cisco-finesse/dist/lib/finesse";
import {AGENT_CALL_STATES} from "../config/call-states";
import NavTabs from "../components/partials/NavTabs";
import ShiftLayoutAppearAnimation from "../components/partials/animation/ShiftLayoutAppearAnimation";
import PriceCourse from "../components/layout/PriceCourse";
import AvatarFrame from "../components/layout/AvatarFrame";
import AppIcon from "../components/partials/AppIcon";
import GroupMessage from "../components/layout/GroupMessage";
import HeaderDialer from "../components/layout/HeaderDialer";
import FilialsList from "../components/layout/FilialsList";
import AppButton from "../components/partials/AppButton";
import ChatReconnectService from "../services/chat/ChatReconnectService";
import chatReconnectService from "../services/chat/ChatReconnectService";
import Localstorage from "@/helpers/localstorage";
import AppCheckboxInput from "@/components/partials/AppCheckboxInput.vue";

import { agentCallStatusTimer, agentChatStatusTimer, disableChangeStatusTimer } from "./timer-services"

export default {
    name: "TheHeaderNew",
    directives: {
        onClickaway: onClickaway,
    },
    mixins: [filterDate],
    components: {
        AppCheckboxInput,
        AppButton,
        FilialsList,
        HeaderDialer,
        GroupMessage,
        AppIcon,
        AvatarFrame,
        PriceCourse,
        ShiftLayoutAppearAnimation,
        NavTabs,
    },
    data() {
        return {
            agentCallStatusMenuOpened: false,
            agentChatStatusMenuOpened: false,
            isDebug: (process.env.NODE_ENV !== "production"),
            confirm: false,
            dropdown: false,
            settings: false,
            tab_state: 1,
            ready: false,
            ready2: false,
            count: "",
            phone: 1,
            isAnimating: false,
            modals: {
                confirm: false,
                report: false,
                settings: false,
                languageSwitcher: false
            },
            chatStateError: false,
            isChatReload: false,
            agentChatStatusTimer: agentChatStatusTimer.getData(),
            agentCallStatusTimer: agentCallStatusTimer.getData(),
            settingsOpened: false
        };
    },
    computed: {
        isCSINavItemDisplayed() {
            return process.env.VUE_APP_HIDE_CSI_SETTING !== "true";
        },
        chatReloadDisabled() {
            return ChatReconnectService.isReloadChatDisabled();
        },
        agentCallStatus: {
            get() {
                return this.$store.state.callData.operatorState;
            },
            set(value) {
                this.$store.commit("callData/changeOperatorState", value);
            }
        },
        agentChatStatus: {
            get() {
                return this.$store.state.chat.operatorState;
            },
            set(value) {
                this.$store.commit("chat/changeOperatorState", value);
            }
        },
        isAdmin() {
            return this.operator_role === roles.admin;
        },
        visibleForRoles() {
            return [roles.admin, roles.operator, roles.manager].includes(this.operator_role);
        },
        chatFrameState() {
            return this.agentChatStatusInfo.state;
        },
        callFrameState() {
            return this.agentCallStatusInfo.state;
        },
        avatarFrameState() {
            return this.isChat ? this.chatFrameState : this.callFrameState;
        },
        activeTimerValue() {
            return (this.isChat ? this.agentChatStatusTimer.rawSeconds : this.agentCallStatusTimer.rawSeconds) * 1000;
        },
        headerLinks() {
            return [
                {
                    text: "layout.header.headerLinks.report",
                    icon: "pie",
                    callback: () => this.openModalA("report"),
                    visible: this.visibleForRoles,
                },
                {
                    text: "layout.header.headerLinks.setting",
                    icon: "settings",
                    callback: () => this.settingsOpened = true,
                    visible: true,
                },
            ];
        },
        ...mapState(["isCalling", "filialsList", "canChat", "isChat"]),
        ...mapState({
            status: state => state.status,
            drop: state => state.drop,
            call: state => state.call,
            user: state => state.operator.operator,
            operator_image: state => state.operator.operator.photo ?? "/img/4.jpg",
            operator_role: state => state.auth.role
        }),
        agentCallStatusInfo() {
            if (this.agentCallStatus === AGENT_CALL_STATES.RESERVED) return {
                key: "reserved",
                name: "layout.header.agentCallStatusesList.reserved",
                state: "reserved",
                description: "",
            };
            return this.agentCallStatusesList.find(stat => stat.key === this.agentCallStatus);
        },
        agentChatStatusInfo() {
            return this.agentChatStatusesList.find(stat => stat.key === this.agentChatStatus);
        },
        workType() {
            return this.$store.state.isChat ? "chat" : "call";
        },
        canChangeagentChatStatus() {
            return this.agentChatStatus !== AGENT_CHAT_STATE.RESERVED && this.agentChatStatus !== CHAT_OVERLOADED;
        },
        canChangeagentCallStatus() {
            return this.agentCallStatus !== AGENT_CALL_STATES.RESERVED && this.agentCallStatus !== AGENT_CALL_STATES.TALKING;
        },
        menuOverlayOpened() {
            return this.agentChatStatusMenuOpened || this.agentCallStatusMenuOpened || this.settingsOpened || this.modals.languageSwitcher;
        },
        unreadChatTotal() {
            return Object.values(this.$store.state.chat.chatsData).reduce((total, {unreadMessages}) => {
                return total + unreadMessages;
            }, 0);
        },
        availableLocales() {
            return Object.keys(this.$i18n.messages);
        },
        currentLocale() {
            return this.$i18n.locale;
        },
        truncatedName() {
            return this.user.name.substring(0, 28);
        },
        agentCallStatusesList() {
            const statuses = [
                {
                    key: AGENT_CALL_STATES.READY,
                    name: "layout.header.agentCallStatusesList.ready",
                    state: "ready",
                    description: "layout.header.agentCallStatusesList.administratorRecommendsReadyMode",
                    visible: true
                },
                {
                    key: AGENT_CALL_STATES.NOT_READY,
                    name: "layout.header.agentCallStatusesList.notReady",
                    state: "not-ready",
                    description: "layout.header.agentCallStatusesList.administratorRecommendsNotSittingReadyModeLongTime",
                    visible: true
                },
                {
                    key: AGENT_CALL_STATES.PAUSE,
                    name: "layout.header.agentCallStatusesList.break",
                    state: "pause",
                    description: "layout.header.agentCallStatusesList.administratorRecommendsNotSittingWorkBreakModeLongTime",
                    visible: true
                },
                {
                    key: AGENT_CALL_STATES.TRAINING,
                    name: "layout.header.agentCallStatusesList.training",
                    state: "training",
                    description: "layout.header.agentCallStatusesList.administratorRecommendsNotSitTrainingModeLongTime",
                    visible: true
                }
            ];

            if (process.env?.VUE_APP_VOICE_STATUS_POST_PROCESSING_IS_ENABLED === "true") {
                statuses.push({
                    key: AGENT_CALL_STATES.POST_PROCESSING,
                    name: "layout.header.agentCallStatusesList.post-processing",
                    state: "post-processing",
                    description: "",
                    visible: true
                });
            }
            if (process.env?.VUE_APP_VOICE_STATUS_LUNCH_TIME_IS_ENABLED === "true") {
                statuses.push({
                    key: AGENT_CALL_STATES.LUNCH,
                    name: "layout.header.agentCallStatusesList.lunch",
                    state: "lunch",
                    description: "",
                    visible: true
                });
            }

            const invisibleStatuses = [
                {
                    key: AGENT_CALL_STATES.OVERTIME,
                    name: "layout.header.agentCallStatusesList.conclusion",
                    state: "overtime",
                    description: "",
                    visible: false
                },
                {
                    key: AGENT_CALL_STATES.RESERVED,
                    name: "layout.header.agentCallStatusesList.reserved",
                    state: "reserved",
                    description: "",
                    visible: false
                },
                {
                    key: AGENT_CALL_STATES.TALKING,
                    name: "layout.header.agentCallStatusesList.talk",
                    state: "answered",
                    description: "",
                    visible: false
                },
            ];

            return statuses.concat(invisibleStatuses);
        },
        agentChatStatusesList() {
            return [
                {
                    key: AGENT_CHAT_STATE.READY,
                    name: "layout.header.agentChatStatusesList.ready",
                    state: "ready",
                    description: "layout.header.agentChatStatusesList.administratorRecommendsReadyMode",
                    visible: true
                },
                {
                    key: AGENT_CHAT_STATE.RESERVED,
                    name: "layout.header.agentChatStatusesList.reserved",
                    state: "reserved",
                    description: "",
                    visible: false
                },
                {
                    key: CHAT_OVERLOADED,
                    name: "layout.header.agentChatStatusesList.overloaded",
                    state: "overtime",
                    description: "",
                    visible: false
                },
                {
                    key: AGENT_CHAT_STATE.NOT_READY,
                    name: "layout.header.agentChatStatusesList.notReady",
                    state: "not-ready",
                    description: "layout.header.agentChatStatusesList.administratorRecommendsNotSittingReadyModeLongTime",
                    visible: true
                },
            ];
        },
        workTypesList() {
            return [
                {
                    name: "layout.header.workingOnCalls",
                    key: "call"
                },
                {
                    name: "layout.header.workingOnChats",
                    key: "chat"
                }
            ];
        },
        isHomePage() {
            return this.$route.name === "ClientCard";
        }
    },
    mounted() {
        getUser().then((res) => {
            if (res.data.id) {
                this.$store.commit("getOperatorData", res.data);
            }
        });
        EventBus.$on("income-call", this.closeMenu);
        EventBus.$on("outgoing-call", this.closeMenu);
        ChatController.on(STATE_CHANGE, this.onChatStateChange);
        ChatController.on(INCOMING_CHAT, this.closeMenu);
        ChatController.on(SWITCH_ERROR_CHAT, this.onSwitchError);
        agentCallStatusTimer.startTimer();
        agentChatStatusTimer.startTimer();
    },
    beforeDestroy() {
        ChatController.off(STATE_CHANGE, this.onChatStateChange);
        ChatController.off(INCOMING_CHAT, this.closeMenu);
        ChatController.off(SWITCH_ERROR_CHAT, this.onSwitchError);
        disableChangeStatusTimer.clearTimer();
        EventBus.$off("income-call");
        EventBus.$off("outgoing-call");
        this.clearAnimation();
        agentCallStatusTimer.clearTimer();
        agentChatStatusTimer.clearTimer();
    },
    watch: {
        avatarFrameState: ["startAnimation"],
        callFrameState() {
            agentCallStatusTimer.resetTimer();
        },
        chatFrameState() {
            agentChatStatusTimer.resetTimer();
        },
        "$i18n.locale"() {
            this.$refs["chat-or-call-switcher"]?.$forceUpdate();
        }
    },
    methods: {
        toggleUploadAvatar() {
            this.$refs["avatar-input"].click();
        },
        onAvatarUpload(e) {
            let formData = new FormData();
            formData.append("avatar", e.target.files[0]);
            post("/profile", formData).then(res => {
                if (res.data.success) {
                    this.$store.commit("getAvatarImg", res.data);
                    this.settingsOpened = false;
                } else {
                    throw new Error();
                }
            }).catch(e => {
                this.$store.commit("notifications/error", "errors.fileCouldNotBeUploaded");
            });
        },
        onMenuOverlayClick() {
            this.agentChatStatusMenuOpened = false;
            this.agentCallStatusMenuOpened = false;
            this.settingsOpened = false;
            this.modals.languageSwitcher = false;
        },
        onChatStateChange(state) {
            this.agentChatStatus = state === CHAT_OVERLOADED ? CHAT_OVERLOADED : AGENT_CHAT_STATE[state];
            agentChatStatusTimer.resetTimer();
        },
        closeMenu() {
            this.$store.commit("changeStatus", 0);
            this.agentChatStatusMenuOpened = false;
            this.agentCallStatusMenuOpened = false;
            this.settingsOpened = false;
        },
        openCallMenuStatus() {
            if (this.canChangeagentCallStatus) {
                this.agentCallStatusMenuOpened = true;
            }
        },
        openChatMenuStatus() {
            if (this.canChangeagentChatStatus) {
                this.agentChatStatusMenuOpened = true;
            }
        },
        reloadChat() {
            chatReconnectService.reloadChat();
        },
        onSwitchError() {
            this.$store.commit("notifications/error", "errors.canNotChangeSstatus");
            this.chatStateError = true;
            disableChangeStatusTimer.startTimer((timerData) => {
                if (timerData.rawSeconds >= 10) {
                    disableChangeStatusTimer.clearTimer();
                    this.chatStateError = false;
                }
            });
        },
        onTypeSwitch(key) {
            this.chatStateError = false;
            this.$store.commit("changeChatLayout", key === "chat");
        },
        onAnimationStart() {
            this.isAnimating = true;
        },
        onAnimationFinished() {
            this.isAnimating = false;
        },
        startAnimation() {
            this.$refs["avatar-frame-1"].startAnimation();
            if (this.$refs["avatar-frame"]) {
                this.$refs["avatar-frame"].forEach(frame => frame.startAnimation());
            }
        },
        clearAnimation() {
            this.$refs["avatar-frame-1"].clearAnimation();
            if (this.$refs["avatar-frame"]) {
                this.$refs["avatar-frame"].forEach(frame => frame.clearAnimation());
            }
        },

        closeModal() {
            this.modals = {
                report: false,
                languageSwitcher: false,
                settings: false
            };
        },
        onLogout() {
            if (!this.$store.state.isOperatorPickedUpPhone) {
                this.$store.commit("modals/openModal", "leave");
            }
        },
        openModalA(name) {
            this.away();
            this.$store.commit("modals/openModal", name);
        },
        openModal(name) {
            this.modals = {
                report: false,
                languageSwitcher: false,
                settings: false
            };
            this.away();
            this.$set(this.modals, name, true);
        },
        emitIncomingCall() {
            EventBus.$emit("income-call-emitted");
        },
        emitOutgoingCall() {
            EventBus.$emit("outgoing-call", "0977929858");
        },
        switchChatOperatorStatus(iStatus) {
            if (this.chatStateError || !this.canChat || iStatus === this.agentChatStatus) return;
            if (iStatus === AGENT_CHAT_STATE.READY) {
                ChatController.setOperatorReady();
            } else {
                ChatController.setOperatorNotReady();
            }
        },
        switchCallOperatorStatus(iStatus) {
            if (!this.canChangeagentCallStatus || iStatus === this.agentCallStatus) return;
            EventBus.$emit("switch-operator-status", {status: iStatus});
            this.$store.commit("setOperatorPickedUpPhone", false);
            agentCallStatusTimer.resetTimer();
        },
        away() {
            this.$store.commit("changeStatus", 0);
            this.onMenuOverlayClick();
        },
        closeSettings() {
            this.closeModal();
            this.$store.commit("changeStatus", 2);
        },
        showDrop() {
            this.$store.commit("changeStatus", 2);
        },
        changeAppLocale(locale) {
            // It's active language
            if (this.currentLocale === locale) {
                return;
            }

            Localstorage.setItem("locale", locale);

            this.$i18n.locale = locale;
            this.modals.languageSwitcher = false;
        }
    },
};
</script>

<style src="@/assets/scss/modules/containers/TheHeaderNew.scss" lang="scss"></style>
<style lang="scss">
.avatar-link.disabled {
    pointer-events: none;
    color: var(--secondary);

    svg {
        color: var(--secondary);
    }
}
</style>
