import oCiscoConfig from "../config/cisco";
import axios from "axios";
import moment from "moment";

/**
 * Abstract Class BaseStatistics.
 * get CUIC statistics
 * @class BaseStatistics
 */
class BaseStatistics {
    _userName = '';

    constructor(userName) {
        if (this.constructor == BaseStatistics) {
            throw new Error("Abstract classes can't be instantiated.");
        }
        this._userName = userName;

    }

    /**
     * make request
     * @param xRequest request url or data
     * @returns {AxiosPromise}
     * @protected
     */
    makeRequest(xRequest) {
        const oRequestInstance = axios.create({
            method: 'POST',
            baseURL: '//' + location.host + '/realtime/',
            headers: {
                'cache': false,
                'Content-Type': "application/json",
                'processData': false,
                "Accept": "*/*",
                "Authorization": "Basic " + oCiscoConfig.CUIC_credential.base64
            }
        });
        return oRequestInstance({url: xRequest});
    }

    getCallback() {
        throw new Error("Method 'getCallback()' must be implemented.");
    }
}

/**
 * Overidde BaseStatistics class to call new Cisco CUIC API to retrieve call logs and operator status logs
 * @class CUICBaseStatistics
 * @extends {BaseStatistics}
 */
class CUICBaseStatistics extends BaseStatistics {
    /**
     * make request
     * @param xRequest request url or data
     * @returns {AxiosPromise}
     * @protected
     */
    makeRequest(xRequest) {
        const oRequestInstance = axios.create({
            method: 'POST',
            baseURL: "//" + location.host + "/",
            headers: {
                'cache': false,
                'Content-Type': "application/json",
                "Accept": "application/json",
                "Authorization": "Basic " + oCiscoConfig.CUIC_credential.base64_report
            }
        });
        return oRequestInstance.post('cuic/rest/en_EN/initialData/', xRequest);
    }

    /**
     * parse JSON
     * @param data
     * @returns {*|*[]|*[]}
     */
    parseAServerResponse(data) {
        let aResult = [];
        try {
            aResult = JSON.parse(data)
        } catch (e) {
            return [];
        }
        return aResult;
    }
}

/**
 * Get statics about voice queue
 * @class VoiceIAQStats
 * @extends {BaseStatistics}
 */
class VoiceIAQStats extends BaseStatistics {
    /**
     * callback after data fetch
     * @returns {(function(): void)|*}
     */
    getCallback() {
        const that = this;
        return function () {
            this.voiceStatistics = [];
            this.statLoading = true;
            that.makeRequest(
                'VoiceIAQStats?userId=' + that._userName + '&ids=' + oCiscoConfig.VoiceGroups.join(',')
            ).then((res) => {
                let result = [];
                if (res.data.length) {
                    result = res.data.filter((item) => {
                        return oCiscoConfig.VoiceGroups.includes(item.id)
                    });
                }
                this.voiceStatistics = result;
            }).finally(() => {
                this.statLoading = false;
            })
        }
    }
}

/**
 * Get statics about voice queue
 * @class ChatQueueStatistics
 * @extends {BaseStatistics}
 */
class ChatQueueStatistics extends BaseStatistics {
    /**
     * callback after data fetch
     * @returns {(function(): void)|*}
     */
    getCallback() {
        const that = this;
        return function () {
            this.chatStatistics = [];
            this.statLoading = true;
            that.makeRequest(
                'ChatQueueStatistics?userId=' + that._userName + '&ids=' + oCiscoConfig.ChatGroups.join(',')
            ).then((res) => {
                let result = [];
                if (res.data.length) {
                    result = res.data.filter((item) => {
                        return oCiscoConfig.ChatGroups.includes(item.id)
                    });
                }
                this.chatStatistics = result;
            }).finally(() => {
                this.statLoading = false;
            })
        }
    }
}

const oStatesTranslate = Object.freeze({
    'Not Ready': 'Не готов',
    'Ready': 'Готов',
    'Talking': 'Разговор',
    'Reserved': 'Зарезервирован',
    'Work': 'Работает',
    'Logged-In': 'В системе'
});

const oStateTranslate = Object.freeze({
    'Training': 'Трейнинг',
    'Break-Time':'Перерыв',
    'Post-Call-Manual':'Пост обработка',
    'Lunch-Time' : 'Обед'
})

/**
 * Get statics about ResourceStats
 * @class ResourceStats
 * @extends {BaseStatistics}
 */
export class ResourceStats extends BaseStatistics {
    /**
     * callback after data fetch
     * @returns {(function(): void)|*}
     */
    getCallback() {
        const that = this;

        return function () {
            this.resourceStats = [];
            this.statLoading = true;
            that.makeRequest(
                'ResourceIAQStats?userId=' + that._userName
            ).then((res) => {
                let result = [];
                if (res.data.length) {
                    res.data.forEach((item) => {
                        result.push(ResourceStats.getParsedMessage(item));
                    });
                }
                this.resourceStats = result;
            }).finally(() => {
                this.statLoading = false;
            })
        }
    }

    static getParsedMessage(item) {
        const result = {
            'resourceName': item.ResourceIAQStats.resourceName,
            'logonDuration': moment.utc(item.ResourceIAQStats.logonDuration).format('HH:mm:ss'),
            'timeChangedStateMillis': item.ResourceIAQStats.timeChangedStateMillis,
            'resourceId': item.ResourceIAQStats.resourceId,
            'state': oStatesTranslate.hasOwnProperty(item.ResourceIAQStats.strResourceState)
                ? oStatesTranslate[item.ResourceIAQStats.strResourceState]
                : item.ResourceIAQStats.strResourceState,
            avgTalkDuration: item.ResourceIAQStats.avgTalkDuration,
            totalNotReadyTime: item.ResourceIAQStats.totalNotReadyTime,
            avgHoldDuration: item.ResourceIAQStats.avgHoldDuration,
            resourceState: item.ResourceIAQStats.strResourceState
        }

        const translates = Object.keys(oStateTranslate);
        if (item.ResourceIAQStats.strResourceState === 'Not Ready' && translates.includes(item.ResourceIAQStats.rsrcCurrentStateReason)) {
            result.state = oStateTranslate[item.ResourceIAQStats.rsrcCurrentStateReason];
        }

        return result;
    }
}

/**
 * Get operator call logs
 * @class OperatorCallLogs
 * @extends {CUICBaseStatistics}
 */
class OperatorCallLogs extends CUICBaseStatistics {
    /**
     * callback after data fetch
     * @returns {(function(): void)|*}
     */
    getCallback() {
        const that = this;
        return function () {
            this.operatorCallLog = [];
            this.statLoading = true;
            let sTempUserName = 'ShMamarajabova';
            that.makeRequest(
                {
                    "filters": JSON.stringify([{
                        "isKeyField": true, "name": "AgentCallLogDetailStats.agentID",
                        "fieldType": "VALUELIST", "value": [{
                            "key": that._userName, "desc": that._userName
                        }],
                        "operator": "SetValues",
                        "fieldId": oCiscoConfig.CUIC_credential.call_log_field_id,
                        "valuelistId": oCiscoConfig.CUIC_credential.call_log_value_list_id
                    }]),
                    "reportId": oCiscoConfig.CUIC_credential.call_log_report_id
                }
            ).then((res) => {
                let result = [];
                if (res.data?.data) {
                    const aResult = that.parseAServerResponse(res.data?.data);
                    if (aResult.length > 0) {
                        aResult.shift().forEach((item) => {
                            result.push({
                                'calType': (item.AgentCallLogDetailStats.callType == '1' ? 'Входящий' : 'Исходящий'),
                                'phoneNumber': item.AgentCallLogDetailStats.phoneNumber,
                                'csqName': item.AgentCallLogDetailStats?.csqName ?? '--',
                                'status': item.AgentCallLogDetailStats.disposition,
                                'statusName': (item.AgentCallLogDetailStats.disposition == 1 ? 'Пропущен' : 'Принят'),
                                'startTime': moment(item.AgentCallLogDetailStats.AgentCallLogStartTime.startTime).format('DD.MM.YYYY HH:mm:ss'),
                                'duration': moment.utc(item.AgentCallLogDetailStats.callDuration).format('HH:mm:ss'),
                                'agentID': item.AgentCallLogDetailStats.agentID,
                            });
                        });
                    }
                }
                this.operatorCallLog = result.reverse();
            }).finally(() => {
                this.statLoading = false;
            })
        }
    }
}

/**
 * Get operator status log
 * @class OperatorCallLogs
 * @extends {BaseStatistics}
 */
class OperatorStatusLogs extends CUICBaseStatistics {
    /**
     * callback after data fetch
     * @returns {(function(): void)|*}
     */
    getCallback() {
        const that = this;
        return function () {
            this.operatorStatusLog = [];
            this.statLoading = true;
            that.makeRequest(
                {
                    "filters": JSON.stringify([{
                        "isKeyField": true, "name": "AgentCallLogDetailStats.agentID",
                        "fieldType": "VALUELIST", "value": [{
                            "key": that._userName, "desc": that._userName
                        }],
                        "operator": "SetValues",
                        "fieldId": oCiscoConfig.CUIC_credential.state_log_field_id,
                        "valuelistId": oCiscoConfig.CUIC_credential.state_log_value_list_id
                    }]),
                    "reportId": oCiscoConfig.CUIC_credential.state_log_report_id
                }
            ).then((res) => {
                let result = [];
                if (res.data?.data) {
                    const aResult = that.parseAServerResponse(res.data?.data);
                    if (aResult.length > 0) {
                        aResult.shift().forEach((item) => {
                            result.push({
                                'startTime': moment(item.AgentStateDetailStats.AgentStateStartTime.startTime).format('DD.MM.YYYY HH:mm:ss'),
                                'duration': moment.utc(item.AgentStateDetailStats.stateDuration).format('HH:mm:ss'),
                                'agentState': item.AgentStateDetailStats.agentState,
                                'reason': item.AgentStateDetailStats.reason
                            });
                        });
                    }
                }
                this.operatorStatusLog = result.reverse();
            }).finally(() => {
                this.statLoading = false;
            })
        };
    }
}

/**
 * get operator statistics
 * @class OperatorStatistics
 * @extends {BaseStatistics}
 */
class OperatorStatistics extends BaseStatistics {
    /**
     * callback after data fetch
     * @returns {(function(): void)|*}
     */
    getCallback() {
        const that = this;
        return function () {
            this.statLoading = true;
            that.makeRequest(
                'ResourceIAQStats?userId=' + that._userName + '&ids=' + that._userName
            ).then((res) => {
                let result = [];
                if (res.data.length) {
                    result = res.data.filter((item) => {
                        return that._userName == item.id;
                    });
                }

                if (result.length) {
                    const oDiagrams = {
                        'nHandledContacts': result[0].ResourceIAQStats.nHandledContacts,
                        'nPresentedContacts': result[0].ResourceIAQStats.nPresentedContacts,
                        'talkTimeChart': {
                            'avg': result[0].ResourceIAQStats.avgTalkDuration,
                            'max': result[0].ResourceIAQStats.longestTalkDuration,
                            'total': result[0].ResourceIAQStats.totalTalkTime
                        },
                        'holdTimeChart': {
                            'avg': result[0].ResourceIAQStats.avgHoldDuration,
                            'max': result[0].ResourceIAQStats.longestHoldDuration,
                            'total': result[0].ResourceIAQStats.totalHoldTime
                        },
                        'readyTimeChart': {
                            'avg': result[0].ResourceIAQStats.avgReadyTime,
                            'max': result[0].ResourceIAQStats.maxReadyTime,
                            'total': result[0].ResourceIAQStats.totalReadyTime
                        },
                        'notReadyTimeChart': {
                            'avg': result[0].ResourceIAQStats.avgNotReadyTime,
                            'max': result[0].ResourceIAQStats.maxNotReadyTime,
                            'total': result[0].ResourceIAQStats.totalNotReadyTime
                        },
                        'workTimeChart': {
                            'avg': result[0].ResourceIAQStats.avgWorkDuration,
                            'max': result[0].ResourceIAQStats.maxWorkTime,
                            'total': result[0].ResourceIAQStats.totalWorkTime
                        },
                    };
                    this.oDiagrams = oDiagrams
                }
            }).finally(() => {
                this.statLoading = false;
            })
        }
    }
}

/**
 * driver list
 * @type {{"1": VoiceIAQStats, "2": ResourceStats, "3": OperatorStatistics, "4": OperatorCallLogs, "5": OperatorStatusLogs, "6": ChatQueueStatistics}}
 */
const oStatisticsDrivers = {
    '1': VoiceIAQStats,
    '2': ResourceStats,
    '4': OperatorCallLogs,
    '5': OperatorStatusLogs,
    '3': OperatorStatistics,
    '6': ChatQueueStatistics,
};

/**
 * Proxy class to init statistics drivers
 * @class StatisticsProxyClass
 */
export class StatisticsProxyClass {
    #type = 0;
    #userName = 0;

    constructor(type, userName) {
        this.#type = type;
        this.#userName = userName;
    }

    /**
     * get Statistics driver callback
     * @returns {BaseStatistics|null}
     */
    getDrivers() {
        if (oStatisticsDrivers.hasOwnProperty(this.#type)) {
            return new oStatisticsDrivers[this.#type](this.#userName);
        }
        return null;
    }
}


