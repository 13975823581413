<template>
    <form class="window-dialer" @submit.prevent="onSubmit">
        <div v-if="body" class="window-dialer__body"><slot /></div>
        <div class="window-dialer__content">
            <transition name="opacity">
                <div v-if="phoneBook" class="window-dialer__phone-book">
                    <div class="window-dialer__phone-book-back" @click="onBack">
                        &#8592; {{ $t("components.common.back") }}
                    </div>
                    <div class="window-dialer__phone-book-list">
                        <div
                            v-for="(item, i) in phoneBookList"
                            :key="i"
                            class="window-dialer__phone-book-item"
                            @click="onPhoneBookNumberClick(item.number)"
                        >
                            <div class="window-dialer__phone-book-item-label">
                                {{ item.label }}
                            </div>
                            <div class="window-dialer__phone-book-item-number">
                                {{ item.number }}
                            </div>
                        </div>
                    </div>
                </div>
            </transition>
            <AppInput
                class="window-dialer__input"
                v-model="number"
                size="small"
                :filter-value="onInput"
                :placeholder="$t('components.common.enterNumberWithPoints')"
                ref="input"
            />
            <div class="window-dialer__numpad">
                <button
                    v-for="button in buttons"
                    :key="button"
                    class="window-dialer__pad"
                    type="button"
                    @click="onClick(button)"
                >
                    {{ button }}
                </button>
            </div>
            <AppButton
                class="window-dialer__submit"
                type="submit"
                size="medium-a"
                theme="success"
                >{{ $t(buttonText) }}</AppButton
            >
        </div>
    </form>
</template>
<script>
import AppInput from "./AppInput";
import AppButton from "./AppButton";
export default {
    name: "DialerWindow",
    components: { AppButton, AppInput },
    props: {
        body: Boolean,
        buttonText: {
            type: String,
            default: "common.call",
        },
        phoneBook: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            buttons: [
                "1",
                "2",
                "3",
                "4",
                "5",
                "6",
                "7",
                "8",
                "9",
                "+",
                "0",
                "#",
            ],
            number: "",
        };
    },
    mounted() {
        this.$nextTick(this.focus);
    },
    methods: {
        focus() {
            this.$refs.input.$el.focus();
        },
        onInput(value) {
            return value
                .split("")
                .filter((num) => this.buttons.includes(num))
                .join("");
        },
        onClick(button) {
            this.number += button;
            this.focus();
        },
        onSubmit() {
            this.$emit("submit", this.number);
        },
        onBack() {
            this.$emit("phoneBookClose");
        },
        onPhoneBookNumberClick(number) {
            this.number = number.toString();
            this.onBack();
        },
    },
    computed: {
        phoneBookList() {
            try {
                const list = JSON.parse(process.env.VUE_APP_PHONE_BOOK_LIST);
                if (
                    typeof list.map === "function" &&
                    list.length > 0 &&
                    list[0].label &&
                    list[0].number
                ) {
                    return list;
                }
                return [];
            } catch (error) {
                return [];
            }
        },
    },
};
</script>
<style lang="scss">
@import "@/assets/scss/variables";
.window-dialer {
    $self: &;
    width: 270px;
    background: var(--color-white);
    box-shadow: 0px 2px 20px var(--shadow-dialer);
    border-radius: 12px 0px 12px 12px;
    position: relative;
    font-family: $font-secondary;
    padding-top: 14px;
    &__input {
        display: block;
        margin: 0 auto 16px;
        width: calc(100% - 28px);
        height: 32px;
        font-weight: 500;
        font-size: 12px;
        line-height: 20px;
        letter-spacing: 0.005em;
        padding-left: 11px;
        font-family: $font-primary;
    }
    &__numpad {
        margin: 0 0 20px;
        display: flex;
        flex-wrap: wrap;
    }
    &__pad {
        padding: 0;
        width: calc(100% / 3);
        height: 40px;
        font-weight: 500;
        font-size: 16px;
        line-height: 40px;
        text-align: center;
        border: 0;
        background-color: transparent;
        &:hover {
            background: var(--color-white-bluesh);
        }
    }
    &__submit {
        width: calc(100% - 28px);
        margin: 0 auto;
    }
    &__body {
        padding: 0 14px 14px;
    }
}

.window-dialer__content {
    position: relative;
    padding-bottom: 14px;
}

.window-dialer__phone-book-back {
    padding: 16px 14px;

    font-size: 16px;
    font-weight: 500;
    color: var(--color-primary);
    cursor: pointer;
}

.window-dialer__phone-book {
    position: absolute;
    z-index: 2;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: white;
    border-radius: 0 0 12px 12px;
    padding-bottom: 8px;

    display: flex;
    flex-direction: column;
}

.window-dialer__phone-book-list {
    overflow-y: scroll;
    padding: 0 12px;
}

.window-dialer__phone-book-item {
    padding: 8px 12px;
    border-radius: 8px;

    cursor: pointer;

    &:hover {
        background: #f4f4f4;
    }
}

.window-dialer__phone-book-item-label {
    font-size: 12px;
    color: #949494;
    margin-bottom: 4px;
}

.window-dialer__phone-book-item-number {
    font-weight: 500;
    font-size: 14px;
}
</style>
