<template>
    <section class="waiting-image" :class="['waiting-image--' + state, 'waiting-image--o-' + currentPack]">
        <div v-if="infoContent.title" class="waiting-image__wrap">
            <img :src="infoContent.image" alt="" class="waiting-image__object">
            <div class="waiting-image__text-wrap">
                <p class="waiting-image__text">{{ infoContent.title }}</p>
                <p class="waiting-image__settings">{{ $t("components.common.doYouKnowICan") }} <span @click="modals.settings = true">{{ $t("components.common.change") }}</span></p>
            </div>
        </div>
        <WaitingImageModal v-if="modals.settings" :value="currentPack" :stickers-list="stickersList" @submit="submit" @close-modal="modals.settings = false"/>
    </section>
</template>
<script>
import WaitingImageModal from "../modals/WaitingImageModal";
import {setSticker} from "../../helpers/api";
import '../../assets/scss/components/WaitingImage.scss';

// 0 - звонок 1 - долгое ожидание 2 - стандарт
const IMAGES = [
    [ '/img/waiting-images/SweetJam-256px-11.webp', '/img/waiting-images/SweetJam-256px-17.webp', '/img/waiting-images/SweetJam-256px-7.webp'],
    [ '/img/waiting-images/MonkeyPuppet-256px-3.webp', '/img/waiting-images/MonkeyPuppet-256px-4.webp', '/img/waiting-images/MonkeyPuppet-256px-20.webp'],
    [ '/img/waiting-images/TheCoffeeCup-256px-15.webp', '/img/waiting-images/TheCoffeeCup-256px-17.webp', '/img/waiting-images/TheCoffeeCup-256px-24.webp'],
    [ '/img/waiting-images/UniPink-256px-9.webp', '/img/waiting-images/UniPink-256px-15.webp', '/img/waiting-images/UniPink-256px-18.webp'],
    [ '/img/waiting-images/UtyaDuck-256px-3.webp', '/img/waiting-images/UtyaDuck-256px-10.webp', '/img/waiting-images/UtyaDuck-256px-27.webp'],
    [ '/img/waiting-images/BabyYoda-256px-9.webp', '/img/waiting-images/BabyYoda-256px-17.webp', '/img/waiting-images/BabyYoda-256px-13.webp'],
    [ '/img/waiting-images/HighFox-256px-11.webp', '/img/waiting-images/HighFox-256px-18.webp', '/img/waiting-images/HighFox-256px-5.webp'],
    [ '/img/waiting-images/Scream-256px-5.webp', '/img/waiting-images/Scream-256px-18.webp', '/img/waiting-images/Scream-256px-22.webp'],
    [ '/img/waiting-images/ValentineCat-256px-3.webp', '/img/waiting-images/ValentineCat-256px-12.webp', '/img/waiting-images/ValentineCat-256px-7.webp'],
]
export default {
    name: 'WaitingImage',
    components: {WaitingImageModal},
    props: {
        isChat: Boolean,
        calling: Boolean
    },
    data() {
        return {
            state: 'waiting',
            waitingTimer: null,
            modals: {
                settings: false
            },
            stickersList: [
                {
                    id: 1,
                    name: 'Sweet Jam',
                    description: 'Милое варенье',
                    images: IMAGES[0]
                },
                {
                    id: 2,
                    name: 'MonkeyPuppet',
                    description: 'Состояние души',
                    images: IMAGES[1]
                },
                {
                    id: 3,
                    name: 'TheCoffeeCup',
                    description: 'Чашка кофе',
                    images: IMAGES[2]
                },
                {
                    id: 4,
                    name: 'UniPink',
                    description: 'Единорожка',
                    images: IMAGES[3]
                },
                {
                    id: 5,
                    name: 'UtyaDuck',
                    description: 'Утёнок Утя',
                    images: IMAGES[4]
                },
                {
                    id: 6,
                    name: 'BabyYoda',
                    description: 'Малыш Йода',
                    images: IMAGES[5]
                },
                {
                    id: 7,
                    name: 'HighFox',
                    description: 'Упоротая лиса',
                    images: IMAGES[6]
                },
                {
                    id: 8,
                    name: 'Scream',
                    description: 'Крик',
                    images: IMAGES[7]
                },
                {
                    id: 9,
                    name: 'ValentineCat',
                    description: 'Кот Валентинка',
                    images: IMAGES[8]
                },

            ],
        }
    },
    computed: {
        currentPack() {
            return this.$store.state.operator.operator.sticker;
        },
        infoContent() {
            if(!this.currentPack) return {};

            const idx = this.stickersList.findIndex(sticker => sticker.id === this.currentPack);
            switch(this.state) {
                case 'calling':
                    return {
                        image: this.stickersList[idx].images[0],
                        title: this.isChat ? this.$t('components.common.writingToUs') : this.$t('components.common.callingUs')
                    }
                case 'waiting-long':
                    return {
                        image: this.stickersList[idx].images[1],
                        title: this.$t('components.common.timeUsefully')
                    }
                default:
                    return {
                        image: this.stickersList[idx].images[2],
                        title: this.isChat ? this.$t('components.common.forwardToCommunicating') : this.$t('components.common.waitingForCall')
                    }
            }
        },
    },
    watch: {
        'calling': {
            immediate: true,
            handler: 'changeState'
        },
    },
    methods: {
        changeState(value) {
            if(value) {
                this.destroyWaitingTimer();
                this.state = 'calling'
            } else {
                this.createWaitingTimer();
                this.state = 'waiting'
            }
        },
        submit(sticker) {
            setSticker({
                sticker
            });
            this.$store.commit('setOperatorSticker', sticker);
        },
        destroyWaitingTimer() {
            clearTimeout(this.waitingTimer);
            this.waitingTimer = null;
        },
        createWaitingTimer() {
            this.waitingTimer = setTimeout(() => {
                this.destroyWaitingTimer();
                this.state = 'waiting-long';
            }, 600000);
        }
    }
}
</script>
