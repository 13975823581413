<template>
    <div class="home-page" ref="wrap" :style="{'--home-connection-error-height': connectionError && (isCalling || activeChat) ? '54px' : '0px', '--home-subscribe-height': hasSubscribe ? '54px' : '0px'}">
        <div v-if="connectionError && (isCalling || activeChat)" class="home-page__error-panel error-panel">
            <img class="error-panel__img" src="../../assets/img/error-gif.gif" alt="">
            {{ $t("components.common.lossOfLossServerNotResponding") }} 😉 {{ $t("components.common.weSaveEverything") }} ✌️
            <span class="error-panel__link" @click="reloadPage">{{ $t("components.common.reloadThePage") }}</span>
        </div>
        <ShiftLayoutAppearAnimation v-if="!isHomePage" height="60" :visible="!!(incomingChat) || newCall">
            <HomeTabs selected-view="''" show-alarm/>
        </ShiftLayoutAppearAnimation>
        <ErrorImage v-if="connectionError && !isCalling && !activeChat" @reload-click="reloadPage"/>
        <template v-else>
            <transition name="slide-calls">
                <div v-show="!isChat" class="calls-view" ref="calls">
                    <div class="calls-view__phone-control">
                        <div v-if="isCallOvertime" class="error-panel error-panel--theme-overtime">
                            <img class="error-panel__img" src="../../assets/img/error-gif.gif" alt="">
                            {{ $t("components.common.itIsLooksLikeYouHaveAnUnfinishedTask") }} 🤔  {{ $t("components.common.checkAllFieldsAndCloseTheTask") }}
                        </div>
                        <ShiftLayoutAppearAnimation height="60" :visible="!!(userData.id || incomingChat) && isHomePage">
                            <HomeTabs :selected-view="card_stat" :user-data="userData" @input="changeCard"/>
                        </ShiftLayoutAppearAnimation>
                          <AgentAndPhoneControl @show-hold="modals.dontHold = true" @clear="clearState"/>
                    </div>

                    <WaitingImage v-show="!isCalling" :calling="isIncomingCall"/>
                    <WaitingStatistics v-show="!isCalling" class="mt-auto"/>

                    <div v-if="isCalling">
                        <PageLoader v-if="isLoading"/>
                        <AboutClient v-else-if="card_stat == 1" :commands="commandsList" :call-data="customerData" @open:modal="openModal($event)"/>
                        <HistoryProblems v-else-if="card_stat == 2" :call-data="customerData"/>
                        <MybankProduct v-else-if="card_stat == 4" :processing-cards="processingCards" :user-data="userData"
                                       @process-cards="onProcessCards" @process-cards-delay="onProcessCardsDelay"/>
                        <MonitoringProducts v-else-if="card_stat == 5" :card-stat="card_stat" :common-data="commonData" :user-data="userData"/>
                        <OutgoingHistory v-else-if="card_stat === 6 && customerData.selectedCustomer" :customer-id="customerData.selectedCustomer.toString()" :call-data="customerData" />
                        <AutoDebit
                            v-else-if="card_stat === 7"
                            :processing-cards="processingCards"
                            :client="userData"
                            :is-call="true"
                        />
                    </div>
                </div>
            </transition>
            <transition v-if="canChat" name="slide-chat">
                <ChatView v-show="isChat" :processing-cards="processingCards" ref="chat" @process-cards="onProcessCards" @process-cards-delay="onProcessCardsDelay"/>
            </transition>
        </template>
        <PrevCall ref="prevCall"/>
        <DontHoldModal v-if="modals.dontHold" @close-modal="modals.dontHold = false"/>

        <CreditProductModal
                v-if="modals.creditProduct"
                :calculationType="calculationType"
                :product-info="productInfo"
                :product-table="calculationTable"
                :call-data="callModel"
                :slide="slide"
                @cancel:form="closeModal"
                @close-modal="closeModal"
                @submit:credit-product="submitCreditProduct($event)"
                @submit:deposit-product="submitDepositProduct($event)"
                @reset:step="slide = 'default'"
        />
    </div>
</template>
<script>
import AgentAndPhoneControl from "../AgentAndPhoneControl.vue";
import HistoryProblems from "../historyProblems.vue";
import OutgoingHistory from "../outgoing-history.vue";
import MybankProduct from "../MybankProduct.vue";
import {mapState} from "vuex";
import PrevCall from "../PrevCall.vue";
import WaitingImage from "../partials/WaitingImage";
import WaitingStatistics from "../partials/WaitingStatistics";
import DontHoldModal from "../modals/DontHoldModal";
import PageLoader from "../partials/PageLoader";
import ErrorImage from "../partials/ErrorImage";
import AboutClient from "../aboutClient";
import ChatController, {CAN_CHAT} from "../../services/ChatController";
import {get, post} from "../../helpers/api";
import {EventBus} from "../../helpers/eventBus";
import AppButton from "../partials/AppButton";
import AppIcon from "../partials/AppIcon";
import ShiftLayoutAppearAnimation from "../partials/animation/ShiftLayoutAppearAnimation";
import ListUtils from "../../mixins/ListUtils";
import MonitoringProducts from "../MonitoringProducts";
import {CustomerCommands} from "../../services/chat/CustomerController";
import HomeTabs from "../partials/home/HomeTabs";
const ChatView = () => import("../partials/chat/ChatView");
import CreditProductModal from "../modals/CreditProductModal";
import FullScreenModalMixin from "../../mixins/FullScreenModalMixin";
import AutoDebit from "@/components/client-base/auto-debit.vue";

export default {
    name: "HomePage",
    components: {
        HomeTabs,
        CreditProductModal,
        MonitoringProducts,
        ShiftLayoutAppearAnimation,
        AppIcon,
        AppButton,
        ChatView,
        AboutClient,
        ErrorImage,
        PageLoader,
        DontHoldModal,
        WaitingStatistics,
        WaitingImage,
        HistoryProblems,
        OutgoingHistory,
        MybankProduct,
        AgentAndPhoneControl,
        PrevCall,
        AutoDebit
    },
    mixins: [ListUtils, FullScreenModalMixin],
    data() {
        return {
            modals: {
                dontHold: false,
                creditProduct: false
            },
            processingCards: {},
            productInfo: {},
            calculationTable: {},
            calculationType: "",
            slide: "default"
        };
    },
    computed: {
        ...mapState(['isLoading', 'isCalling', 'card_stat', 'connectionError', 'canChat', 'isChat', 'isIncomingCall', 'isCallOvertime']),
        ...mapState('callData', ['callModel']),
        ...mapState({
            newCall: (state) => state.call,
        }),
        userData() {
            try {
                return this.callModel.customers.find(c => c.id === this.callModel.selectedCustomer) || {};
            } catch(e) {
                return {};
            }
        },
        hasSubscribe() {
            return !!this.$store.state.teamMessages.messages.length;
        },
        activeChat() {
            return this.$store.state.chat.activeChatId;
        },
        incomingChat() {
            return this.$store.state.chat.incomingChat;
        },
        lang() {
            if(!this.callModel) return '';
            return this.callModel.lang;
        },
        commonData() {
            if(!this.callModel) {
                return {
                    lang: '',
                    phone: ''
                };
            }
            return {
                lang: this.callModel.lang,
                phone: this.callModel.selectedPhone || this.callModel.data.phone
            }
        },
        commandsList() {
            return new CustomerCommands(this.$store.state.callData.callModel);
        },
        customerData() {
            return this.$store.state.callData.callModel;
        },
        isHomePage() {
            return this.$route.name === "ClientCard";
        },
    },
    watch: {
        modalWindowFullscreen(value) {
            const calls = this.$refs.calls;
            if(value) {
                calls.classList.add('calls-view--fullscreen');
            } else {
                setTimeout(() => {
                    calls.classList.remove('calls-view--fullscreen');
                }, 250);
            }
        }
    },
    mounted() {
        ChatController.on(CAN_CHAT, this.checkUserCanChat);
        EventBus.$on("apeal-details-modal-close", () => {
            this.modals.creditProduct = false;
        });
    },
    beforeDestroy() {
        ChatController.off(CAN_CHAT, this.checkUserCanChat);
        EventBus.$off('call-ended');
        EventBus.$off("apeal-details-modal-clos")
    },
    beforeRouteLeave(to, from, next) {
        EventBus.$off('income-call-emitted');
        EventBus.$off('outgoing-call');
        EventBus.$off('income-call');

        this.$store.commit("getCall", false);
        this.$store.commit("setIsCallOvertime", false);
        this.$store.commit("checkCalling", false);
        this.$store.commit("callData/setCall", null);
        this.$store.commit('setOperatorPickedUpPhone', false);
        this.$destroy();
        next()
    },
    methods: {
        closeModal( ) {
          if (this.modalWindowFullscreen) {
            this.modalWindowFullscreenToggle();
          }

          this.slide = "default";
          this.modals.creditProduct = false;
        },
        openModal(id) {
            get(`/products/${id}`).then((res) => {
                if (res.data.success) {
                    this.productInfo = res.data.data;

                        this.modals.creditProduct = true;

                }
            })
                .catch((_,) => {
                    this.$store.commit('notifications/error', 'errors.somethingWentWrong');
                })
        },
        changeLayout() {
            this.$store.commit('changeChatLayout', true);
        },
        checkUserCanChat() {
            this.$store.commit('userCanChat');
        },
        changeCard(value) {
            this.$store.commit("getcard_stat", value);
        },
        reloadPage() {
            document.location.reload();
        },
        clearState() {
            this.$store.commit('clearSearchError');
            this.$store.commit('setSearchEnd');
            this.$store.commit("getcard_stat", 1);
            this.processingCards = {};
        },
        onProcessCardsDelay(cards) {
            cards.forEach(card => {
                const {cardNumber} = card;
                this.$set(this.processingCards, cardNumber, {
                    ...(this.processingCards[cardNumber] || {}),
                    loading: true,
                });
            })
            this.delay(() => {
                this.onProcessCards(cards);
            }, 10000);
        },
        onProcessCards(cards) {
            cards.forEach(card => {
                const {cardNumber, type, dateExpiry} = card;

                this.$set(this.processingCards, cardNumber, {
                    ...(this.processingCards[cardNumber] || {}),
                    loading: true
                });
                get(`/processing/${type.toLowerCase()}?card=${cardNumber}&valid=${dateExpiry}`)
                    .then(({data}) => {
                        if(data.success) {
                            this.$set(this.processingCards, cardNumber, {
                                ...this.processingCards[cardNumber],
                                data: data.data
                            });
                        } else {
                            throw new Error();
                        }
                    })
                    .catch(e => {
                        this.$set(this.processingCards, cardNumber, {
                            ...this.processingCards[cardNumber],
                            data: {},
                            error: true
                        });
                    })
                    .finally(() => {
                        this.$set(this.processingCards, cardNumber, {
                            ...this.processingCards[cardNumber],
                            loading: false
                        });
                    })
            })
        },
        submitCreditProduct(form) {
          post('/calculator/credit', form)
              .then((res) => {
                this.calculationType = 'credit';
                this.calculationTable = res.data.data;
                this.slide = 'results';
              })
              .catch(() => {
                this.$store.commit('notifications/error', 'errors.somethingWentWrong')
              })
        },
        submitDepositProduct(form) {
          post('/calculator/deposit', form)
              .then((res) => {
                this.calculationType = 'deposit';
                this.calculationTable = res.data.data;
                this.slide = 'results';
              })
              .catch(() => {
                this.$store.commit('notifications/error', 'errors.somethingWentWrong')
              })

        }
    }
};
</script>
<style lang="scss">
@import '../../assets/scss/variables';
@import '../../assets/scss/animation';
:root{
    --error-panel-sides: 64px;
    --error-panel-font: normal 500 18px/21px #{$font-primary};
}
.message {
    padding-top: 32px;
}
.home-page {
    position: relative;
    overflow: hidden;
    height: calc(100vh - var(--header-height) - var(--home-subscribe-height));
    &__error-panel {
        z-index: 5;
        position: sticky;
        top: 0;
    }
}
.calls-view {
    $self: &;
    height: calc(100% - var(--home-connection-error-height));
    width: 100%;
    overflow: auto;
    display: flex;
    flex-direction: column;
    &__phone-control {
        background: var(--color-white-bg);
        z-index: 3;
        position: sticky;
        top: 0;
        width: 100%;
    }
    &--fullscreen {
        > *:not(#{$self}__phone-control) {
            display: none;
        }
        #{$self}__phone-control {
            position: static;
            z-index: auto;
        }
    }
}
.error-panel {
    display: flex;
    align-items: center;
    background: var(--color-danger-dark);
    box-shadow: var(--shadow-panel);
    padding: 0 var(--error-panel-sides);
    height: 54px;
    color: var(--color-white-08);
    font: var(--error-panel-font);
    &__img {
        width: 50px;
        height: 50px;
        margin-right: 12px;
    }
    &__icon {
        width: 20px;
        height: 20px;
        margin-right: 8px;
    }
    &__link {
        cursor: pointer;
        user-select: none;
        text-decoration: underline;
        color: var(--color-white);
        &:hover {
            text-decoration: none;
        }
    }
    &--theme-overtime {
        color: #454142;
        background: var(--color-overtime-light);
    }
}
.slide-chat {
    &-enter-active, &-leave-active {
        transition: transform .4s;
        position: absolute;
        top: var(--home-connection-error-height);
        left: 0;
        width: 100%;
        height: 100%;
    }
    &-enter-active {
        transition-delay: .2s;
    }
    &-enter,
    &-leave-to {
        transform: translateX(100%);
    }
}
.slide-calls {
    &-enter-active, &-leave-active {
        transition: transform .4s;
        position: absolute;
        top: var(--home-connection-error-height);
        left: 0;
        width: 100%;
        height: 100%;
    }
    &-enter-active {
        transition-delay: .2s;
    }
    &-enter,
    &-leave-to {
        transform: translateX(-100%);
    }
}
.home-tabs {
    --active-bg-color: #ED1D24;
    --active-text-color: #454142;
    width: 100%;
    display: flex;
    padding-left: 64px;
    padding-right: 64px;
    background-color: var(--color-white);
    height: 60px;
    padding-bottom: 1px solid var(--color-border-lighten);
    &__item {
        cursor: pointer;
        user-select: none;
        padding: 20px 24px 22px;
        font-weight: 700;
        font-size: 15px;
        line-height: 18px;
        color: var(--color-gray-lighten);
        position: relative;
        margin-bottom: -1px;
        &:after {
            content: '';
            height: 2px;
            width: 100%;
            position: absolute;
            bottom: 0;
            left: 0;
        }
        &:hover {
            color: #454142;
            background-color: #F7F9FF;
        }
        &--active {
            color: var(--active-text-color);
            &:after {
                background: var(--active-bg-color);
            }
        }
    }
    &__incoming {
        margin-left: auto;
        display: flex;
        align-items: center;
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;
        color: #454142;
    }
    &__incoming-button {
        margin-left: 12px;
        padding-left: 16px;
        padding-right: 16px;
        position: relative;
        z-index: 0;
        svg {
            margin-left: 4px;
            margin-bottom: -2px;
        }
        &:after {
            content: "";
            z-index: -2;
            position: absolute;
            top: 50%;
            left: 50%;
            margin-left: calc(-50% + -14px);
            margin-top: -25px;
            width: calc(100% + 28px);
            height: 50px;
            border-radius: inherit;
            background-color: inherit;
            opacity: 0.3;
            animation: pulse 1.5s infinite ease-out both;
        }
    }
}
</style>
