/**
     Extracts unique prefixes from environment variables based on a given prefix.
     @param {string} prefix - The base prefix to search for in the environment variable keys.
     @returns {string[]} - An array of unique prefixes found after the provided base prefix.
*/
export function getPrefixes(prefix) {
    return Object.keys(process.env)
        .filter(key => key.startsWith(prefix))
        .map(key => key.split(`${prefix}_`)[1].split('_')[0])
        .filter((value, index, self) => self.indexOf(value) === index);
}

/**
  Populates provided groups and their info based on environment variables and given prefixes.

  @param {string[]} prefixes - An array of prefixes to look for in the environment variable keys.
  @param {string} prefix - The base prefix combined with each provided prefix to form the full key.
  @param {string[]} Groups - An array to which group identifiers will be added.
  @param {Object} GroupsInfo - An object where key is a group identifier and value is its related information.
*/
export function gatherGroupInfo(prefixes, prefix, Groups, GroupsInfo) {
    prefixes.forEach(pref => {
        const group = process.env[`${prefix}_${pref}`];
        const name = process.env[`${prefix}_${pref}_NAME`];
        const iconName = process.env[`${prefix}_${pref}_ICON`];
        const icon = iconName ? `/img/${iconName}.svg` : null;

        if (group && name && icon) {
            Groups.push(group);
            GroupsInfo[group] = {
                name,
                icon
            };
        }
    });
}
